import React from 'react';


function PrivacyPolicy() {
  return (
    <div className="min-h-screen ">
      {/* Header */}
      <header >
        <div className="max-w-[1000px] mx-auto py-6 px-4">
          <div className="flex items-center space-x-3">


          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto py-8 px-4">
      <div className="p-6 sm:p-8">
              <h2 className="text-xl font-semibold text-gray-900">Privacy Policy</h2>
              <p className="text-gray-900">
                Read our full Privacy Policy to understand how we handle your data.
                Click below to view the document.
              </p>

              <a
                href="/TermandCondition.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="mt-4 inline-block px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition"
              >
                View Privacy Policy (PDF)
              </a>
            </div>
        <div className="   p-6 space-y-8">
          {/* Introduction */}
          <h1 className="text-4xl font-bold text-gray-900 mb-12 item-center  ">Privacy Policy</h1>
          <section className="space-y-4">
            <div className="flex items-center space-x-2 text-indigo-900 text-lg">
              <h2 className="text-2xl font-semibold text-gray-900  ">Introduction</h2>
            </div>
            <p className="text-gray-900 text-lg leading-relaxed">
              When you visit{' '}
              <a
                href="https://automoviecreator.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline hover:text-blue-700 transition-all"
              >
                https://automoviecreator.com/
              </a>
              , our Privacy Policy applies and outlines how we collect, protect, and share the information resulting from your use of our Service. We use this data to provide and enhance the Service, and by using the Service, you agree to the terms of this Policy. The definitions used in this Privacy Policy are the same as those in our Terms and Conditions.
            </p>

            <p className="text-gray-900 text-lg leading-relaxed">
              Please note that any information collected and used on behalf of our customers is subject to this Policy, but our customers may use the data in a manner that differs from this Policy. Our customers own this data, not us.
            </p>
            <p className="text-gray-900 text-lg leading-relaxed">
              Our Terms and Conditions govern the use of our Service, and together with this Privacy Policy, create an agreement between you and us.
            </p>
          </section>

          {/* Definitions */}
          <section className="space-y-3">
            <div className="flex items-center space-x-2 text-indigo-900 text-lg">
              <h2 className="text-2xl font-semibold text-gray-900  ">Definitions</h2>
            </div>
            <div className="space-y-4">
              <div className=" p-4 ">
                <h2 className="font-bold text-gray-900 text-lg ">Our Service</h2>
                <p className="text-gray-900 text-lg">
                  Refers to the{' '}
                  <a
                    href="https://automoviecreator.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline hover:text-blue-700 transition-all"
                  >
                    https://automoviecreator.com
                  </a>{' '}
                  website that we operate.
                </p>
              </div>

              <div className=" p-4 ">
                <h2 className="font-bold text-gray-900 text-lg ">Personal Data</h2>
                <p className="text-gray-900 text-lg">Refers to any information relating to a living individual that can be used to identify that person, either on its own or in combination with other data.</p>
              </div>

              <div className=" p-4 ">
                <h2 className="font-bold text-gray-900 text-lg ">Usage Data</h2>
                <p className="text-gray-900 text-lg">Is automatically collected data generated by the use of the Service or the Service's infrastructure, such as the duration of a page visit.</p>
              </div>

              <div className=" p-4 ">
                <h2 className="font-bold text-gray-900 text-lg ">Cookies</h2>
                <p className="text-gray-900 text-lg">Are small files that are stored on your computer or mobile device.</p>
              </div>

              <div className=" p-4 ">
                <h2 className="font-bold text-gray-900 text-lg ">Data Processors (or Service Providers)</h2>
                <p className="text-gray-900 text-lg">Are any natural or legal persons who process data on behalf of the Data Controller. We may use various Service Providers to process your data more efficiently.</p>
              </div>

              <div className=" p-4 ">
                <h2 className="font-bold text-gray-900 text-lg ">Data Subject</h2>
                <p className="text-gray-900 text-lg">Refers to any living individual who is the subject of Personal Data.</p>
              </div>

              <div className=" p-4 ">
                <h2 className="font-bold text-gray-900 text-lg ">The User</h2>
                <p className="text-gray-900 text-lg">Is the individual who uses our Service, and corresponds to the Data Subject, who is the subject of Personal Data.</p>
              </div>
            </div>
          </section>

          {/* Information Collection and Use */}
          <section className="space-y-4">
            <div className="flex items-center space-x-2 text-indigo-900 text-lg">

              <h2 className="text-2xl font-semibold text-gray-900  ">Information Collection and Use</h2>
            </div>
            <p className="text-gray-900 text-lg leading-relaxed">
              We collect various types of information for different purposes to provide and enhance our Service to you.
            </p>

            <div className="space-y-6">
              <div className=" p-4 ">
                <h2 className="font-bold text-gray-900 text-lg ">Personal Data</h2>
                <p className="text-gray-900 text-lg ">While using our Service, we may request that you provide us with certain personally identifiable information that can be used to contact or identify you. This information may include, but is not limited to:</p>
                <ul className="list-disc list-inside text-gray-900 text-lg space-y-1 ml-4">
                  <li>Email Address</li>
                  <li>First Name & Last Name</li>
                  <li>Phone Number</li>
                  <li>Address, State, Province, ZIP/Postal code, City</li>
                  <li>Cookies and Usage Data</li>
                </ul>
                <p className="text-gray-900 text-lg my-3">We may use your Personal Data to communicate with you by sending newsletters, marketing or promotional materials, and other information that we believe may be of interest to you. If you wish to stop receiving these communications, you can opt out by clicking on the unsubscribe link provided in the email.</p>
              </div>
              <div className=" p-4 ">
                <h2 className="font-bold text-gray-900 text-lg ">Usage Data</h2>
                <p className="text-gray-900 text-lg">
                  We may also collect Usage Data when you visit our Service or access it through a mobile device. This data includes information that your browser sends, such as your computer's Internet Protocol (IP) address, browser type and version, the pages of our Service you visit, the time and date of your visit, the time spent on those pages, and unique device identifiers, among other diagnostic data.
                </p>
              </div>

              <div className=" p-4 ">
                <h2 className="font-bold text-gray-900 text-lg ">Tracking Cookies Data</h2>
                <p className="text-gray-900 text-lg ">
                  We use cookies and similar tracking technologies to monitor and track user activity on our Service, and we store certain information related to this activity. Cookies are small data files that may contain an anonymous unique identifier. These files are sent to your browser from a website and stored on your device. We also use other tracking technologies like beacons, tags, and scripts to collect and track information, and to analyze and improve our Service. You can configure your browser to reject all cookies or to notify you when a cookie is being sent. However, if you choose not to accept cookies, you may not be able to access some parts of our Service.
                </p>
                <p className="text-gray-900 text-lg ">Types of cookies we use:</p>
                <ul className="list-disc list-inside text-gray-900 text-lg space-y-1 ml-4">
                  <li>Session Cookies: We use session cookies to run our Service.</li>
                  <li>Preference Cookies: We use preference cookies to remember your preferences and settings.</li>
                  <li>Security Cookies: We use security cookies for security purposes.</li>
                  <li>Advertising Cookies: We use advertising cookies to display ads that may be of interest to you.</li>
                </ul>
              </div>
            </div>
          </section>

          {/* Use of Data */}
          <section className="space-y-4">
            <div className="flex items-center space-x-2">

              <h2 className="text-2xl font-semibold text-gray-900  ">Use of Data</h2>
            </div>
            <div className=" p-4 ">
              <p className="text-gray-900 text-lg ">We use the information we collect for various purposes, including:</p>
              <ul className="list-disc list-inside text-gray-900 text-lg space-y-1 ml-4">
                <li>Providing and maintaining our Service</li>
                <li>Notifying you about changes to our Service</li>
                <li>Allowing you to participate in interactive features of our Service when you choose to do soProviding customer support</li>
                <li>Using the information in any other way we may describe when you provide the information</li>
                <li>Gathering analysis to improve our Service</li>
                <li>Monitoring usage of our Service</li>
                <li>Detecting, preventing, and addressing technical issues</li>
                <li>Fulfilling any other purpose for which you provide the information</li>
                <li>Carrying out our obligations and enforcing our rights arising from any contracts entered into between you and us, including for billing and collection</li>
                <li>Providing you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc</li>
                <li>Providing you with news, special offers, and general information about other goods, services, and events which we offer that are similar to those that you have already purchased or inquired about, unless you have opted not to receive such information</li>
                <li>Using the information for any other purpose with your consent.</li>
              </ul>
            </div>
          </section>

          {/* Retention of Data */}
          <section className="space-y-4">
            <div className="flex items-center space-x-2 text-indigo-900 text-lg">
              <h2 className="text-2xl font-semibold text-gray-900  ">Retention of Data</h2>
            </div>
            <div className=" p-4  space-y-3">
              <p className="text-gray-900 text-lg">
                We will only keep your Personal Data for as long as necessary for the purposes stated in this Privacy Policy. We may need to keep and use your Personal Data to comply with legal obligations, resolve disputes, and enforce agreements and policies. Usage Data is usually retained for a shorter period, unless it’s used to enhance security, improve functionality, or legal requirements demand it to be kept for a longer period.
              </p>
              <p className="text-gray-900 text-lg">
                After canceling a subscription, we typically delete call data (including transcriptions and analysis) after six months, unless legal obligations require us to keep it for a longer period. However, we keep the customer’s email address, name, and phone number for marketing purposes. Customers with this information may receive occasional promotional emails from  AutomovieCreator.
              </p>
            </div>
          </section>

          {/* Transfer of Data */}
          <section className="space-y-4">
            <div className="flex items-center space-x-2 text-indigo-900 text-lg">
              <h2 className="text-2xl font-semibold text-gray-900 g ">Transfer of Data</h2>
            </div>
            <div className=" p-4  space-y-3">
              <p className="text-gray-900 text-lg">
                We may transfer and store your information, including Personal Data, on computers located outside of your jurisdiction where the data protection laws may differ from those of your jurisdiction. By providing your information, you consent to this transfer.
              </p>
              <p className="text-gray-900 text-lg">
                We will take reasonable measures to ensure your data is treated securely and in accordance with this Privacy Policy. We will not transfer your Personal Data to any organization or country unless there are adequate controls in place, including the security of your data and other personal information.
              </p>
            </div>
          </section>

          {/* Disclosure of Data */}
          <section className="space-y-4">
            <div className="flex items-center space-x-2 text-indigo-900 text-lg">
              <h2 className="text-2xl font-semibold text-gray-900  ">Disclosure of Data</h2>
            </div>
            <div className=" p-4 ">
              <p className="text-gray-900 text-lg ">We may share the personal information we collect from you or that you provide in the following circumstances:</p>
              <ul className="space-y-3">
                <li className="text-gray-900 text-lg">
                  <span className="font-bold">Disclosure for Law Enforcement:</span> In some situations, we may be legally obligated to disclose your personal data to comply with the law or respond to valid requests by public authorities.
                </li>
                <li className="text-gray-900 text-lg">
                  <span className="font-bold">Business Transaction:</span> If our company or any of our subsidiaries undergo a merger, acquisition, or asset sale, your personal data may be transferred as a result.
                </li>
                <li className="text-gray-900 text-lg">
                  <span className="font-bold">Other Cases:</span> We may also disclose your information in other cases, such as to display your company's logo on our website or with your consent.
                </li>
              </ul>
            </div>
          </section>

          {/* Security of Data */}
          <section className="space-y-4">
            <div className="flex items-center space-x-2 text-indigo-900 text-lg">
              <h2 className="text-2xl font-semibold text-gray-900 ">Security of Data</h2>
            </div>
            <div className=" p-4 ">
              <p className="text-gray-900 text-lg">
                We understand the significance of securing your data; however, it's important to note that no method of transmitting or storing data electronically is completely secure. Although we take reasonable measures to protect your Personal Data, we cannot ensure its complete security.
              </p>
            </div>
          </section>

          {/* GDPR Rights */}
          <section className="space-y-4">
            <div className="flex items-center space-x-2 text-indigo-900 text-lg">
              <h2 className="text-2xl font-semibold text-gray-900 ">Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h2>
            </div>
            <div className=" p-4  space-y-4">
              <p className="text-gray-900 text-lg">
                If you are a resident of the European Union (EU) or European Economic Area (EEA), you have certain data protection rights under the General Data Protection Regulation (GDPR). We are committed to taking reasonable steps to enable you to correct, amend, delete, or limit the use of your Personal Data.
              </p>
              <p className="text-gray-900 text-lg">
                If you want to know what Personal Data we have about you or wish to have it removed from our systems, please email us at{' '}
                <a
                  href="mailto:info@automoviecreator.com"
                  className="text-blue-500 underline hover:text-blue-700 transition-all"
                >
                  info@automoviecreator.com
                </a>
                .
              </p>

              <div className="space-y-2">
                <p className="text-gray-900 text-lg font-bold">You have the following data protection rights:</p>
                <ul className="list-disc list-inside text-gray-900 text-lg space-y-2 ml-4">
                  <li>The right to access, update, or delete the information we have on you.</li>
                  <li>The right of rectification, which means you can request that we correct any inaccurate or incomplete information.</li>
                  <li>The right to object to our processing of your Personal Data.</li>
                  <li>The right of restriction, which means you can request that we limit the processing of your personal information.</li>
                  <li>The right to data portability, which means you can request a copy of your Personal Data in a structured, machine-readable format.</li>
                  <li>The right to withdraw consent, which means you can withdraw your consent at any time if we rely on it to process your personal information.</li>
                </ul>
                <p className='text-gray-900 text-lg' >Please note that we may need to verify your identity before responding to such requests. Please also note that we may not be able to provide our services without collecting certain necessary data.

                  For all such requests, we will only share data with the authorized account holder, admin, or sponsor of the account to maintain data confidentiality. Additionally, any data sharing will only be done through organization email IDs.

                  If you have any concerns about our collection and use of your Personal Data, you have the right to complain to a Data Protection Authority. Please contact your local data protection authority in the European Economic Area (EEA) for more information.</p>
              </div>
            </div>
          </section>

          {/* CalOPPA Rights */}
          <section className="space-y-4">
            <div className="flex items-center space-x-2 text-indigo-900 text-lg">

              <h2 className="text-2xl font-semibold text-gray-900 ">Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)</h2>
            </div>
            <div className=" p-4  space-y-4">
              <p className="text-gray-900 text-lg">
                CalOPPA is a California state law that requires all commercial websites and online services to have a privacy policy. This law applies not only to California but to any person or company operating websites that collect personally identifiable information from California consumers.
              </p>
              <div className="space-y-2">
                <p className="text-gray-900 text-lg font-bold">As per CalOPPA, we agree to the following:</p>
                <ul className="list-disc list-inside text-gray-900 text-lg space-y-2 ml-4">
                  <li>Users can browse our site anonymously.</li>
                  <li>We have included a clear and easily accessible Privacy Policy link on our website homepage.</li>
                  <li>Users will be notified of any changes made to the Privacy Policy on our Privacy Policy Page.</li>
                  <li>Users can modify their personal information by sending us an email at <a
                    href="mailto:info@automoviecreator.com"
                    className="text-blue-500 underline hover:text-blue-700 transition-all"
                  >
                    info@automoviecreator.com
                  </a></li>
                </ul>
              </div>
            </div>
          </section>

          {/* Service Providers */}
          <section className="space-y-4">
            <div className="flex items-center space-x-2 text-indigo-900 text-lg">

              <h2 className="text-2xl font-semibold text-gray-900 ">Service Providers</h2>
            </div>
            <div className=" p-4  space-y-4">
              <p className="text-gray-900 text-lg">
                To enhance our Service, we may collaborate with third-party companies and individuals ("Service Providers") who assist us in delivering and analysing our Service. These Service Providers may access your Personal Data solely to aid us in our work, and are bound not to share or exploit it for any other reason.
              </p>
            </div>
          </section>

          {/* Analytics */}
          <section className="space-y-4">
            <div className="flex items-center space-x-2 text-indigo-900 text-lg">

              <h2 className="text-2xl font-semibold text-gray-900  ">Analytics</h2>
            </div>
            <div className=" p-4  space-y-4">
              <p className="text-gray-900 text-lg">
                We use various web analytics services, such as Google Analytics, Segment.io, Flurry Analytics, and Mixpanel, to monitor and analyze the use of our Service. Google Analytics collects data to track and report website traffic, which is also shared with other Google services. Segment.io and Mixpanel are web traffic analysis tools, while Flurry Analytics is provided by Yahoo! Inc. These third-party service providers are bound by their own privacy policies and only have access to your Personal Data to perform services on our behalf. You can visit their respective web pages to learn more about their privacy practices and policies, as well as opt-out options.
              </p>
            </div>
          </section>

          {/* CI/CD Tools */}
          <section className="space-y-4">
            <div className="flex items-center space-x-2 text-indigo-900 text-lg">

              <h2 className="text-2xl font-semibold text-gray-900  ">CI/CD Tools</h2>
            </div>
            <div className=" p-4  space-y-3">
              <p className="text-gray-900 text-lg">
                To automate the development process of our Service, we may utilize third-party Service Providers.
              </p>
              <p className="text-gray-900 text-lg">
                One such provider is GitHub, which is operated by GitHub, Inc. This platform is used for hosting and reviewing code, managing projects, and building software.
              </p>
              <p className='text-gray-900 text-lg'>
                For details on the data collected by GitHub, its purpose, and the measures taken to safeguard it, please refer to the
                <a href="https://help.github.com/en/articles/github-privacy-statement" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                  GitHub Privacy Policy page
                </a>.
              </p>

            </div>
          </section>

          {/* Behavioral Remarketing */}
          <section className="space-y-4">
            <div className="flex items-center space-x-2 text-indigo-900 text-lg">

              <h2 className="text-2xl font-semibold text-gray-900 ">Behavioral Remarketing</h2>
            </div>
            <div className=" p-4  space-y-6">
              {/* Google Ads */}
              <div className="space-y-2">
                <h2 className="font-bold text-gray-900 text-lg">Google Ads (AdWords)</h2>
                <p className="text-gray-900 text-lg">
                  Google Ads (AdWords) remarketing service is offered by Google Inc. To customize Google Display Network ads and opt-out of Google Analytics for Display Advertising, visit the
                  <a href="http://www.google.com/settings/ads" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                    Google Ads Settings page
                  </a>.
                  You can also use the
                  <a href="https://tools.google.com/dlpage/gaoptout" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                    Google Analytics Opt-out Browser Add-on
                  </a> – to prevent your data from being collected and used by Google Analytics. For more details about Google’s privacy practices, please refer to the
                  <a href="https://policies.google.com/privacy?hl=en" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                    Google Privacy Terms web page
                  </a>.
                </p>

              </div>

              {/* Bing Ads */}
              <div className="space-y-2">
                <h2 className="font-bold text-gray-900 text-lg">Bing Ads Remarketing</h2>
                <p className="text-gray-900 text-lg">
                  Bing Ads remarketing service is provided by Microsoft Inc. To opt-out of Bing Ads interest-based ads, follow the instructions provided at
                  <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                    Bing Ads opt-out page
                  </a>.
                  To learn more about Microsoft’s privacy practices and policies, please visit their
                  <a href="https://privacy.microsoft.com/en-us/PrivacyStatement" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                    Privacy Policy page
                  </a>.
                </p>

              </div>

              {/* Twitter */}
              <div className="space-y-2">
                <h2 className="font-bold text-gray-900 text-lg">Twitter</h2>
                <p className="text-gray-900 text-lg">
                  Twitter remarketing service is provided by Twitter Inc. You can opt-out of Twitter’s interest-based ads by following the instructions at
                  <a href="https://support.twitter.com/articles/20170405" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                    Twitter’s opt-out page
                  </a>.
                  To learn more about Twitter’s privacy practices and policies, please visit their
                  <a href="https://twitter.com/privacy" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                    Privacy Policy page
                  </a>.
                </p>

              </div>

              {/* Facebook */}
              <div className="space-y-2">
                <h2 className="font-bold text-gray-900 text-lg">Facebook</h2>
                <p className="text-gray-900 text-lg">
                  Facebook remarketing service is provided by Facebook Inc. To learn more about Facebook’s interest-based advertising, visit
                  <a href="https://www.facebook.com/help/164968693837950" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                    Facebook’s interest-based advertising page
                  </a>.
                  To opt-out of Facebook’s interest-based ads, follow the instructions provided at
                  <a href="https://www.facebook.com/help/568137493302217" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                    Facebook opt-out page
                  </a>.
                  Facebook complies with the Digital Advertising Alliance’s Self-Regulatory Principles for Online Behavioral Advertising. You can also opt-out through the Digital Advertising Alliance in the USA at
                  <a href="http://www.aboutads.info/choices/" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                    aboutads.info
                  </a>, the Digital Advertising Alliance of Canada in Canada at
                  <a href="http://youradchoices.ca/" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                    youradchoices.ca
                  </a>, or the European Interactive Digital Advertising Alliance in Europe at
                  <a href="http://www.youronlinechoices.eu/" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                    youronlinechoices.eu
                  </a>.
                  You can also opt-out using your mobile device settings.
                </p>

              </div>
            </div>
          </section>

          {/* Payments */}
          <section className="space-y-4">
            <div className="flex items-center space-x-2 text-indigo-900 text-lg">

              <h2 className="text-2xl font-semibold text-gray-900  ">Payments</h2>
            </div>
            <div className=" p-4  space-y-3">
              <p className="text-gray-900 text-lg">
                Facebook remarketing service is provided by Facebook Inc. To learn more about Facebook’s interest-based advertising, visit
                <a href="https://www.facebook.com/help/164968693837950" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                  Facebook’s interest-based advertising page
                </a>.
                To opt-out of Facebook’s interest-based ads, follow the instructions provided at
                <a href="https://www.facebook.com/help/568137493302217" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                  Facebook opt-out page
                </a>.
                Facebook complies with the Digital Advertising Alliance’s Self-Regulatory Principles for Online Behavioral Advertising. You can also opt-out through the Digital Advertising Alliance in the USA at
                <a href="http://www.aboutads.info/choices/" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                  aboutads.info
                </a>, the Digital Advertising Alliance of Canada in Canada at
                <a href="http://youradchoices.ca/" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                  youradchoices.ca
                </a>, or the European Interactive Digital Advertising Alliance in Europe at
                <a href="http://www.youronlinechoices.eu/" className="text-blue-900 text-lg hover:underline" target="_blank" rel="noopener noreferrer">
                  youronlinechoices.eu
                </a>.
                You can also opt-out using your mobile device settings.
              </p>

              <p className="text-gray-900 text-lg">
                These payment processors comply with PCI-DSS standards, which are managed by the PCI Security Standards Council, a collaboration of major credit card brands such as Visa, Mastercard, American Express, and Discover. The PCI-DSS requirements ensure the secure handling of payment information.
              </p>
            </div>
          </section>

          {/* Links to Other Sites */}
          <section className="space-y-4">
            <div className="flex items-center space-x-2 text-indigo-900 text-lg">

              <h2 className="text-2xl font-semibold text-gray-900  ">Links to Other Sites</h2>
            </div>
            <div className=" p-4  space-y-3">
              <p className="text-gray-900 text-lg">
                Our Service may include hyperlinks to other websites that are not controlled or operated by us. Clicking on a hyperlink to a third-party website will redirect you to that website, and we recommend that you read the Privacy Policy of every website you visit.
              </p>
              <p className="text-gray-900 text-lg">
                We are not responsible for the content, privacy policies, or practices of any third-party websites or services, and we do not have control over them.
              </p>
            </div>
          </section>

          {/* Children's Privacy */}
          <section className="space-y-4">
            <div className="flex items-center space-x-2 text-indigo-900 text-lg">

              <h2 className="text-2xl font-semibold text-gray-900  ">Children's Privacy</h2>
            </div>
            <div className=" p-4  space-y-3">
              <p className="text-gray-900 text-lg">
                Our Service is designed and intended for use by individuals who are 18 years of age or older. We do not knowingly collect personal information from children under the age of 18.
              </p>
              <p className="text-gray-900 text-lg">
                If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us immediately so that we can take the necessary steps to remove the information from our servers.
              </p>
              <p className="text-gray-900 text-lg">
                We take the protection of children's privacy very seriously and will promptly take appropriate action to safeguard their information.
              </p>
            </div>
          </section>

          {/* Changes to Privacy Policy */}
          <section className="space-y-4">
            <div className="flex items-center space-x-2 text-indigo-900 text-lg">

              <h2 className="text-2xl font-semibold text-gray-900  ">Changes to This Privacy Policy</h2>
            </div>
            <div className=" p-4  space-y-3">
              <p className="text-gray-900 text-lg">
                We may make changes to our Privacy Policy occasionally. If we make any changes, we will post the updated Privacy Policy on this page and update the "effective date" at the top of the page.
              </p>
              <p className="text-gray-900 text-lg">
                We recommend that you check this page periodically to stay informed of any changes. Any changes to this Privacy Policy become effective when they are posted on this page.
              </p>
            </div>
          </section>


        </div>
      </main>


    </div>
  );
}



export default PrivacyPolicy;