import React from 'react';
import {  Clock, CreditCard, Mail, DollarSign ,Phone} from 'lucide-react';

function RefundPolicys() {
  return (
    <div className="min-h-screen mt-10">
      {/* Header */}
     

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className=" p-6 md:p-8 space-y-8">
          {/* Introduction */}
          <section className="space-y-4">
            <div className="  p-4 ">
              <p className="text-lg">
                At Next Solutions (“we”, “us”, or “our”), we value our customers and aim to provide exceptional IT services through our website. This Refund Policy outlines our commitment to customer satisfaction and the process for requesting a refund.
              </p>
              <p className="text-gray-900  text-lg mt-3">
                Please read this policy carefully before purchasing any of our products or services. By using our services, you agree to the terms and conditions of this Refund Policy.
              </p>
            </div>
          </section>

          {/* Refund Eligibility */}
          <section className="space-y-4">
            <div className="grid gap-4 md:grid-row-2">
              <div className=" p-6 ">
                <h3 className="text-2xl font-semibold text-gray-900 mb-6">
                  
                  Refund Eligibility
                </h3>
                <p className="text-gray-900 text-lg">
                  Refunds may be granted under the following conditions:
                </p>
                <ul className="list-disc  text-lg pl-6 text-gray-900 ">
                  <li>The product or service does not meet the description provided on our website.</li>
                  <li>The product or service has significant defects or malfunctions.</li>
                  <li>A valid refund request is submitted within the specified refund window.</li>
                </ul>
                <p className="text-gray-900 text-lg mt-3">
                  Refunds are not granted for:
                </p>
                <ul className="list-disc pl-6 text-lg text-gray-900 ">
                  <li>Change of mind or dissatisfaction not related to a policy breach.</li>
                  <li>Incompatibility issues not disclosed in the product description.</li>
                  <li>Misuse, unauthorized modifications, or damage caused by the user.</li>
                </ul>
              </div>

              <div className=" p-6 ">
                <h3 className="text-2xl font-semibold text-gray-900 mb-6">
                  
                  Refund Request Process
                </h3>
                <p className="text-gray-900 text-lg">
                  To request a refund, follow these steps:
                </p>
                <ul className="list-decimal pl-6 text-lg text-gray-900 mt-3">
                  <li>Contact our support team at <a href="mailto:info@automoviecreator.com" className="text-indigo-600 hover:underline">info@automoviecreator.com</a> with the subject line “Refund Request.”</li>
                  <li>Provide your order number, product details, and a detailed explanation of the issue.</li>
                  <li>Attach relevant documentation such as screenshots, error messages, or correspondence with support.</li>
                </ul>
                <p className="text-gray-900 text-lg mt-3">
                  Allow up to 5 business days for our team to review your request and respond. Refunds will be processed using the original payment method. Processing times may vary depending on your financial institution.
                </p>
              </div>
            </div>
          </section>

          {/* Exceptions and Policy Changes */}
          <section className="grid gap-4 md:grid-row-2">
            <div className=" p-6 ">
              <h3 className="text-2xl font-semibold text-gray-900 mb-6">
                Exceptions
              </h3>
              <p className="text-gray-900 text-lg">
                In certain circumstances, we may provide a refund or exchange at our sole discretion, even if the conditions outlined in this Refund Policy are not met. These exceptions will be assessed on a case-by-case basis.
              </p>
            </div>

            <div className=" p-6 ">
              <h3 className="font-semibold text-gray-900 mb-2 flex items-center gap-2">
                Changes to This Refund Policy
              </h3>
              <p className="text-gray-900 text-lg">
                We reserve the right to modify this Refund Policy at any time. Any changes will be effective when posted on our website. We encourage you to review this policy periodically to stay informed about our refund practices.
              </p>
            </div>
          </section>

          {/* Contact Section */}
        


        </div>
      </main>
    </div>
  );
}

export default RefundPolicys;
