import React from 'react';

function InternationalRefundPolicy() {
  return (
    <div className="min-h-screen py-12">
      {/* Header */}
      <header className="text-center mt-5 mb-12">
        <div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-5xl font-extrabold text-gray-900 leading-tight">
            International Refund Policy
          </h1>

        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="space-y-12">

          {/* Introduction */}
          <section>
            <p className="text-xl text-gray-700 ml-2 ">
              At automoviecreator, we are committed to providing a seamless and transparent transaction experience for our international customers. Below are the guidelines for refunds on international transactions.
            </p>
          </section>

          {/* Refund Turnaround Time */}
          <section className=" p-8 ">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">Refund Turnaround Time</h2>
            <div className="space-y-6">
              <div>
                <h3 className="text-xl font-medium text-gray-900">Processing Time</h3>
                <p className="text-lg text-gray-800">Refunds are processed within 15 business days from the date of refund request approval.</p>
              </div>
              <div>
                <h3 className="text-xl font-medium text-gray-900">Refund Method</h3>
                <p className="text-lg text-gray-800">Refunds will be processed through the original payment method used for the transaction.</p>
              </div>
              <div>
                <h3 className="text-xl font-medium text-gray-900">Currency</h3>
                <p className="text-lg text-gray-800">Refunds will be issued in the same currency as the original transaction.</p>
              </div>
            </div>
          </section>

          {/* How to Request a Refund */}
          <section className=" p-8 ">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">How to Request a Refund</h2>
            <div className="space-y-6">
              <div>
                <h3 className="text-xl font-medium text-gray-900">Contact Customer Service</h3>
                <p className="text-lg text-gray-800">Email our customer service team to initiate your refund request.</p>
              </div>
              <div>
                <h3 className="text-xl font-medium text-gray-900">Provide Transaction Details</h3>
                <p className="text-lg text-gray-800">Include your order number, transaction date, and reason for the refund.</p>
              </div>
              <div>
                <h3 className="text-xl font-medium text-gray-900">Wait for Approval</h3>
                <p className="text-lg text-gray-800">We will review your request and respond within 5 business days.</p>
              </div>
            </div>
          </section>

          {/* Conditions for Refund */}
          <section className=" p-8 ">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">Conditions for Refund</h2>
            <div className="space-y-6">
              <div>
                <h3 className="text-xl font-medium text-gray-900">Eligibility</h3>
                <p className="text-lg text-gray-800">Refunds are eligible only if specified on the website or email as part of the provided product/service offering.</p>
              </div>
              <div>
                <h3 className="text-xl font-medium text-gray-900">Time Limit</h3>
                <p className="text-lg text-gray-800">Refund requests must be made within 15 days from the date of the original transaction.</p>
              </div>
            </div>
          </section>

          {/* Additional Information */}
          <section className=" p-8 ">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">Additional Information</h2>
            <div className="space-y-6">
              <div>
                <h3 className="text-xl font-medium text-gray-900">Exchange Rates</h3>
                <p className="text-lg text-gray-800">Refunds are processed based on the exchange rate at the time of processing, not the transaction date.</p>
              </div>
              <div>
                <h3 className="text-xl font-medium text-gray-900">Fees</h3>
                <p className="text-lg text-gray-800">International transaction fees or bank charges are typically non-refundable.</p>
              </div>
            </div>
          </section>

          {/* Contact Section */}
          <section className=" p-8 ">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">Contact Us</h3>
            <p className="text-lg text-gray-800">
              For any queries or further clarification regarding our international refund policy, please do not hesitate to contact us at{' '}
              <a href="mailto:info@automoviecreator.com" className="text-blue-600 hover:underline">
                info@automoviecreator.com
              </a>
              <br />
              Phone: <a href="tel:+918130339698" className="text-blue-600 hover:underline">+918130339698</a>
            </p>
          </section>

          {/* Thank You Note */}
          <section className="text-center">
            <p className="text-lg text-gray-800">
              Thank you for choosing automoviecreator! We are dedicated to ensuring your satisfaction with every transaction.
            </p>
          </section>
        </div>
      </main>
    </div>
  );
}

export default InternationalRefundPolicy;
