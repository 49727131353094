import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../provider/AuthProvider';
import axios from 'axios';
import { serverbaseURL } from '../constant/index.jsx';
import { Calendar, Clock, MessageSquare, Plus, X, Loader2, Send, Trash2, Repeat } from 'lucide-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Wand2 } from 'lucide-react';


// Update the formatDateToISO function
// function formatDateToISO(date, time) {
//   const [hours, minutes] = time.split(':').map(Number);

//   // Create date in local timezone
//   const localDate = new Date(
//     date.getFullYear(),
//     date.getMonth(),
//     date.getDate(),
//     hours,
//     minutes
//   );

//   // Convert to UTC
//   return new Date(localDate.getTime() - (localDate.getTimezoneOffset() * 60000))
//     .toISOString()
//     .slice(0, 19)
//     .replace('T', ' ');
// }





function formatDateToISO(date, time) {
  const [hours, minutes] = time.split(':').map(Number);

  // Create date object in local timezone
  const localDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hours,
    minutes
  );

  // Convert to UTC and format as 'YYYY-MM-DD HH:mm:ss'
  return localDate.toISOString().slice(0, 19).replace('T', ' ');
}


const SchedulePost = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState('');
  const [connectedAccounts, setConnectedAccounts] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [caption, setCaption] = useState(location.state.topic || ""); // Initialize with topic
  const [isSumbit, setIsSumbit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [frequency, setFrequency] = useState('once');
  const [selectedDays, setSelectedDays] = useState([]);
  // Add these state variables at the top of your component
  const [generatorPrompts, setGeneratorPrompts] = useState(['', '']);
  const [generatorOutputs, setGeneratorOutputs] = useState(['', '']);
  const [isLoadingGenerators, setIsLoadingGenerators] = useState([false, false]);
  // Add with other state declarations
  const [description, setDescription] = useState("");
  const [isFetchingAccounts, setIsFetchingAccounts] = useState(true);





  // Ensure caption is set when location.state.topic changes
  useEffect(() => {
    setCaption(location.state.topic || "");
  }, [location.state.topic]);


  // Add this effect to initialize prompts with the topic
  useEffect(() => {
    const topic = location.state.topic || "";
    setGeneratorPrompts([
      `Generate a catchy title  for a short video about ${topic}`,
      `Create an engaging video description for content about ${topic}`
    ]);
  }, [location.state.topic]);

  // Add this handler function
  const handleGenerate = async (index) => {
    if (!generatorPrompts[index].trim()) {
      toast.error("Please enter a valid prompt before generating.");
      return;
    }
    try {
      // const newLoadingStates = [...isLoadingGenerators];
      // newLoadingStates[index] = true;
      // setIsLoadingGenerators(newLoadingStates);
      // Start loading only for the clicked button
      setIsLoadingGenerators((prev) => {
        const newLoadingState = [...prev];
        newLoadingState[index] = true;
        return newLoadingState;
      });


      const response = await axios.post(`${serverbaseURL}schedule-chat-completion`, {
        prompt: generatorPrompts[index]
      });
      console.log(response);
      const newOutputs = [...generatorOutputs];
      newOutputs[index] = response.data;
      setGeneratorOutputs(newOutputs);
    } catch (error) {
      toast.error(`Generation failed: ${error.message}`);
    } finally {
      // const newLoadingStates = [...isLoadingGenerators];
      // newLoadingStates[index] = false;
      // setIsLoadingGenerators(newLoadingStates);
      setIsLoadingGenerators((prev) => {
        const newLoadingState = [...prev];
        newLoadingState[index] = false;
        return newLoadingState;
      });
    }
  };



  const fetchConnectedAccounts = async () => {
    try {
      setIsFetchingAccounts(true);
      const response = await axios.post(`${serverbaseURL}fetch-accounts`, {
        uid: user.uid,
        email: user.email,
      });
      setConnectedAccounts(response.data.accounts);
    } catch (error) {
      console.error('Error fetching connected accounts:', error);
    } finally {
      setIsFetchingAccounts(false);
    }
  };

  useEffect(() => {
    fetchConnectedAccounts();
  }, [user]);


  const handleDateChange = (event) => {
    setSelectedDate(new Date(event.target.value));
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleSocialConnect = async (provider) => {

    // Check if user already has 3 connected accounts
    if (connectedAccounts.length >= 3) {
      toast.error("You've reached the maximum of 3 connected accounts. Please remove an existing account before adding a new one.");
      return;
    }
    setShowSidebar(false);
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.uid || !user.email) {
        console.error("User information is missing from localStorage");
        return;
      }
      const { uid, email } = user;
      const response = await axios.post(`${serverbaseURL}connect-social-account`, {
        provider,
        uid,
        email,
      });
      // const { connect_url } = response.data;
      // if (connect_url) {
      //   window.open(connect_url, '_blank');
      // }

      const popup = window.open(response.data.connect_url, '_blank', 'width=600,height=700');

      if (!popup) {
        toast.error("Popup blocked! Please allow popups and try again.");
        return;
      }



      // Check periodically if popup is closed
      const popupCheckInterval = setInterval(() => {
        if (popup.closed) {
          clearInterval(popupCheckInterval);
          fetchConnectedAccounts(); // Refresh accounts list after popup closes
          // toast.success('Account connected successfully!');
        }
      }, 500);
    } catch (error) {
      console.error("Error connecting social account:", error);
    }
  };



  // const handleRemoveAccount = async (accountId) => {
  //   console.log(accountId);
  //   try {
  //     const response = await axios.delete(`${serverbaseURL}account/${accountId}`, {
  //       data: { uid: user.uid },
  //       headers: { 'Content-Type': 'application/json' },
  //     });

  //     if (response.status === 200) {
  //       setConnectedAccounts(connectedAccounts.filter((account) => account.id !== accountId));
  //       toast.success('Account successfully removed!');
  //     }
  //   } catch (error) {
  //     console.error('Failed to remove account:', error);
  //     toast.error('Failed to remove account. Please try again.');
  //   }
  // };

  const handleRemoveAccount = async (accountId) => {
    console.log("Attempting to remove account with ID:", accountId);

    try {
      const url = `${serverbaseURL}account/${accountId}`;
      console.log("API URL:", url);

      const requestData = { uid: user.uid };
      console.log("Request Data:", requestData);

      const response = await axios.delete(url, {
        data: requestData,
        headers: { 'Content-Type': 'application/json' },
      });

      console.log("Response:", response);

      if (response.status === 200) {
        setConnectedAccounts(connectedAccounts.filter((account) => account.id !== accountId));
        toast.success('Account successfully removed!');
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error during deletion request:", error);

      if (error.response) {
        console.error("Response Data:", error.response.data);
        console.error("Response Status:", error.response.status);
        console.error("Response Headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received. Request Details:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }

      toast.error('Failed to remove account. Please try again.');
    }
  };

  const handleSubmit = async () => {
    try {
      // Validate day selection for weekly/biweekly
      if ((frequency === 'weekly' || frequency === 'biweekly') && selectedDays.length === 0) {
        toast.error('Please select at least 1 day for weekly scheduling');
        return;
      }

      if ((frequency === 'weekly' || frequency === 'biweekly') && selectedDays.length > 3) {
        toast.error('Maximum 3 days allowed for weekly scheduling');
        return;
      }
      setIsLoading(true);
      const publishAt = formatDateToISO(selectedDate, selectedTime);
      const accountIds = connectedAccounts.filter((a) => a.selected).map((a) => a.id);
      const content = description;
      const videoTitle = location.state.topic;
      const recurrence = {
        frequency,
        selectedDays,
        interval: frequency === 'biweekly' ? 2 : 1
      };


      const scheduledPostResponse = await axios.post(`${serverbaseURL}schedule-video-post`, {
        videoUrl: location.state.videoUrl,
        accountIds,
        publishAt,
        content,
        videoTitle,
        recurrence
      });

      if (scheduledPostResponse.status === 200) {
        toast.success('Post scheduled successfully!');
        navigate('/my-generations');
      }
    } catch (error) {
      toast.error('Failed to schedule the post. Please try again.');
      console.error('Error scheduling post:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitnow = async () => {
    try {
      setIsSumbit(true);
      const accountIds = connectedAccounts.filter((a) => a.selected).map((a) => a.id);
      const content = description;
      const videoTitle = caption;

      const scheduledPostResponse = await axios.post(`${serverbaseURL}post-video-now`, {
        videoUrl: location.state.videoUrl,
        accountIds,
        content,
        videoTitle,
      });

      if (scheduledPostResponse.status === 200) {
        toast.success('Post published immediately!');
        navigate('/my-generations');
      }
    } catch (error) {
      toast.error('Failed to schedule the post. Please try again.');
      console.error('Error scheduling post:', error);
    } finally {
      setIsSumbit(false);
    }
  };


  return (
    <div className="min-h-screen w-full shadow-lg rounded-xl p-4 md:p-8 py-6 md:py-10">

      <h2 className="text-3xl md:text-4xl font-semibold font-custom text-gray-800 mt-3 py-3 text-center">Schedule Post</h2>
      <h3 className="text-lg md:text-2xl font-semibold text-gray-800 text-center mb-5"> You can maximum connect the 3 account at once time's </h3>
      <div className="flex flex-col md:flex-row mt-10 gap-8 justify-evenly">

        <div className="w-full md:w-2/5">
          <div className="max-w-2xl mx-auto">

            <div className='mt-4' >
              {isFetchingAccounts ? (
                <div className="text-center  py-4">
                  <Loader2 className="w-8 h-8 animate-spin text-purple-600 mx-auto" />
                  <p className="mt-2 text-gray-600">Loading connected accounts...</p>
                </div>
              ) : (
                <>
                  {connectedAccounts.map((account) => (
                    <div className="mb-8 md:mb-10">

                      <div className="w-full md:w-1/2">
                        <div className="border-[1px] border-[#805af5] rounded-t-xl bg-[#f8f8ff] shadow-lg hover:shadow-xl hover:border-[#0d6efd] overflow-hidden">
                          <div
                            key={account.id}
                            className="group mb-3 last:mb-0"
                          >
                            {/* Header */}
                            <div className="bg-gradient-to-r from-purple-500 to-indigo-600 px-5 py-3">
                              <h3 className="text-lg font-semibold text-white">Connected Accounts</h3>
                            </div>
                            <div className="flex items-center p-4 border border-gray-100 rounded-xl bg-[#efefff]  transition-all duration-200 hover:border-purple-200 hover:shadow-md">
                              <div className="flex-shrink-0">
                                <input
                                  type="checkbox"
                                  checked={account.selected}
                                  onChange={() => {
                                    setConnectedAccounts(
                                      connectedAccounts.map((a) =>
                                        a.id === account.id ? { ...a, selected: !a.selected } : a
                                      )
                                    );
                                  }}
                                  className="h-5 w-5 rounded border-gray-300 text-purple-600 focus:ring-purple-500 cursor-pointer"
                                />
                              </div>

                              <div className="ml-4 flex-grow">
                                <h4 className="text-[#55595c] font-medium capitalize">{account.name}</h4>
                                <p className="text-gray-500 text-sm">{account.type}</p>
                              </div>

                              <button
                                onClick={() => handleRemoveAccount(account.id)}
                                className="ml-4 p-2 text-gray-400 hover:text-red-600 rounded-full hover:bg-red-50 transition-colors duration-200"
                                title="Remove account"
                              >
                                <Trash2 size={18} />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="bg-[#efefff] shadow-lg rounded-xl p-4 md:p-6">
                        <div className="mb-4">
                          <label className="flex items-center text-sm md:text-base font-semibold text-[#55595c] mb-1">
                            <Calendar className="w-5 h-5 text-[#55595c]" />
                            <span className="ml-2">Select Date</span>
                          </label>
                          <input
                            type="date"
                            value={selectedDate.toISOString().slice(0, 10)}
                            onChange={handleDateChange}
                            className="w-full px-3 md:px-4 py-2 mt-2 rounded-lg bg-[#f9f9f9] border border-[#d1d1d6] focus:border-[#4f75e1] focus:ring-[#4f75e1] focus:outline-none transition duration-200"
                          />
                        </div>

                        <div className="mb-4">
                          <label className="flex items-center text-base font-semibold text-[#55595c] mb-1">
                            <Clock className="w-5 h-5 text-[#55595c]" />
                            <span className="ml-2">Select Time (Local) Time will be converted to UTC</span>
                          </label>
                          <input
                            type="time"
                            value={selectedTime}
                            onChange={handleTimeChange}
                            className="w-full px-4 py-2 mt-2 rounded-lg bg-[#f9f9f9] border border-[#d1d1d6] focus:border-[#4f75e1] focus:ring-[#4f75e1] focus:outline-none transition duration-200"
                          />
                          {/* <p className="text-sm text-gray-500 mt-1">Time will be converted to UTC</p> */}
                        </div>

                        <div className="mb-4">
                          <label className="flex items-center text-base font-semibold text-[#55595c] mb-1">
                            <MessageSquare className="w-5 h-5 text-[#55595c]" />
                            <span className="ml-2">Write a caption or title for your video</span>
                          </label>
                          <div className="relative">
                            <textarea

                              value={caption}
                              onChange={(e) => setCaption(e.target.value)}
                              className="w-full px-4 py-2 mt-2 rounded-lg bg-[#f9f9f9] border border-[#d1d1d6] focus:border-[#4f75e1] focus:ring-[#4f75e1] focus:outline-none transition duration-200 resize-none"
                              rows="1"
                            />
                            {/* <button
                              className="absolute right-3 bottom-3 text-[#4f75e1] hover:text-blue-600 font-semibold"
                              onClick={() => setCaption(location.state.topic)}
                            >
                              Reset
                            </button> */}
                          </div>

                        </div>
                        <div className="mb-4">
                          <label className="flex items-center text-base font-semibold text-[#55595c] mb-1">
                            <MessageSquare className="w-5 h-5 text-[#55595c]" />
                            <span className="ml-2">Write a description for your video</span>
                          </label>
                          <div className="relative">
                            <textarea

                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              className="w-full px-4 py-2 mt-2 rounded-lg bg-[#f9f9f9] border border-[#d1d1d6] focus:border-[#4f75e1] focus:ring-[#4f75e1] focus:outline-none transition duration-200 resize-none"
                              rows="1"
                            />
                            {/* <button
                              className="absolute right-3 bottom-3 text-[#4f75e1] hover:text-blue-600 font-semibold"
                              onClick={() => setDescription("")}                        >
                              Reset
                            </button> */}
                          </div>

                        </div>
                      </div>

                    </div>

                  ))}
                  {connectedAccounts.length === 0 && (
                    <div className="col-span-full text-lg text-center pt-3 text-[#55595c]">
                      No social accounts connected. Click "Add Socials" to connect.
                    </div>
                  )}
                </>
              )}

            </div>

            <div className="p-4 md:p-6">
              <div className="flex flex-col md:flex-row justify-center gap-4 md:gap-6">
                <button
                  onClick={() => setShowSidebar(true)}
                  className="flex items-center justify-center px-4 md:px-6 py-3 text-sm md:text-md bg-gradient-to-r from-[#805af5] to-blue-600 text-white   rounded-md text-md font-semibold hover:shadow-lg transition duration-200"
                >
                  <Plus className="w-5 h-5 mr-2" />
                  Add Social
                </button>
                <button
                  onClick={handleSubmit}
                  disabled={!selectedTime || isLoading}
                  className="flex items-center  justify-center px-4 md:px-6 py-3 text-sm md:text-md gap-2  bg-white  border-[1px] border-[#805af5] text-black rounded-lg hover:bg-blue-700 hover:text-white transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isLoading ? (
                    <Loader2 className="w-5 h-5 animate-spin" />
                  ) : (
                    <Send className="w-5 h-5" />
                  )}
                  {isLoading ? 'Scheduling...' : 'Schedule Post'}
                </button>


                <button
                  onClick={handleSubmitnow}
                  disabled={!caption && !description || isSumbit}
                  className="flex items-center gap-2  justify-center px-4 md:px-6 py-3 text-sm md:text-md bg-white  border-[1px] border-[#805af5] text-black rounded-lg hover:bg-blue-700 hover:text-white transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSumbit ? (
                    <Loader2 className="w-5 h-5 animate-spin" />
                  ) : (
                    <Send className="w-5 h-5" />
                  )}
                  {isSumbit ? 'Posting...' : ' Post Now'}
                </button>


              </div>
            </div>
          </div>
        </div>
        {connectedAccounts.length > 0 && (
          <div className="w-full md:w-1/2 mt-6 md:mt-10">
            {/* <div className="bg-[#efefff] shadow-lg rounded-xl mb-4 p-4 md:p-6">

              <div className="mb-4">
                <label className="flex items-center text-base font-semibold   md:text-base text-[#55595c] mb-1">
                  <Repeat className="w-5 h-5 text-[#55595c]" />
                  <span className="ml-2">Recurrence</span>
                </label>
                <select
                  value={frequency}
                  onChange={(e) => setFrequency(e.target.value)}
                  className="w-full px-4 py-2 mt-2 rounded-lg bg-[#f9f9f9] border border-[#d1d1d6]"
                >
                  <option value="once">Once</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="biweekly">Bi-Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>

           
              {(frequency === 'weekly' || frequency === 'biweekly') && (
                <div className="mb-4">
                  <div className="flex items-center justify-between mb-1">
                    <label className="flex items-center text-base font-semibold text-[#55595c]">
                      <Calendar className="w-5 h-5 text-[#55595c]" />
                      <span className="ml-2">Repeat on Days</span>
                    </label>
                    <span className="text-xs md:text-sm text-gray-500">

                      {selectedDays.length}/3 days selected
                    </span>
                  </div>
                  <div className="grid grid-cols-2 md:flex md:flex-wrap gap-2 mt-2">
                    {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                      <label key={day} className="flex items-center space-x-2 md:space-x-1">
                        <input
                          type="checkbox"
                          checked={selectedDays.includes(day)}
                          onChange={(e) => {
                            if (e.target.checked && selectedDays.length >= 3) {
                              toast.error("Maximum 3 days allowed for weekly scheduling");
                              return;
                            }
                            const newDays = e.target.checked
                              ? [...selectedDays, day]
                              : selectedDays.filter(d => d !== day);
                            setSelectedDays(newDays);
                          }}
                          className="h-5 w-5 rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                          disabled={selectedDays.length >= 3 && !selectedDays.includes(day)}
                        />
                        <span className={`text-gray-700 ${selectedDays.length >= 3 && !selectedDays.includes(day)
                          ? 'opacity-50'
                          : ''
                          }`}>
                          {day}
                        </span>
                      </label>
                    ))}
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    Select 1-3 days for weekly and bi-weekly scheduling
                  </p>
                </div>
              )}
            </div> */}
            <div className="max-w-4xl mx-auto sticky top-24 ">
              <div className="bg-[#efefff] rounded-2xl shadow-xl  p-4 md:p-8 ">
                <h1 className="text-2xl md:text-3xl font-bold 6 md:mb-8 text-gray-800 mb-8">
                  Content Generator
                </h1>
                <div className="space-y-4 md:space-y-6">

                  {[0, 1].map((index) => (
                    <div key={index} className="bg-white border border-[#d1d1d6] p-4 md:p-6 rounded-xl">
                      <label className="block text-gray-800 text-base md:text-lg font-semibold mb-2">
                        {index === 0
                          ? "Prompt to generate Title and Caption"
                          : "Prompt to generate Description"}
                      </label>
                      <div className="flex flex-col md:flex-row gap-3">

                        <input
                          type="text"
                          value={generatorPrompts[index]}
                          onChange={(e) => {
                            const newPrompts = [...generatorPrompts];
                            newPrompts[index] = e.target.value;
                            setGeneratorPrompts(newPrompts);
                          }}
                          className="flex-1 px-4 py-3 bg-[#f9f9f9] border border-gray-950 text-gray-700 rounded-lg input-focus-ring"
                          placeholder={
                            index === 0
                              ? `Modern title and caption for your topic "${location.state.topic}"`
                              : `Modern description for your topic "${location.state.topic}"`
                          }
                        />
                        <button
                          className="px-6 py-3 sm:text-center bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white rounded-lg font-medium flex items-center gap-2 transition-all duration-300 hover:shadow-lg hover:scale-[1.02]"
                          onClick={() => handleGenerate(index)}
                          disabled={isLoadingGenerators[index]}
                        >
                          {isLoadingGenerators[index] ? (
                            <Loader2 className="w-5 h-5 animate-spin" />
                          ) : (
                            <>
                              <Wand2 size={18} className="animate-pulse" />
                              Generate
                            </>
                          )}
                        </button>
                      </div>

                      {generatorOutputs[index] && (
                        <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                          <div className="flex justify-between items-start mb-2">
                            <h4 className="font-medium text-gray-700">Generated Content:</h4>
                            <button
                              onClick={() => {
                                if (index === 0) {
                                  setCaption(generatorOutputs[index]);
                                } else {
                                  setDescription(generatorOutputs[index]);
                                }
                                toast.success('Content applied to form!');
                              }}
                              className="px-3 py-1 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
                            >
                              Apply
                            </button>
                          </div>
                          <p className="text-gray-600 whitespace-pre-wrap">
                            {generatorOutputs[index]}
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
      {showSidebar && (
        <div className="sidebar fixed top-0 right-0 w-full h-full bg-white shadow-lg z-50 p-6 md:p-8 transition-transform duration-300 ease-in-out transform 
    ${showSidebar ? 'translate-x-0' : 'translate-x-full'}  // Dynamic translation based on state
    md:w-1/4 md:translate-x-0 lg:w-[25%] xl:w-[20%] 2xl:w-[18%]">

          {/* Close button - position adjusted for better mobile UX */}
          <button
            onClick={() => setShowSidebar(false)}
            className="absolute top-4 right-4 text-gray-600 hover:text-indigo-600 transition-all duration-200 md:left-4 md:right-auto"
          >
            <X className="w-6 h-6" />
          </button>

          {/* Content container */}
          <h2 className="text-lg font-semibold text-gray-700 mt-3 mb-6 text-center md:text-left">
            Connect Social Account
          </h2>

          {/* Scrollable area with mobile-optimized spacing */}
          <div className="space-y-4 md:space-y-6 text-base overflow-y-auto max-h-[calc(100vh-120px)] pb-4">
            {[
              'facebook', 'twitter', 'instagram', 'linkedin',
              'google', 'mastodon', 'reddit', 'youtube', 'threads'
            ].map((provider) => (
              <button
                key={provider}
                onClick={() => handleSocialConnect(provider)}
                className="block w-full text-left px-4 py-3 bg-gray-100 rounded-lg 
                     hover:text-violet-500 hover:bg-indigo-50 focus:ring-2 
                     focus:ring-indigo-500 transition-colors duration-300 
                     capitalize text-sm md:text-base"
              >
                {provider.charAt(0).toUpperCase() + provider.slice(1)}
              </button>
            ))}
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default SchedulePost;