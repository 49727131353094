import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { SceneListInput } from './SceneListInput';
import { AvatarSelector, VoiceSelector } from './HeyGenSelectors';
import { AudioUploader } from './AudioUploader';
import { VideoListInput } from './VideoListInput';

export function NodeProperties({ nodeType, properties, onChange, output, nodeName, onNameChange, isEditable }) {
  console.log('NodeProperties render:', {
    nodeType,
    properties,
    output,
    nodeName
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleNameChange = (newName) => {
    try {
      onNameChange(newName);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChange = (name, value) => {
    onChange({ ...properties, [name]: value });
  };

  const renderProperty = (prop) => {
    if (prop.showWhen && !prop.showWhen(properties)) {
      return null;
    }

    switch (prop.type) {
      case 'text':
        return (
          <textarea
            className="w-full p-2 border rounded"
            value={properties[prop.name] || ''}
            onChange={(e) => handleChange(prop.name, e.target.value)}
            placeholder={prop.required ? '(Required)' : '(Optional)'}
          />
        );
      case 'select':
        return (
          <select
            className="w-full p-2 border rounded"
            value={properties[prop.name] || prop.default}
            onChange={(e) => handleChange(prop.name, e.target.value)}
          >
            {prop.options?.map((option) => (
              <option key={option} value={option}>
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </option>
            ))}
          </select>
        );
      case 'range':
        return (
          <div className="flex items-center gap-2">
            <input
              type="range"
              className="flex-1"
              min={prop.min}
              max={prop.max}
              step={prop.step}
              value={properties[prop.name] || prop.default}
              onChange={(e) => handleChange(prop.name, parseFloat(e.target.value))}
            />
            <span className="text-sm text-gray-600">
              {properties[prop.name] || prop.default}
            </span>
          </div>
        );
      case 'number':
        return (
          <input
            type="number"
            className="w-full p-2 border rounded"
            min={prop.min}
            max={prop.max}
            value={properties[prop.name] || prop.default}
            onChange={(e) => handleChange(prop.name, parseInt(e.target.value, 10))}
          />
        );
      case 'boolean':
        return (
          <input
            type="checkbox"
            checked={properties[prop.name] || prop.default}
            onChange={(e) => handleChange(prop.name, e.target.checked)}
          />
        );
      case 'scene-list':
        return (
          <SceneListInput
            value={properties[prop.name] || prop.default}
            onChange={(newValue) => {
              handleChange(prop.name, newValue);
            }}
          />
        );
      case 'file':
        return (
          <div>
            <input
              type="file"
              accept=".csv"
              name="csvFile"
              onChange={(e) => handleChange(prop.name, e.target.files[0])}
            />
            {properties[prop.name] && <p>Selected File: {properties[prop.name].name}</p>}
            <p className="mt-2 text-sm text-gray-500">
              CSV format example:<br />
              <code>
                date,Python,JavaScript,Java,C++,Ruby<br />
                2020-01,30,25,20,15,10<br />
                2020-02,32,27,19,16,11
              </code>
            </p>
          </div>
        );
      case 'avatar-select':
        return (
          <AvatarSelector
            value={properties[prop.name]}
            onChange={(value) => handleChange(prop.name, value)}
          />
        );
      case 'voice-select':
        return (
          <VoiceSelector
            value={properties[prop.name]}
            onChange={(value) => handleChange(prop.name, value)}
          />
        );
      case 'audio-upload':
        return (
          <AudioUploader
            value={properties[prop.name]}
            onChange={(value) => handleChange(prop.name, value)}
          />
        );
      case 'video-list':
        return (
          <VideoListInput
            value={properties[prop.name]}
            onChange={(value) => handleChange(prop.name, value)}
          />
        );
      // Update renderProperty in NodeProperties component
      case 'video-upload':
        return (
          <div className="border-1 border-dashed border-gray-300 rounded-lg p-4 text-center">
            <input
              type="file"
              accept="video/*"
              className="hidden"
              id={`video-upload-${nodeType.id}`}
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  handleChange(prop.name, file);
                }
              }}
            />
            <label
              htmlFor={`video-upload-${nodeType.id}`}
              className="cursor-pointer block"
            >
              <div className="text-gray-600 mb-2">
                {properties[prop.name] ?
                  properties[prop.name].name :
                  'Drop video here or click to upload'}
              </div>
              <div className="text-sm text-gray-500">
                MP4, MOV, or AVI • Max 2GB
              </div>
            </label>
          </div>
        );
      case 'video-input':
        return (
          <div key={prop.name} className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {prop.description || prop.name}
            </label>
            <input
              type="text"
              value={properties[prop.name] || ''}
              onChange={(e) => handleChange(prop.name, e.target.value)}
              placeholder="Enter Cloudinary video URL"
              className="w-full p-2 border rounded-md"
            />
          </div>
        );
      default:
        return null;
    }
  };

  const renderOutput = () => {
    const outputValue = output || properties.output;

    if (!outputValue) return null;

    switch (nodeType.id) {
      case 'prompt-input':
        return (
          <div className="p-4 border-t border-gray-200">
            <h4 className="font-medium text-sm mb-2">Output:</h4>
            <textarea
              key={JSON.stringify(outputValue)}
              className="w-full h-32 p-2 border rounded bg-gray-50 font-mono text-sm"
              value={JSON.stringify(outputValue, null, 2)}
              readOnly
            />
          </div>
        );
      case 'text-generation':
        return (
          <div className="p-4 border-t border-gray-200">
            <h4 className="font-medium text-sm mb-2">Output:</h4>
            <textarea
              key={JSON.stringify(outputValue)}
              className="w-full h-32 p-2 border rounded bg-gray-50 font-mono text-sm"
              value={typeof outputValue === 'object' ? JSON.stringify(outputValue, null, 2) : outputValue}
              readOnly
            />
          </div>
        );
      case 'Videos To Shorts':
        console.log("output", outputValue);

        return (
          <div className="p-4 border-t border-gray-200">
            <h4 className="font-medium text-sm mb-2">Output:</h4>
            {outputValue?.processing ? (
              <div className="text-sm text-gray-500">
                Analyzing video... ({outputValue.progress}%)
              </div>
            ) : outputValue?.clipUrls ? (
              <div className="flex-col pb-4 space-y-4">
                {outputValue.clipUrls.map((url, index) => (
                  <div key={index} className="flex-none w-full">
                    <div className="relative group">
                      <video
                        controls
                        className="w-full rounded-lg shadow-lg transform transition-transform group-hover:scale-102 aspect-video"
                        style={{ maxHeight: '200px' }}
                      >
                        <source src={url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <div className="absolute bottom-2 left-2 bg-black/50 text-white px-2 py-1 rounded text-xs">
                        Clip {index + 1}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-sm text-gray-500">
                {outputValue?.error || 'Error  to generate clips'}
              </div>
            )}
          </div>
        );
      case 'image-generation':
        return (
          <div className="p-4 border-t border-gray-200">
            <h4 className="font-medium text-sm mb-2">Output:</h4>
            {Array.isArray(outputValue) ? (
              outputValue.map((url, index) => (
                <img
                  key={`${url}-${index}`}
                  src={url}
                  alt={`Generated ${index + 1}`}
                  className="w-full rounded mb-2"
                />
              ))
            ) : outputValue.url ? (
              <img
                src={outputValue.url}
                alt="Generated"
                className="w-full rounded"
              />
            ) : (
              <textarea
                className="w-full h-32 p-2 border rounded bg-gray-50 font-mono text-sm"
                value={JSON.stringify(outputValue, null, 2)}
                readOnly
              />
            )}
          </div>
        );

      case 'text-to-video':
      case 'image-prompt-video':
        return (
          <div className="p-4 border-t border-gray-200">
            <h4 className="font-medium text-sm mb-2">Output:</h4>
            {Array.isArray(outputValue) ? (
              <textarea
                className="w-full h-32 p-2 border rounded bg-gray-50 font-mono text-sm"
                value={JSON.stringify(outputValue, null, 2)}
                readOnly
              />
            ) : (
              <div className="text-sm text-gray-500">
                Processing...
              </div>
            )}
            {outputValue?.error && (
              <div className="text-red-500 text-sm mt-2">{outputValue.error}</div>
            )}
          </div>
        );

      // Update the renderOutput function in NodeProperties component
      case 'story-image-generation':
        return (
          <div className="p-4 border-t border-gray-200">
            <h4 className="font-medium text-sm mb-2">Generated Story Images:</h4>
            {outputValue?.processing ? (
              <div className="text-sm text-gray-500">
                Generating images... ({outputValue.progress}%)
              </div>
            ) : outputValue?.urls ? (
              <div className="flex overflow-x-auto pb-4 space-x-4 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                {outputValue.urls.map((url, index) => (
                  <div
                    key={index}
                    className="flex-none w-64 h-64 relative rounded-lg overflow-hidden border border-gray-200"
                  >
                    <img
                      src={url}
                      alt={`Story image ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 text-sm">
                      Prompt {index + 1}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-sm text-gray-500">
                Enter prompts and generate to see results
              </div>
            )}
            {outputValue?.error && (
              <div className="text-red-500 text-sm mt-2">{outputValue.error}</div>
            )}
          </div>
        );

      case 'video-composition':
        return (
          <div className="p-4 border-t border-gray-200">
            <h4 className="font-medium text-sm mb-2">Output:</h4>
            {outputValue?.url ? (
              <video
                key={outputValue.url}
                controls
                className="w-full rounded"
                src={outputValue.url}
              />
            ) : Array.isArray(outputValue) ? (
              <div className="space-y-4">
                {outputValue.map((url, index) => (
                  <video
                    key={`${url}-${index}`}
                    controls
                    className="w-full rounded"
                    src={url}
                  />
                ))}
              </div>
            ) : (
              <textarea
                className="w-full h-32 p-2 border rounded bg-gray-50 font-mono text-sm"
                value={JSON.stringify(outputValue, null, 2)}
                readOnly
              />
            )}
            {outputValue?.error && (
              <div className="text-red-500 text-sm mt-2">{outputValue.error}</div>
            )}
          </div>
        );

      default:
        return (
          <div className="p-4 border-t border-gray-200">
            <h4 className="font-medium text-sm mb-2">Output:</h4>
            <textarea
              className="w-full h-32 p-2 border rounded bg-gray-50 font-mono text-sm"
              value={typeof outputValue === 'object' ? JSON.stringify(outputValue, null, 2) : outputValue}
              readOnly
            />
          </div>
        );
    }
  };

  return (
    <div className="p-4 space-y-4">
      <div>
           {/* Add Enable Output checkbox */}
           <div className="mt-2">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={properties.enableOutput || false}
              onChange={(e) => handleChange('enableOutput', e.target.checked)}
              className="form-checkbox h-4 w-4 text-blue-600"
            />
            <span className="ml-2 text-sm text-gray-700">
            Enable this option to use the output as input elsewhere.
            </span>
          </label>
        </div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Node Name
          <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          className="w-full p-2 border rounded"
          value={nodeName || ''}
          onChange={(e) => handleNameChange(e.target.value)}
          placeholder="Enter unique node name"
          disabled={!isEditable}
        />
     

        {properties.frozen && (
          <div className="mt-2 text-xs text-blue-500 flex items-center gap-1">
            <span>🧊</span> This node's output is frozen and will not be recalculated when the flow runs
          </div>
        )}
      </div>

      {nodeType.properties
        .filter(prop => {
          if (prop.showWhen) {
            return prop.showWhen(properties);
          }
          return true;
        })
        .filter(prop => prop.name === 'model' || prop.name === 'prompt')
        .map(prop => (
          <div key={prop.name}>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {prop.name}
              {prop.required && <span className="text-red-500">*</span>}
            </label>
            {prop.description && (
              <p className="text-sm text-gray-500 mb-1">{prop.description}</p>
            )}
            {renderProperty(prop)}
          </div>
        ))}

      <button
        className="text-blue-500"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? 'Hide Options' : 'Advance Options'}
      </button>

      {isOpen && (
        <div className="mt-2">
          {nodeType.properties
            .filter(prop => {
              if (prop.showWhen) {
                return prop.showWhen(properties);
              }
              return true;
            })
            .filter(prop =>
              prop.name !== 'model' &&
              prop.name !== 'prompt' &&
              (properties.model === 'Leonardo' ? prop.name !== 'loraLink' : prop.name !== 'stylePreset')
            )
            .map(prop => (
              <div key={prop.name}>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {prop.name}
                  {prop.required && <span className="text-red-500">*</span>}
                </label>
                {prop.description && (
                  <p className="text-sm text-gray-500 mb-1">{prop.description}</p>
                )}
                {renderProperty(prop)}
              </div>
            ))}
        </div>
      )}

      <div className="mt-4">
        <div className="font-medium text-sm text-gray-700 mb-2">Output:</div>
        {renderOutput()}
      </div>
    </div>
  );
}