import React, { useState } from 'react';
import { Upload } from 'lucide-react';

export function AudioUploader({ onChange, value }) {
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useState('');

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.size > 10 * 1024 * 1024) { // 10MB limit
      alert('File is too large. Please choose a file smaller than 10MB.');
      return;
    }

    if (!file.type.startsWith('audio/')) {
      alert('Please select an audio file (MP3, WAV, etc.)');
      return;
    }

    setFileName(file.name);
    setIsUploading(true);

    try {
      const formData = new FormData();
      formData.append('audio', file);
      
      onChange(file);
    } catch (error) {
      console.error('Error handling audio file:', error);
      alert('Error handling audio file. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-center w-full">
        <label className="w-full flex flex-col items-center px-4 py-6 bg-white text-gray-700 rounded-lg border-2 border-dashed border-gray-300 cursor-pointer hover:bg-gray-50">
          <Upload className="w-8 h-8 text-gray-400" />
          <span className="mt-2 text-sm">
            {isUploading ? 'Uploading...' : 'Click to upload audio file'}
          </span>
          <span className="mt-1 text-xs text-gray-500">MP3, WAV (max. 10MB)</span>
          <input
            type="file"
            className="hidden"
            accept="audio/*"
            onChange={handleFileChange}
          />
        </label>
      </div>
      {fileName && (
        <div className="text-sm text-gray-600 flex items-center gap-2">
          <span>Selected file:</span>
          <span className="font-medium">{fileName}</span>
        </div>
      )}
    </div>
  );
} 