import React, { useState } from "react";
import axios from "axios";
import { serverbaseURL } from "../constant/index";
import { motion } from "framer-motion";
import { toast } from "react-toastify";


const Support = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    category: "",
    description: "",
  });
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateEmail = (email) => {
    const regex = /^[A-Za-z0-9]+@[A-Za-z0-9.]+\.[A-Za-z]{2,}$/;
    return regex.test(email);
  };

  const validateName = (name) => {
    const regex = /^[A-Za-z]+(?:['\s][A-Za-z]+)*$/;
    return regex.test(name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(formData.email)) {
      toast.error(
        "Please enter a valid email address without any special characters."
      );
      return;
    }
    if (!validateName(formData.name)) {
      toast.error(
        "Please enter a valid name. Special characters like %, ^, and & are not allowed."
      );
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${serverbaseURL}support-tickets`,
        formData
      );
      setShowModal(true); setFormData({
        name: "",
        email: "",
        subject: "",
        category: "",
        description: "",
      });
      // // Additional confirmation message (you can add this part to show a popup or message)
      // toast.info("We have received your request. Our support team will contact you shortly.");
    } catch (error) {
      console.error("Error submitting support request:", error);
      toast.error("Failed to submit support request. Please try again.");
    }
    finally {
      setLoading(false);
    }
  };



  return (
    <>
    <div className="flex flex-col items-center my-10 space-y-10">
      {/* Support Form */}
      <motion.div
        className="flex justify-center items-center w-full"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          ease: "easeInOut",
          delay: 0.3,
        }}
      >
        <div className="bg-[#efefff] w-full max-w-md p-8 rounded-xl shadow-md border border-[#805af5]/20 hover:border-[#805af5] transition-all duration-300 ease-in-out">
          <h1 className="bg-black font-custom text-transparent bg-clip-text text-3xl text-center font-semibold mb-6">
            Support Request
          </h1>
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your Name"
              className="w-full py-2 text-[#21242d] border-2 border-[#805af5]/15 rounded-[12px] h-[50px] text-[16px] px-[15px] bg-transparent shadow-none outline-none leading-[28px] font-normal transition-all max-w-full align-baseline"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your Email"
              className="w-full py-2 text-[#21242d] border-2 border-[#805af5]/15 rounded-[12px] h-[50px] text-[16px] px-[15px] bg-transparent shadow-none outline-none leading-[28px] font-normal transition-all max-w-full align-baseline"
              required
            />
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder="Subject"
              className="w-full py-2 text-[#21242d] border-2 border-[#805af5]/15 rounded-[12px] h-[50px] text-[16px] px-[15px] bg-transparent shadow-none outline-none leading-[28px] font-normal transition-all max-w-full align-baseline"
              required
            />
            <select
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="w-full py-2 text-[#21242d] border-2 border-[#805af5]/15 rounded-[12px] h-[50px] text-[16px] px-[15px] bg-transparent shadow-none outline-none leading-[28px] font-normal transition-all max-w-full align-baseline"
              required
            >
              <option value="">Select Category</option>
              <option value="technical">Technical Issue</option>
              <option value="billing">Billing</option>
              <option value="general">General Inquiry</option>
            </select>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Describe your issue"
              className="w-full py-2 text-[#21242d] border-2 border-[#805af5]/15 rounded-[12px] text-[16px] px-[15px] bg-transparent shadow-none outline-none leading-[28px] font-normal transition-all max-w-full align-baseline"
              rows="4"
              required
            ></textarea>
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white font-medium text-lg sm:text-sm py-2 sm:py-3 px-6 sm:px-8 rounded-lg hover:shadow-lg transition duration-400 ease-in-out leading-[50px] tracking-[0.5px] mt-8 border-0 text-center inline-block"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit Request"}
            </button>
          </form>
        </div>
      </motion.div>
      {/* Success Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
            <h2 className="text-xl font-semibold mb-4">Success!</h2>
            <p>We have received your request. Our support team will contact you shortly.</p>
            <button
              onClick={() => setShowModal(false)}
              className="mt-4 px-6 py-2 bg-[#805af5] text-white rounded-lg"
            >
              Close
            </button>
          </div>
        </div>
      )}

    </div>
    
    </>
  );
};

export default Support;
