import React from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect, useRef } from "react";
import axios from 'axios';
import { serverbaseURL } from "../constant/index.jsx";
import { AuthContext } from "../provider/AuthProvider";
import { Loader2, Share2, ImageIcon, Info, Wallet, HeartIcon, VideoIcon, Paintbrush, Mic, X, Sparkles, ChevronLeft, ChevronRight } from "lucide-react";
import { toast } from "react-toastify";
const shortclip = "shortclip";

export const TemplateDetails = () => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showGeneratedVideo, setShowGeneratedVideo] = useState(false);
  const [generatedVideoUrl, setGeneratedVideoUrl] = useState(null);
  const [topic, setTopic] = useState(null);
  const [GenerationCount, setGenerationCount] = useState(0);
  const [generationError, setGenerationError] = useState(null);
  // const [activeTab, setActiveTab] = useState("/templates");
  const { user, userCredits, updateCredits } = useContext(AuthContext);
  const userEmail = user?.email;
  const userUID = user?.uid;
  const [showPreGenModal, setShowPreGenModal] = useState(false);
  const [selectedNarrator, setSelectedNarrator] = useState("pNInz6obpgDQGcFmaJgB");
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [showMyGenerations, setShowMyGenerations] = useState(false);
  const [marketplaceTemplates, setMarketplaceTemplates] = useState([]);
  const [showGeneratingModal, setShowGeneratingModal] = useState(false);
  const [templateScenes, setTemplateScenes] = useState([]);
  const [loadingScenes, setLoadingScenes] = useState(false);
  const [editableScenes, setEditableScenes] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [templateVideo, setTemplateVideo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [generatedClips, setGeneratedClips] = useState([]);
  const [currentClipIndex, setCurrentClipIndex] = useState(0);
  const [showClipsModal, setShowClipsModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [clipPrompt, setClipPrompt] = useState("");
  const [generatedImages, setGeneratedImages] = useState([]);
  const [showGeneratedImages, setShowGeneratedImages] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentNodeOutput, setCurrentNodeOutput] = useState(null);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [approvalMessage, setApprovalMessage] = useState('');
  const approvalResolveRef = useRef(null);



  // Approval Modal Component
  const ApprovalModal = () => {
    const [editingIndex, setEditingIndex] = useState(null);
    const [editedPrompt, setEditedPrompt] = useState("");
    const [regenerating, setRegenerating] = useState(false);
    const [updatedImageUrls, setUpdatedImageUrls] = useState(null);
    
    const handleEditPrompt = (index, prompt) => {
      setEditingIndex(index);
      setEditedPrompt(prompt);
    };
    
    const handleRegenerateImage = async (index) => {
      if (!editedPrompt.trim()) return;
      
      setRegenerating(true);
      console.log(`Starting regeneration for image at index ${index} with prompt: "${editedPrompt}"`);
      console.log("Current node output before regeneration:", currentNodeOutput);
      
      try {
        // Call the API to generate a new image
        console.log("Calling generate-single-image API...");
        const response = await axios.post(`${serverbaseURL}generate-single-image`, {
          prompt: editedPrompt
        });
        
        if (response.data.imageUrl) {
          console.log("Received new image URL:", response.data.imageUrl);
          
          // Create a deep copy of the current output to avoid mutation issues
          let updatedOutput = JSON.parse(JSON.stringify(currentNodeOutput));
          console.log("Original structure (deep copy):", updatedOutput);
          
          // Extract the current imageUrls array that will be used for video composition
          let imageUrlsArray = [];
          
          // Check if we're dealing with an array of objects with url properties
          if (Array.isArray(updatedOutput) && updatedOutput.length > 0 && 'url' in updatedOutput[0]) {
            console.log("Extracting image URLs from array of objects with url property");
            imageUrlsArray = updatedOutput.map(item => item.url);
          } 
          // If it's a single object with imageUrls array
          else if (updatedOutput && updatedOutput.imageUrls && Array.isArray(updatedOutput.imageUrls)) {
            console.log("Using existing imageUrls array");
            imageUrlsArray = [...updatedOutput.imageUrls];
          }
          
          console.log("Current imageUrls array:", imageUrlsArray);
          
          // Update the image URL in the appropriate structure
          if (Array.isArray(updatedOutput)) {
            console.log("Detected array structure");
            
            // Update the object at the specified index
            if (updatedOutput[index]) {
              console.log(`Updating object at index ${index}`);
              
              // If the item has a url property
              if ('url' in updatedOutput[index]) {
                console.log("Item has 'url' property, updating it");
                updatedOutput[index].url = response.data.imageUrl;
                
                // Also update the imageUrlsArray
                imageUrlsArray[index] = response.data.imageUrl;
              }
              
              // If the item has imageUrls property
              if (updatedOutput[index].imageUrls) {
                console.log("Item has 'imageUrls' property, updating it");
                updatedOutput[index].imageUrls = [response.data.imageUrl];
              }
              
              // Update the prompt
              if ('prompt' in updatedOutput[index]) {
                console.log("Updating prompt");
                updatedOutput[index].prompt = editedPrompt;
              }
            } else {
              console.warn(`Index ${index} is out of bounds for the array`);
            }
          } else if (typeof updatedOutput === 'object') {
            console.log("Detected object structure");
            
            // If it's a single object with imageUrls
            if (updatedOutput.imageUrls && Array.isArray(updatedOutput.imageUrls)) {
              console.log(`Updating imageUrls array at index ${index}`);
              updatedOutput.imageUrls[index] = response.data.imageUrl;
              
              // Also update the imageUrlsArray
              imageUrlsArray[index] = response.data.imageUrl;
            }
            
            // If it has a url property
            if ('url' in updatedOutput) {
              console.log("Updating url property");
              updatedOutput.url = response.data.imageUrl;
            }
            
            // Update the prompt
            if ('prompt' in updatedOutput) {
              console.log("Updating prompt");
              updatedOutput.prompt = editedPrompt;
            }
          }
          console.log("Updated structure:", updatedOutput);
          console.log("Updated imageUrls array:", imageUrlsArray);
          window.updatedImageUrls = [{ imageUrls: imageUrlsArray }];
          console.log("Stored updated image URLs in correct format:", window.updatedImageUrls);
          // Update the currentNodeOutput state
          console.log("Setting currentNodeOutput to updated structure");
          setCurrentNodeOutput(updatedOutput);
          if (showGeneratedImages) {
            console.log("Updating generatedImages state");
            setGeneratedImages(Array.isArray(updatedOutput) ? updatedOutput : [updatedOutput]);
          }
          console.log("Image regeneration complete");
          toast.success("Image regenerated successfully!");
        } else {
          console.error("API response did not contain an image URL:", response.data);
          throw new Error("Failed to regenerate image: No image URL in response");
        }
      } catch (error) {
        console.error("Error regenerating image:", error);
        toast.error(`Failed to regenerate image: ${error.message}`);
      } finally {
        setRegenerating(false);
        setEditingIndex(null);
      }
    };
    
    const cancelEditing = () => {
      setEditingIndex(null);
      setEditedPrompt("");
    };
    
    const handleApproveAndContinue = () => {
      if (window.updatedImageUrls) {
        console.log("Using updated image URLs for video composition:", window.updatedImageUrls);
      }
      
      approvalResolveRef.current(true);
      setShowApprovalModal(false);
    };

    return (
      <div className="fixed inset-0 bg-black/60 flex items-center justify-center p-4 z-50 backdrop-blur-sm">
        <div className="bg-white rounded-xl p-6 max-w-2xl w-full shadow-2xl border border-gray-100">
          <div className="flex justify-between items-start mb-6">
            <div className="flex items-center gap-2">
              <Sparkles className="w-5 h-5 text-violet-600" />
              <h3 className="text-2xl font-bold text-gray-900">Content Review</h3>
            </div>
            <button
              onClick={() => {
                approvalResolveRef.current(false);
                setShowApprovalModal(false);
              }}
              className="text-gray-400 hover:text-gray-600 transition-colors rounded-full hover:bg-gray-100 p-1"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          <div className="mb-6 max-h-[60vh] overflow-y-auto pr-3 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
            {Array.isArray(currentNodeOutput) ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {currentNodeOutput.map((item, index) => (
                  <div key={index} className="group relative bg-gray-50 rounded-lg p-4 transition-all hover:bg-gray-100 border border-gray-200">
                    {item.url ? (
                      <>
                        <div className="aspect-square bg-gray-200 rounded-lg overflow-hidden mb-3 shadow-sm">
                          <img
                            src={item.url}
                            alt={`Generated content ${index + 1}`}
                            className="w-full h-full object-cover transition-transform group-hover:scale-105"
                          />
                        </div>
                        <div className="absolute top-2 right-2 bg-white/90 px-2 py-1 rounded-full text-xs font-medium shadow-sm">
                          {index + 1}/{currentNodeOutput.length}
                        </div>
                      </>
                    ) : null}
                    {editingIndex === index ? (
                      <div className="bg-white p-3 rounded-lg border border-violet-200 shadow-sm">
                        <div className="flex justify-between items-center mb-2">
                          <p className="text-sm text-gray-700 font-medium flex items-center gap-1">
                            <Paintbrush className="w-3.5 h-3.5 text-violet-600" />
                            Edit Prompt
                          </p>
                          {regenerating ? (
                            <Loader2 className="w-4 h-4 animate-spin text-violet-600" />
                          ) : null}
                        </div>
                        <textarea
                          value={editedPrompt}
                          onChange={(e) => setEditedPrompt(e.target.value)}
                          className="w-full p-2 text-sm border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-transparent"
                          rows="3"
                        />
                        <div className="flex justify-end gap-2 mt-2">
                          <button
                            onClick={cancelEditing}
                            className="px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                            disabled={regenerating}
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => handleRegenerateImage(index)}
                            className="px-3 py-1.5 text-sm bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white rounded-lg hover:opacity-90 flex items-center gap-1.5 shadow-sm transition-all"
                            disabled={regenerating}
                          >
                            {regenerating ? (
                              <>
                                <Loader2 className="w-3.5 h-3.5 animate-spin" />
                                Regenerating...
                              </>
                            ) : (
                              <>
                                <Sparkles className="w-3.5 h-3.5" />
                                Regenerate
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    ) : item.prompt ? (
                      <div className="bg-white p-3 rounded-lg border border-gray-200 shadow-sm">
                        <div className="flex justify-between items-center">
                          <p className="text-sm text-gray-700 font-medium flex items-center gap-1.5">
                            <Info className="w-3.5 h-3.5 text-violet-600" />
                            Prompt
                          </p>
                          <button
                            onClick={() => handleEditPrompt(index, item.prompt)}
                            className="text-xs text-violet-600 hover:text-violet-800 font-medium flex items-center gap-1"
                          >
                            <Paintbrush className="w-3 h-3" />
                            Edit
                          </button>
                        </div>
                        <p className="text-sm text-gray-600 mt-1.5">{item.prompt}</p>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : typeof currentNodeOutput === 'object' ? (
              <div className="space-y-4">
                {currentNodeOutput.url && (
                  <div className="relative bg-gray-50 rounded-lg p-4 border border-gray-200">
                    <div className="aspect-video bg-gray-200 rounded-lg overflow-hidden shadow-sm">
                      <img
                        src={currentNodeOutput.url}
                        alt="Generated content"
                        className="w-full h-full object-cover"
                      />
                    </div>
                    {editingIndex === 0 ? (
                      <div className="mt-4 bg-white p-3 rounded-lg border border-violet-200 shadow-sm">
                        <div className="flex justify-between items-center mb-2">
                          <p className="text-sm text-gray-700 font-medium flex items-center gap-1.5">
                            <Paintbrush className="w-3.5 h-3.5 text-violet-600" />
                            Edit Prompt
                          </p>
                          {regenerating ? (
                            <Loader2 className="w-4 h-4 animate-spin text-violet-600" />
                          ) : null}
                        </div>
                        <textarea
                          value={editedPrompt}
                          onChange={(e) => setEditedPrompt(e.target.value)}
                          className="w-full p-2 text-sm border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-transparent"
                          rows="3"
                        />
                        <div className="flex justify-end gap-2 mt-2">
                          <button
                            onClick={cancelEditing}
                            className="px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                            disabled={regenerating}
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => handleRegenerateImage(0)}
                            className="px-3 py-1.5 text-sm bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white rounded-lg hover:opacity-90 flex items-center gap-1.5 shadow-sm transition-all"
                            disabled={regenerating}
                          >
                            {regenerating ? (
                              <>
                                <Loader2 className="w-3.5 h-3.5 animate-spin" />
                                Regenerating...
                              </>
                            ) : (
                              <>
                                <Sparkles className="w-3.5 h-3.5" />
                                Regenerate
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    ) : currentNodeOutput.prompt ? (
                      <div className="mt-4 bg-white p-3 rounded-lg border border-gray-200 shadow-sm">
                        <div className="flex justify-between items-center">
                          <p className="text-sm text-gray-700 font-medium flex items-center gap-1.5">
                            <Info className="w-3.5 h-3.5 text-violet-600" />
                            Prompt
                          </p>
                          <button
                            onClick={() => handleEditPrompt(0, currentNodeOutput.prompt)}
                            className="text-xs text-violet-600 hover:text-violet-800 font-medium flex items-center gap-1"
                          >
                            <Paintbrush className="w-3 h-3" />
                            Edit
                          </button>
                        </div>
                        <p className="text-sm text-gray-600 mt-1.5">{currentNodeOutput.prompt}</p>
                      </div>
                    ) : null}
                  </div>
                )}
                {currentNodeOutput.text && (
                  <div className="bg-gray-50 rounded-lg p-4 border border-gray-200 shadow-sm">
                    <p className="text-sm text-gray-700 font-medium mb-2 flex items-center gap-1.5">
                      <Mic className="w-3.5 h-3.5 text-violet-600" />
                      Generated Text
                    </p>
                    <div className="bg-white p-3 rounded-lg border border-gray-200">
                      <p className="text-gray-600 whitespace-pre-wrap text-sm">{currentNodeOutput.text}</p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="bg-gray-50 rounded-lg p-4 border border-gray-200 shadow-sm">
                <p className="text-gray-600">{currentNodeOutput}</p>
              </div>
            )}
          </div>

          <div className="flex justify-end gap-3 border-t pt-4">
            <button
              onClick={() => {
                approvalResolveRef.current(false);
                setShowApprovalModal(false);
              }}
              className="px-5 py-2.5 text-gray-700 hover:bg-gray-100 rounded-lg font-medium transition-colors border border-gray-200"
            >
              Cancel
            </button>
            <button
              onClick={handleApproveAndContinue}
              className="px-5 py-2.5 bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white rounded-lg font-medium hover:opacity-90 transition-all shadow-sm flex items-center gap-2"
            >
              <Sparkles className="w-4 h-4" />
              Approve & Continue
            </button>
          </div>
        </div>
      </div>
    );
  };

  //useEffect to monitor state changes for generated images
  useEffect(() => {
    console.log('Generated Images State Updated:', {
      showGeneratedImages,
      imagesCount: generatedImages.length
    });
  }, [showGeneratedImages, generatedImages]);

  useEffect(() => {
    const fetchCount = async () => {
      try {
        const response = await axios.get(`${serverbaseURL}api/template-generation-count/${selectedTemplate.id}`);
        setGenerationCount(response.data.count);
      } catch (error) {
        console.error('Error fetching generation count:', error);
      }
    };
    fetchCount();
  }, [selectedTemplate?.id]);

  const fetchTemplateScenes = async () => {
    if (!selectedTemplate?.id) return;
    console.log(selectedTemplate?.id)
    setLoadingScenes(true);
    try {
      const response = await axios.get(`${serverbaseURL}api/template-scenes/${selectedTemplate.id}`);
      if (response.data.success) {
        setTemplateScenes(response.data);
        if (response.data.type === "scenes") {
          setEditableScenes(response.data.scenes);
        } else if (response.data.type === "text") {
          setEditableScenes([{ prompt: response.data.prompt }]);
        }
      } else {
        toast.error("Failed to load template content");
      }
    } catch (error) {
      console.error("Error fetching template content:", error);
      toast.error("Failed to load template content");
      setTemplateScenes({ type: "none" });
      setEditableScenes([]);
    } finally {
      setLoadingScenes(false);
    }
  };

  const handleSchedulePost = (videoUrl, topic) => {
    navigate('/schedulePost', { state: { videoUrl, topic } });
  };
  useEffect(() => {
    fetchTemplateScenes();
  }, [selectedTemplate?.id]);
  useEffect(() => {
    const fetchUserCredits = async () => {
      if (user?.uid) {
        try {
          const response = await axios.post(`${serverbaseURL}get_user_details`, {
            uid: user.uid
          });
          updateCredits(response.data.credits);
        } catch (error) {
          console.error("Failed to fetch user credits:", error);
        }
      }
    };
    fetchUserCredits();
  }, [user]);


  const fetchTemplateVideos = async (templateId) => {
    console.log("Fetching videos for template ID:", templateId);
    try {
      const response = await axios.get(`${serverbaseURL}get_template_videos/${templateId}`);
      console.log("API Response:", response.data);
      if (response.data.success) {
        const videoData = response.data.data;
        if (Array.isArray(videoData)) {
          setTemplateVideo(videoData);
        } else if (videoData && typeof videoData === "object") {
          setTemplateVideo([videoData]); // Convert single object to array
        } else {
          setTemplateVideo([]);
        }
        console.log("Stored in State:", videoData);
      } else {
        toast.error("Failed to fetch template video examples");
      }
    } catch (error) {
      console.error("Error fetching template videos:", error);
      toast.error("Failed to load template examples");
    }
  };

  useEffect(() => {
    console.log("Updated templateVideo state:", templateVideo);
  }, [templateVideo]);

  // Add this useEffect to trigger the fetch when template changes
  useEffect(() => {
    if (selectedTemplate?.id) {
      fetchTemplateVideos(selectedTemplate.id);
    }
  }, [selectedTemplate?.id]);

  const { state } = useLocation();
  const navigate = useNavigate();
  // Redirect if no template data
  useEffect(() => {
    if (!state?.template) {
      navigate("/templates");
    } else {
      setSelectedTemplate(state.template);

    }
  }, [state, navigate]);
  if (!selectedTemplate) return null; // Ensure UI doesn't break before state updates
  console.log("Selected Template:", selectedTemplate);


  const handleFinalGenerate = async () => {
    setShowPreGenModal(false);
    setIsGenerating(true);
    toast.success(`Video Generation added to render queue successfully!`);
    setGenerationError(null);

    try {
      console.log('Selected template:', selectedTemplate);
      console.log('Selected template flowData:', selectedTemplate.flowData);
      const originalCredits = userCredits;
      const newCredits = userCredits - selectedTemplate.credits;
      updateCredits(newCredits);
      const deductionResponse = await axios.post(`${serverbaseURL}deduct_credits`, {
        uid: user.uid,
        credits: selectedTemplate.credits
      });
      await axios.post(`${serverbaseURL}add-generation-status`, {
        uid: user.uid,
        templateId: selectedTemplate.id,
        templateName: selectedTemplate.title,
      });
      const template = selectedTemplate;
      // For other templates, use the flow execution approach
      if (template.flowData && template.flowData.data) {
        // Use edited scenes if available
        const scenesToUse = isEditing ? editableScenes :
          (templateScenes.type === 'scenes' ? templateScenes.scenes :
            [{ prompt: templateScenes.prompt }]);

        // Create a modified template with updated scenes
        const modifiedTemplate = {
          ...template,
          flowData: {
            ...template.flowData,
            data: {
              ...template.flowData?.data,
              nodes: template.flowData?.data.nodes.map(node => {
                if (node.data?.type === 'prompt-input') {
                  const updatedScenes = editableScenes.map((scene, index) => {
                    const originalScene = node.data.properties.scenes[index];
                    return {
                      id: originalScene?.id || index + 1,
                      prompt: scene.prompt
                    };
                  });

                  return {
                    ...node,
                    data: {
                      ...node.data,
                      properties: {
                        ...node.data.properties,
                        scenes: updatedScenes
                      }
                    }
                  };
                } else if (node.data?.type === 'text-generation') {
                  return {
                    ...node,
                    data: {
                      ...node.data,
                      properties: {
                        ...node.data.properties,
                        prompt: editableScenes[0]?.prompt || ''
                      }
                    }
                  };
                }
                return node;
              })
            }
          }
        };

        // Set up a local store for node outputs
        const nodeOutputs = {};
        const setNodeOutput = (nodeId, output) => {
          nodeOutputs[nodeId] = output;
        };

        const getNodeOutput = (nodeName) => {
          const node = modifiedTemplate.flowData.data.nodes.find(n => n.data.name === nodeName);
          return node ? nodeOutputs[node.id] : null;
        };

        const updateNodeProperties = (nodeId, properties) => {
          modifiedTemplate.flowData.data.nodes = modifiedTemplate.flowData.data.nodes.map(node => {
            if (node.id === nodeId) {
              return {
                ...node,
                data: {
                  ...node.data,
                  properties
                }
              };
            }
            return node;
          });
        };

        // Process a single node
        const processNode = async (node) => {
          try {
            console.log('Processing node:', {
              id: node.id,
              type: node.data.type,
              name: node.data.name,
              properties: node.data.properties
            });

            const inputEdges = modifiedTemplate.flowData.data.edges.filter(edge => edge.target === node.id);
            const inputNodes = inputEdges.map(edge =>
              modifiedTemplate.flowData.data.nodes.find(n => n.id === edge.source)
            );
            const inputResults = await Promise.all(
              inputNodes.map(async (inputNode) => {
                if (!getNodeOutput(inputNode.data.name)) {
                  await processNode(inputNode);
                }
                return getNodeOutput(inputNode.data.name);
              })
            );

            let response;
            switch (node.data.type) {
              case 'prompt-input':
                const scenes = node.data.properties.scenes || [];
                const output = scenes.map((scene, index) => ({
                  prompt: scene.prompt,
                }));
                setNodeOutput(node.id, output);
                response = { data: output };
                break;

              case 'video-composition':
                // Process imageSource placeholder
                let processedImageSource = node.data.properties.imageSource;
                if (processedImageSource) {
                  const placeholderRegex = /{([^}]+)\.output}/g;
                  processedImageSource = processedImageSource.replace(placeholderRegex, (match, nodeName) => {
                    const sourceNode = modifiedTemplate.flowData.data.nodes.find(n => n.data.name === nodeName);
                    if (!sourceNode) {
                      console.warn(`Node "${nodeName}" not found`);
                      return match;
                    }
                    const output = getNodeOutput(nodeName);
                    return JSON.stringify(output) || match;
                  });
                }

                // Process audioSource placeholder
                let processedAudioSource = node.data.properties.audioSource;
                if (processedAudioSource) {
                  const placeholderRegex = /{([^}]+)\.output}/g;
                  processedAudioSource = processedAudioSource.replace(placeholderRegex, (match, nodeName) => {
                    const sourceNode = modifiedTemplate.flowData.data.nodes.find(n => n.data.name === nodeName);
                    if (!sourceNode) {
                      console.warn(`Node "${nodeName}" not found`);
                      return match;
                    }
                    const output = getNodeOutput(nodeName);
                    return JSON.stringify(output) || match;
                  });
                }

                const imageOutput = processedImageSource
                  ? JSON.parse(processedImageSource)
                  : getNodeOutput(inputNodes.find(n => n.data.type === 'image-generation')?.data.name);

                const audioOutput = processedAudioSource
                  ? JSON.parse(processedAudioSource)
                  : getNodeOutput(inputNodes.find(n => n.data.type === 'audio-generation')?.data.name);

                if (!imageOutput || !audioOutput) {
                  throw new Error('Video composition requires both image and audio inputs');
                }

                // Check if we have updated image URLs from regeneration
                let imageSources;
                if (window.updatedImageUrls) {
                  console.log("Using updated image URLs for video composition:", window.updatedImageUrls);
                  imageSources = window.updatedImageUrls;
                  // Clear the global variable after using it
                  window.updatedImageUrls = null;
                } else {
                  // Use the original image output
                  imageSources = Array.isArray(imageOutput) && imageOutput[0]?.imageUrls
                    ? imageOutput
                    : (Array.isArray(imageOutput) ? [{ imageUrls: imageOutput.map(item => item.url) }] : [imageOutput]);
                }

                const videoPayload = {
                  imageSources: imageSources,
                  audioSources: {
                    audioUrl: audioOutput.audioUrl,
                    audioDuration: audioOutput.duration || 30.00,
                    type: audioOutput.type,
                  },
                  height: node.data.properties.height || 720,
                  width: node.data.properties.width || 1280,
                  topic: node.data.properties.topic || 'flow'
                };

                console.log('Sending video composition request:', videoPayload);

                response = await axios.post(`${serverbaseURL}create-video-with-subtitles`, videoPayload);
                if (response.data?.videoUrl) {
                  response.data = {
                    url: response.data.videoUrl,
                    type: 'video'
                  };
                }
                break;

              case 'text-generation':
                let processedPrompt = node.data.properties.prompt;
                if (processedPrompt) {
                  const placeholderRegex = /{([^}]+)\.output}/g;
                  processedPrompt = processedPrompt.replace(placeholderRegex, (match, nodeName) => {
                    const sourceNode = modifiedTemplate.flowData.data.nodes.find(n => n.data.name === nodeName);
                    if (!sourceNode) {
                      console.warn(`Node "${nodeName}" not found`);
                      return match;
                    }

                    const output = getNodeOutput(nodeName);
                    console.log('Using output from node:', {
                      nodeName,
                      output
                    });
                    return output || match;
                  });
                }

                // Add model-specific routing
                const endpoint = node.data.properties.model === 'Deepseek R1'
                  ? `${serverbaseURL}chat-completion-deepseek`
                  : `${serverbaseURL}chat-completion`;

                response = await axios.post(endpoint, {
                  ...node.data.properties,
                  prompt: processedPrompt,
                  nodeId: node.id,
                  nodeType: node.data.type
                });

                // After getting response
                setCurrentNodeOutput(response.data);
                setApprovalMessage('Review generated text');
                setShowApprovalModal(true);

                const textApproved = await new Promise((resolve) => {
                  approvalResolveRef.current = resolve;
                });

                if (!textApproved) {
                  throw new Error('User cancelled text generation');
                }
                break;

              case 'image-generation':
                try {
                  let processedPrompt = node.data.properties.prompt;
                  if (processedPrompt) {
                    const placeholderRegex = /{([^}]+)\.output}/g;
                    processedPrompt = processedPrompt.replace(placeholderRegex, (match, nodeName) => {
                      const sourceNode = modifiedTemplate.flowData.data.nodes.find(n => n.data.name === nodeName);
                      if (!sourceNode) {
                        console.warn(`Node "${nodeName}" not found`);
                        return match;
                      }

                      const output = getNodeOutput(nodeName);
                      // Handle the case where output is already an object
                      if (typeof output === 'object') {
                        return JSON.stringify(output);
                      }
                      return output || match;
                    });
                  }

                  let imagePrompts;
                  const promptText = processedPrompt.trim();

                  // Handle different input formats
                  if (typeof promptText === 'string') {
                    if (promptText.startsWith('[')) {
                      try {
                        imagePrompts = JSON.parse(promptText);
                      } catch (parseError) {
                        console.error('JSON Parse Error:', parseError);
                        console.log('Invalid JSON string:', promptText);
                        throw new Error('Failed to parse prompt JSON: ' + parseError.message);
                      }
                    } else {
                      // Include the model and loraLink in the prompt object
                      imagePrompts = [{
                        prompt: promptText,
                        model: node.data.properties.model, // Add the selected model here
                        loraLink: node.data.properties.loraLink // Add the loraLink here
                      }];
                    }
                  } else if (Array.isArray(promptText)) {
                    imagePrompts = promptText.map(item => ({
                      ...item,
                      model: node.data.properties.model, // Add the selected model to each prompt
                      loraLink: node.data.properties.loraLink // Add the loraLink to each prompt
                    }));
                  } else if (typeof promptText === 'object') {
                    imagePrompts = [{
                      ...promptText,
                      model: node.data.properties.model, // Add the selected model
                      loraLink: node.data.properties.loraLink // Add the loraLink
                    }];
                  } else {
                    throw new Error('Invalid prompt format');
                  }

                  // Ensure all prompts have the correct structure
                  imagePrompts = imagePrompts.map(item => {
                    if (typeof item === 'string') {
                      return {
                        prompt: item,
                        model: node.data.properties.model,
                        loraLink: node.data.properties.loraLink
                      };
                    }
                    return {
                      ...item,
                      model: node.data.properties.model,
                      loraLink: node.data.properties.loraLink
                    };
                  });

                  console.log('Sending to Leonardo API:', imagePrompts);
                  response = await axios.post(`${serverbaseURL}generate-images-leonardo`, imagePrompts);

                  console.log('Leonardo API Response:', response.data);
                  if (response.data) {
                    console.log('Processing image generation response...');

                    // Process the response data first
                    let imagesWithPrompts = [];
                    if (response.data.imageUrls && Array.isArray(response.data.imageUrls)) {
                      imagesWithPrompts = response.data.imageUrls.map((url, index) => ({
                        url: url,
                        prompt: imagePrompts[index % imagePrompts.length]?.prompt || "Generated image"
                      }));
                    } else if (Array.isArray(response.data)) {
                      imagesWithPrompts = response.data.map((imageData, index) => ({
                        url: imageData.url || imageData.imageUrls?.[0],
                        prompt: imagePrompts[index]?.prompt || "Generated image"
                      }));
                    } else {
                      console.warn('Unexpected response format:', response.data);
                      throw new Error('Unexpected API response format');
                    }

                    // Set the processed output for approval modal
                    setCurrentNodeOutput(imagesWithPrompts);
                    setApprovalMessage('Review generated images');
                    setShowApprovalModal(true);

                    const imagesApproved = await new Promise((resolve) => {
                      approvalResolveRef.current = resolve;
                    });

                    if (!imagesApproved) {
                      throw new Error('User cancelled image generation');
                    }

                    // Update generated images state with processed data
                    setGeneratedImages(imagesWithPrompts);
                    setShowGeneratedImages(true);
                    console.log('Image generation approved, showing results');
                  }

                  // Update the generated images state with the response data and prompts
                  {
                    console.warn('No valid image data received from API');
                  }
                } catch (error) {
                  console.error('Error processing image prompts:', error);
                  throw new Error(`Failed to process image prompts: ${error.message}`);
                }
                break;

              case 'audio-generation':
                let processedProperties = { ...node.data.properties };
                if (processedProperties.text) {
                  const placeholderRegex = /{([^}]+)\.output}/g;
                  processedProperties.text = processedProperties.text.replace(placeholderRegex, (match, nodeName) => {
                    const sourceNode = modifiedTemplate.flowData.data.nodes.find(n => n.data.name === nodeName);
                    if (!sourceNode) {
                      console.warn(`Node "${nodeName}" not found`);
                      return match;
                    }

                    const output = getNodeOutput(nodeName);
                    return output || match;
                  });
                }

                response = await axios.post(`${serverbaseURL}audio-generation`, {
                  ...processedProperties,
                  nodeId: node.id,
                  nodeType: node.data.type
                });
                break;

              case 'music-generation':
                try {
                  console.log('Starting music generation process...');
                  let processedPrompt = node.data.properties.prompt;
                  console.log('Original prompt:', processedPrompt);

                  if (processedPrompt) {
                    const placeholderRegex = /{([^}]+)\.output}/g;
                    processedPrompt = processedPrompt.replace(placeholderRegex, (match, nodeName) => {
                      console.log(`Replacing placeholder for node: ${nodeName}`);
                      const sourceNode = modifiedTemplate.flowData.data.nodes.find(n => n.data.name === nodeName);
                      if (!sourceNode) {
                        console.warn(`Node "${nodeName}" not found`);
                        return match;
                      }
                      const output = getNodeOutput(nodeName);
                      console.log(`Retrieved output for node ${nodeName}:`, output);
                      return output || match;
                    });
                  }

                  console.log('Processed prompt:', processedPrompt);

                  const requestBody = {
                    text: processedPrompt.trim()
                  };
                  console.log('Sending request with body:', requestBody);

                  response = await axios.post(`${serverbaseURL}generate-music`, requestBody);
                  console.log('Response :', response);

                  console.log('Response received:', response.data);

                  // Convert response to array format for consistency
                  response.data = [response.data];
                  console.log('Formatted response data:', response.data);

                  updateNodeProperties(node.id, {
                    ...node.data.properties,
                    output: response.data
                  });
                  console.log('Updated node properties:', node.data.properties);
                } catch (error) {
                  console.error('Music Generation Error:', error);
                  throw error;
                }
                break;

              case 'video-generation':
                try {
                  const mode = node.data.properties.mode;
                  let payload;

                  if (mode === 'Text to Video') {
                    const textPrompts = node.data.properties.textPrompts.split('\n')
                      .filter(prompt => prompt.trim())
                      .map(prompt => ({ prompt: prompt.trim() }));

                    payload = {
                      mode: 'text',
                      prompts: textPrompts,
                      ratio: node.data.properties.ratio,
                      length: node.data.properties.length
                    };
                  } else {
                    const imagePrompts = node.data.properties.imagePrompts.split('\n')
                      .filter(prompt => prompt.trim())
                      .map(prompt => ({ prompt: prompt.trim() }));

                    const imageUrls = node.data.properties.imageUrls.split('\n')
                      .filter(url => url.trim());

                    if (imagePrompts.length !== imageUrls.length) {
                      throw new Error('Number of prompts must match number of images');
                    }

                    payload = {
                      mode: 'image',
                      prompts: imagePrompts,
                      images: imageUrls,
                      ratio: node.data.properties.ratio,
                      length: node.data.properties.length
                    };
                  }

                  response = await axios.post(`${serverbaseURL}api/generate-kling-video`, payload);
                  break;
                } catch (error) {
                  console.error('Video Generation Error:', error);
                  throw error;
                }

              case 'text-to-video':
                let processedPrompts = node.data.properties.prompts;
                if (processedPrompts) {
                  const placeholderRegex = /{([^}]+)\.output}/g;
                  processedPrompts = processedPrompts.replace(placeholderRegex, (match, nodeName) => {
                    const sourceNode = modifiedTemplate.flowData.data.nodes.find(n => n.data.name === nodeName);
                    if (!sourceNode) {
                      console.warn(`Node "${nodeName}" not found`);
                      return match;
                    }
                    const output = getNodeOutput(nodeName);
                    if (Array.isArray(output)) {
                      return output.map(item => item.prompt).join('\n');
                    }
                    return output || match;
                  });
                }

                const textPrompts = processedPrompts.split('\n')
                  .filter(prompt => prompt.trim())
                  .map(prompt => ({ prompt: prompt.trim() }));

                response = await axios.post(`${serverbaseURL}api/generate-prompt-videos`, {
                  prompts: textPrompts
                });

                // Convert single URL to array format for video composition node
                response.data = [response.data];

                updateNodeProperties(node.id, {
                  ...node.data.properties,
                  output: response.data
                });
                setNodeOutput(node.id, response.data);
                break;

              case 'image-prompt-video':
                let processedImagePrompts = node.data.properties.imagePrompts;
                let processedVideoPrompts = node.data.properties.videoPrompts;

                if (processedImagePrompts) {
                  const placeholderRegex = /{([^}]+)\.output}/g;
                  processedImagePrompts = processedImagePrompts.replace(placeholderRegex, (match, nodeName) => {
                    const sourceNode = modifiedTemplate.flowData.data.nodes.find(n => n.data.name === nodeName);
                    if (!sourceNode) return match;
                    const output = getNodeOutput(nodeName);
                    if (Array.isArray(output)) {
                      return JSON.stringify(output, null, 2);
                    }
                    return output || match;
                  });
                }

                if (processedVideoPrompts) {
                  const placeholderRegex = /{([^}]+)\.output}/g;
                  processedVideoPrompts = processedVideoPrompts.replace(placeholderRegex, (match, nodeName) => {
                    const sourceNode = modifiedTemplate.flowData.data.nodes.find(n => n.data.name === nodeName);
                    if (!sourceNode) return match;
                    const output = getNodeOutput(nodeName);
                    if (Array.isArray(output)) {
                      return output.map(item => item.prompt).join('\n');
                    }
                    return output || match;
                  });
                }

                const imgPrompts = JSON.parse(processedImagePrompts);
                const videoPrompts = processedVideoPrompts.split('\n')
                  .filter(prompt => prompt.trim())
                  .map(prompt => ({ prompt: prompt.trim() }));

                if (imgPrompts.length !== videoPrompts.length) {
                  throw new Error('Number of image prompts must match number of video prompts');
                }

                response = await axios.post(`${serverbaseURL}api/generate-kling-video`, {
                  imgPrompts,
                  videoPrompts,
                  topic: node.data.properties.topic || 'flow',
                  height: node.data.properties.height || 720,
                  width: node.data.properties.width || 1280
                });

                // Convert single URL to array format for video composition node
                response.data = [response.data];

                updateNodeProperties(node.id, {
                  ...node.data.properties,
                  output: response.data
                });
                setNodeOutput(node.id, response.data);
                break;

              case 'heygen-video':
                try {
                  let audioAssetId = null;

                  if (node.data.properties.inputType === 'audio' && node.data.properties.audioFile) {
                    const formData = new FormData();
                    formData.append('audio', node.data.properties.audioFile);

                    const uploadResponse = await axios.post(
                      `${serverbaseURL}api/upload-audio`,
                      formData,
                      {
                        headers: { 'Content-Type': 'multipart/form-data' }
                      }
                    );

                    if (uploadResponse.data.success) {
                      audioAssetId = uploadResponse.data.audio_asset_id;
                    } else {
                      throw new Error('Audio upload failed');
                    }
                  }

                  const videoPayload = {
                    resolution: '360p',
                    dimension: {
                      width: 360,
                      height: 640
                    },
                    video_inputs: [
                      {
                        character: {
                          type: 'avatar',
                          avatar_id: node.data.properties.selectedAvatar,
                          avatar_style: 'normal'
                        },
                        voice: node.data.properties.inputType === 'text'
                          ? {
                            type: 'text',
                            input_text: node.data.properties.text,
                            voice_id: node.data.properties.selectedVoice,
                          }
                          : {
                            type: 'audio',
                            audio_asset_id: audioAssetId
                          },
                        background: {
                          type: 'color',
                          value: '#ffffff'
                        }
                      }
                    ]
                  };

                  const generateResponse = await axios.post(
                    `${serverbaseURL}api/generate-video`,
                    videoPayload
                  );

                  const videoId = generateResponse.data.data.video_id;

                  const statusResponse = await axios.get(
                    `${serverbaseURL}api/video/status`,
                    { params: { video_id: videoId } }
                  );

                  const result = statusResponse.data.data.video_url;
                  updateNodeProperties(node.id, {
                    ...node.data.properties,
                    output: result
                  });
                  setNodeOutput(node.id, result);
                  return result;
                } catch (error) {
                  console.error('HeyGen Video Generation Error:', error);
                  throw error;
                }

              case 'graph-generator':
                try {
                  const { chartType, title, description, csvFile } = node.data.properties;

                  console.log('Graph Generator Properties:', {
                    chartType,
                    title,
                    description,
                    csvFile,
                  });

                  // Validate inputs
                  if (!csvFile) {
                    throw new Error('CSV file is required to generate the chart');
                  }
                  if (!title) {
                    throw new Error('Title is required');
                  }

                  // Prepare form data
                  const formData = new FormData();
                  formData.append('title', title);
                  if (description) formData.append('description', description);
                  formData.append('csvFile', csvFile);

                  // Send request to the backend
                  const response = await axios.post(
                    `${serverbaseURL}api/generate-video-bar-graph`,
                    formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    }
                  );

                  // Update node properties and set the output
                  updateNodeProperties(node.id, {
                    ...node.data.properties,
                    output: response.data,
                  });

                  setNodeOutput(node.id, response.data);
                } catch (error) {
                  console.error('Graph Generation Error:', error);
                  throw error;
                }
                break;

              case 'video-stitcher':
                try {
                  const videos = node.data.properties.videos || [];

                  if (videos.length === 0) {
                    throw new Error('No videos provided for stitching');
                  }

                  // Validate all URLs are from Cloudinary
                  videos.forEach((video, index) => {
                    if (!video.url || !video.url.includes('cloudinary')) {
                      throw new Error(`Invalid Cloudinary URL at position ${index + 1}`);
                    }
                  });

                  const response = await axios.post(
                    `${serverbaseURL}api/stitch-videos`,
                    { videos },
                    {
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    }
                  );

                  if (!response.data.success) {
                    throw new Error('Failed to stitch videos');
                  }

                  const result = response.data.video_url;
                  updateNodeProperties(node.id, {
                    ...node.data.properties,
                    output: result
                  });
                  setNodeOutput(node.id, result);
                  return result;

                } catch (error) {
                  console.error('Video Stitching Error:', error);
                  throw error;
                }

              case 'lip-sync':
                try {
                  const { video_url, audio_url } = node.data.properties;

                  if (!video_url || !audio_url) {
                    throw new Error('Video and Audio URLs are required');
                  }

                  const response = await axios.post(
                    `${serverbaseURL}api/generate-lip-sync`,
                    {
                      video_url,
                      audio_url,
                    }
                  );

                  if (!response.data.requestId) {
                    throw new Error('Lip Sync Generation Failed');
                  }

                  const result = response.data.videoData;
                  updateNodeProperties(node.id, {
                    ...node.data.properties,
                    output: result,
                  });
                  setNodeOutput(node.id, result);
                  return result;
                } catch (error) {
                  console.error('Lip Sync Generation Error:', error);
                  throw error;
                }

              case 'video-overlay':
                try {
                  const { mainVideo, overlayVideo, position, size, padding } = node.data.properties;

                  if (!mainVideo || !overlayVideo) {
                    throw new Error('Both main video and overlay video URLs are required');
                  }

                  const response = await axios.post(
                    `${serverbaseURL}api/overlay-video`,
                    {
                      mainVideo,
                      overlayVideo,
                      position,
                      size,
                      padding: parseInt(padding)
                    },
                    {
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    }
                  );

                  const result = response.data.video_url;
                  updateNodeProperties(node.id, {
                    ...node.data.properties,
                    output: result
                  });
                  setNodeOutput(node.id, result);
                  return result;

                } catch (error) {
                  console.error('Video Overlay Error:', error);
                  throw error;
                }

              case 'kling-image-to-video':
                try {
                  // Get the inputs and clean them up more thoroughly
                  let imageUrls;
                  try {
                    const imageUrlsString = node.data.properties.imageUrls
                      .trim()
                      .replace(/\n/g, '') // Remove newlines
                      .replace(/,\s*]/g, ']') // Remove trailing commas before closing bracket
                      .replace(/^\[/, '[') // Ensure proper opening bracket
                      .replace(/]$/, ']') // Ensure proper closing bracket
                      .replace(/'/g, '"'); // Replace single quotes with double quotes

                    try {
                      imageUrls = JSON.parse(imageUrlsString);
                    } catch (jsonError) {
                      // If JSON parsing fails, try evaluating as array string
                      // This is safer than using eval()
                      imageUrls = imageUrlsString
                        .slice(1, -1) // Remove brackets
                        .split(',')
                        .map(url => url.trim())
                        .filter(url => url.length > 0);
                    }

                    // Ensure we have an array
                    if (!Array.isArray(imageUrls)) {
                      throw new Error('Image URLs must be an array');
                    }

                    // Clean up each URL
                    imageUrls = imageUrls.map(url =>
                      url.trim()
                        .replace(/^['"]/, '') // Remove leading quotes
                        .replace(/['"]$/, '') // Remove trailing quotes
                    ).filter(url => url.length > 0);

                  } catch (e) {
                    console.error('Image URL parsing error:', e);
                    throw new Error('Invalid image URLs format. Expected array of URLs');
                  }

                  let videoPrompts;
                  try {
                    videoPrompts = JSON.parse(node.data.properties.videoPrompts);
                    // Validate the structure of video prompts
                    if (!Array.isArray(videoPrompts) || !videoPrompts.every(p => p.prompt)) {
                      throw new Error('Video prompts must be an array of objects with "prompt" property');
                    }
                  } catch (e) {
                    throw new Error('Invalid video prompts format. Expected JSON array of objects with "prompt" property');
                  }

                  // Validate inputs
                  if (!imageUrls.length) {
                    throw new Error('At least one image URL is required');
                  }

                  if (imageUrls.length !== videoPrompts.length) {
                    console.log('Image URLs:', imageUrls);  // For debugging
                    console.log('Video Prompts:', videoPrompts);  // For debugging
                    throw new Error(`Number of image URLs (${imageUrls.length}) must match number of video prompts (${videoPrompts.length})`);
                  }

                  // Prepare payload for API
                  const payload = {
                    pairs: imageUrls.map((url, index) => ({
                      imageUrl: url,
                      prompt: videoPrompts[index].prompt
                    })),
                    ratio: node.data.properties.ratio || '16:9',
                    length: node.data.properties.length || 3
                  };

                  console.log("Payload:", payload);

                  // Send to backend
                  response = await axios.post(`${serverbaseURL}api/generate-direct-kling-video`, payload);
                  break;
                } catch (error) {
                  console.error('Kling Video Generation Error:', error);
                  throw error;
                }
                break;

              default:
                throw new Error(`Unsupported node type: ${node.data.type}`);
            }

            console.log('API Response:', response.data);

            const result = response.data;
            updateNodeProperties(node.id, {
              ...node.data.properties,
              output: result
            });
            setNodeOutput(node.id, result);
            return result;

          } catch (error) {
            console.error(`Error processing node ${node.id}:`, error);
            setNodeOutput(node.id, { error: error.message });
            throw error;
          }
        };

        // Execute the flow by processing nodes in order
        try {
          // Find the output node (usually the last one in the flow)
          const outputNodes = modifiedTemplate.flowData.data.nodes.filter(node => {
            // Typically video-composition or video-stitcher nodes are output nodes
            return ['video-composition', 'video-stitcher', 'heygen-video'].includes(node.data.type);
          });

          if (outputNodes.length === 0) {
            throw new Error('No output node found in the flow');
          }

          // Process the output node, which will recursively process all required input nodes
          const finalResult = await processNode(outputNodes[0]);
          console.log('Final result:', finalResult);

          // Update generation status
          await axios.post(`${serverbaseURL}update-generation-status`, {
            uid: user.uid,
            templateId: selectedTemplate.id,
          });

          // Set the generated video URL
          if (finalResult && (finalResult.url || finalResult.videoUrl)) {
            setGeneratedVideoUrl(finalResult.url || finalResult.videoUrl);
            setShowGeneratedVideo(true);
          } else if (typeof finalResult === 'string' && (finalResult.includes('cloudinary') || finalResult.includes('video'))) {
            setGeneratedVideoUrl(finalResult);
            setShowGeneratedVideo(true);
          } else {
            throw new Error('No video URL found in the final result');
          }
        } catch (error) {
          console.error('Error executing flow:', error);
          throw error;
        }
      } else {
        throw new Error('Template does not contain valid flow data');
      }
    } catch (error) {
      console.error('Operation failed:', error);
      await axios.post(`${serverbaseURL}update-generation-status`, {
        uid: user.uid,
        templateId: selectedTemplate.id,
        status: 'failed'
      });
      let errorMessage = 'Failed to generate video. Please try again.';
      const originalCredits = userCredits + selectedTemplate.credits;
      if (error.response?.status === 400 && error.response?.data?.message === "Not enough credits.") {
        errorMessage = "You don't have enough credits for this operation.";
        updateCredits(originalCredits);
      } else if (error.response?.status === 404) {
        errorMessage = "User account not found. Please try logging in again.";
      } else if (error.message.includes('Narrator ID')) {
        errorMessage = 'Please select a narrator voice before generating the video.';
      } else if (error.message.includes('audio')) {
        errorMessage = 'Failed to generate audio. Please try a different narrator voice.';
      }
      setGenerationError(errorMessage);
      if (error.response?.status !== 400) {
        try {
          const userDetailsResponse = await axios.post(`${serverbaseURL}get_user_details`, {
            uid: user.uid
          });
          if (userDetailsResponse.data.credits < originalCredits) {
            await axios.post(`${serverbaseURL}refund_credits`, {
              uid: user.uid,
              credits: selectedTemplate.credits
            });
            const finalCreditsResponse = await axios.post(`${serverbaseURL}get_user_details`, {
              uid: user.uid
            });
            updateCredits(finalCreditsResponse.data.credits);
          } else {
            updateCredits(originalCredits);
          }
        } catch (refundError) {
          console.error('Failed to handle credits:', refundError);
          updateCredits(originalCredits);
        }
      }
    } finally {
      setIsGenerating(false);
    }
  };
  // Add function to handle scene updates
  const handleSceneUpdate = (index, newPrompt) => {
    const updatedScenes = [...editableScenes];
    updatedScenes[index] = { ...updatedScenes[index], prompt: newPrompt };
    setEditableScenes(updatedScenes);
  };


  // Add these navigation functions for clips
  const handleNextClip = () => {
    setCurrentClipIndex((prev) => (prev + 1) % generatedClips.length);
  };

  const handlePrevClip = () => {
    setCurrentClipIndex((prev) =>
      prev === 0 ? generatedClips.length - 1 : prev - 1
    );
  };


  const handleVideoUpload = async (file) => {
    setUploadedVideo(file);
    toast.success('Video uploaded successfully! Click Generate to start processing.');
  };



  const handleVideoProcessing = async () => {
    if (!uploadedVideo) {
      toast.error('Please upload a video first');
      return;
    }
    if (!clipPrompt.trim()) {
      toast.error('Please specify which part of the video to clip');
      return;
    }
    if (userCredits < selectedTemplate.credits) {
      toast.error('You don\'t have enough credits for this operation');
      return;
    }

    setIsProcessing(true);
    setIsGenerating(true);
    setGenerationError(null);
    const originalCredits = userCredits;

    try {
      // Deduct credits first
      const deductionResponse = await axios.post(`${serverbaseURL}deduct_credits`, {
        uid: user.uid,
        credits: selectedTemplate.credits
      });

      // Update local credits state
      updateCredits(userCredits - selectedTemplate.credits);

      // Process video
      const formData = new FormData();
      formData.append('video', uploadedVideo);
      formData.append('prompt', clipPrompt);
      formData.append('uid', user.uid);
      formData.append('email', user.email);
      const response = await axios.post(`${serverbaseURL}process-video-to-shorts`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (response.data.success && response.data.clipUrls) {
        setGeneratedClips(response.data.clipUrls);
        setTopic(response.data.topic)
        setShowClipsModal(true);
        setCurrentClipIndex(0);

        toast.success('Video processing completed successfully!');
      } else {
        throw new Error('Failed to process video');
      }
    } catch (error) {
      console.error('Error processing video:', error);
      const errorMessage = error.response?.data?.message || 'Failed to process video';
      setGenerationError(errorMessage);
      toast.error(errorMessage);
      // Refund credits on error
      try {
        await axios.post(`${serverbaseURL}refund_credits`, {
          uid: user.uid,
          credits: selectedTemplate.credits
        });
        updateCredits(originalCredits);
      } catch (refundError) {
        console.error('Failed to refund credits:', refundError);
      }
    } finally {
      setIsProcessing(false);
      setIsGenerating(false);
    }
  };

  // Add the handleLike function
  const handleLike = async (videoId) => {
    try {
      const response = await axios.put(` ${serverbaseURL}like_template_video/${videoId}`);
      if (response.data.success) {
        setTemplateVideo(prevVideos =>
          prevVideos.map(video =>
            video._id === videoId ? { ...video, likes: response.data.data.likes } : video
          )
        );
      }
    } catch (error) {
      console.error('Error liking video:', error);
      // Optionally show error toast/message
    }
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prev) =>
      prev === generatedImages.length - 1 ? 0 : prev + 1
    );
  };

  const goToPrevImage = () => {
    setCurrentImageIndex((prev) =>
      prev === 0 ? generatedImages.length - 1 : prev - 1
    );
  };

  return (
    <>

      {showApprovalModal && <ApprovalModal />}
      <div className="min-h-screen  px-6 py-8">
        <div className="max-w-[1600px] mx-auto">
          {/* Main container */}
          <div className=" ml-6 flex items-center gap-16  my-4 ">
            {/* First div with heading */}
            <div className="">
              <h1 className="text-3xl font-bold text-gray-800">{selectedTemplate.title}</h1>
            </div>
            {/* Second div with image and name */}
            <div className="flex items-center gap-2">
              <img
                src={selectedTemplate.seller.avatar}
                alt={selectedTemplate.seller.name}
                className="w-8 h-8 rounded-full object-cover "
              />
              <h2 className="text-md font-semibold text-gray-800">{selectedTemplate.seller.name}</h2>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-evenly items-start">
            {selectedTemplate && (
              <div className="bg-gradient-to-br from-[#f8f8ff] via-[#e6e6fa] to-[#dcdcdc]  rounded-xl w-full max-w-lg  max-h-[90vh] flex flex-col overflow-hidden">
                {/* Header */}
                <div className="p-4 border-b">
                  <div className="flex justify-between items-start">
                    <h2 className="text-2xl font-bold text-gray-800">Generate Video</h2>
                  </div>
                </div>
                {/* Scrollable Content */}
                <div className="flex-1 overflow-y-auto p-4 space-y-4">
                  {/* Template Image */}
                  <div className="relative h-32 w-full rounded-lg overflow-hidden">
                    <img
                      src={selectedTemplate.image}
                      alt={selectedTemplate.title}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                  </div>

                  {/* Template Details */}
                  <div className="space-y-3">
                    {/* Title Section */}
                    <div className="bg-gray-50 p-3 rounded-lg">
                      <div className="flex items-center gap-3 text-gray-700">
                        <VideoIcon className="h-5 w-5 text-violet-600 shrink-0" />
                        <div className="min-w-0">
                          <p className="text-sm text-gray-500">Title</p>
                          <p className="font-medium truncate">{selectedTemplate.title}</p>
                        </div>
                      </div>
                    </div>
                    {selectedTemplate?.flowData?.data?.nodes?.[0]?.data?.properties?.output?.shortclip === shortclip ? (
                      <div className="flex justify-center items-center ">
                        <div className="bg-white rounded-lg p-6 w-full max-w-md">
                          <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-bold">Upload Video</h2>
                          </div>
                          <div className="space-y-4">
                            <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
                              <input
                                type="file"
                                accept="video/*"
                                onChange={(e) => {
                                  const file = e.target.files?.[0];
                                  if (file) {
                                    handleVideoUpload(file);
                                  }
                                }}
                                className="hidden"
                                id="video-upload"
                              />
                              <label
                                htmlFor="video-upload"
                                className="cursor-pointer text-gray-600"
                              >
                                <div>
                                  <span className="block mb-2">
                                    {uploadedVideo ? uploadedVideo.name : 'Drop your video here or click to upload'}
                                  </span>
                                  <span className="text-sm text-gray-500">MP4, MOV, or AVI</span>
                                </div>
                              </label>
                            </div>
                            {/* Input field for specifying the clip part */}
                            <div>
                              <label htmlFor="clip-prompt" className="block text-gray-700 mb-2">Describe which parts of the video to you want to clip </label>
                              <input
                                type="text"
                                id="clip-prompt"
                                value={clipPrompt}
                                onChange={(e) => setClipPrompt(e.target.value)}
                                placeholder="Enter the part of the video to clip"
                                className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      /* Content Section for Other Templates */
                      <div className="bg-gray-50 p-3 rounded-lg">
                        <div className="flex justify-between items-center mb-2">
                          <h3 className="font-semibold text-gray-800">Content</h3>
                          <button
                            onClick={() => setIsEditing(!isEditing)}
                            className="text-sm text-violet-600 hover:text-violet-700 font-medium"
                          >
                            {isEditing ? 'Save Changes' : 'Edit Content'}
                          </button>
                        </div>

                        {loadingScenes ? (
                          <div className="text-center py-4">
                            <p className="text-gray-600">Loading...</p>
                          </div>
                        ) : templateScenes.type === 'scenes' && editableScenes.length > 0 ? (
                          <div className="space-y-2 max-h-48 overflow-y-auto">
                            {editableScenes.map((scene, index) => (
                              <div
                                key={index}
                                className="bg-white p-3 rounded border border-gray-200"
                              >
                                <div className="flex justify-between items-start mb-2">
                                  <span className="font-medium text-sm text-gray-700">
                                    Scene {index + 1}
                                  </span>
                                </div>
                                {isEditing ? (
                                  <textarea
                                    value={scene.prompt}
                                    onChange={(e) => handleSceneUpdate(index, e.target.value)}
                                    className="w-full p-2 text-sm text-gray-600 border rounded focus:outline-none focus:ring-1 focus:ring-violet-500"
                                    rows="2"
                                  />
                                ) : (
                                  <p className="text-sm text-gray-600">
                                    {scene.prompt}
                                  </p>
                                )}
                              </div>
                            ))}
                          </div>
                        ) : templateScenes.type === 'text' && templateScenes.prompt ? (
                          <div className="bg-white p-3 rounded border border-gray-200">
                            <span className="font-medium text-sm text-gray-700">
                              ChatGPT Story Prompt
                            </span>
                            {isEditing ? (
                              <textarea
                                value={editableScenes[0]?.prompt || ''}
                                onChange={(e) => handleSceneUpdate(0, e.target.value)}
                                className="w-full mt-1 p-2 text-sm text-gray-600 border rounded focus:outline-none focus:ring-1 focus:ring-violet-500"
                                rows="3"
                              />
                            ) : (
                              <p className="text-sm text-gray-600 mt-1">
                                {templateScenes.prompt}
                              </p>
                            )}
                          </div>
                        ) : (
                          <div className="text-center py-4">
                            <p className="text-gray-500">No content available for this template</p>
                          </div>
                        )}
                      </div>
                    )}
                    {/* Credits Section */}
                    <div className="bg-gray-50 p-3 rounded-lg">
                      <div className="flex items-center gap-3 text-gray-700">
                        <Wallet className="h-5 w-5 text-violet-600 shrink-0" />
                        <div className="min-w-0">
                          <p className="text-sm text-gray-500">Credits Required</p>
                          <div className="flex items-center gap-2">
                            <p className="font-medium">{selectedTemplate.credits}</p>
                            <span className="text-sm text-gray-500">
                              (You have: {userCredits})
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-4 border-t bg-gray-50">
                  {selectedTemplate?.flowData?.data?.nodes?.[0]?.data?.properties?.output?.shortclip === shortclip ? (
                    // Clip Generation Button
                    <button
                      onClick={handleVideoProcessing}
                      disabled={
                        !uploadedVideo ||
                        isProcessing ||
                        !clipPrompt.trim() ||
                        userCredits < selectedTemplate.credits
                      }
                      className={`w-full py-3 px-4 rounded-lg shadow-lg transition-all duration-300 flex items-center justify-center gap-2 text-white font-semibold
    ${!uploadedVideo || isProcessing || userCredits < selectedTemplate.credits
                          ? 'bg-gray-400 cursor-not-allowed'
                          : 'bg-gradient-to-r from-[#805af5] to-[#cd99ff] hover:shadow-xl hover:opacity-90'
                        }`}
                    >
                      {isProcessing ? (
                        <>
                          <Loader2 className="animate-spin h-5 w-5" />
                          <span>Processing...</span>
                        </>
                      ) : (
                        <span>Generate Clips ({selectedTemplate.credits} credits)</span>
                      )}
                    </button>
                  ) : (
                    // Video Generation Button
                    <button
                      onClick={handleFinalGenerate}
                      disabled={userCredits < selectedTemplate.credits || isGenerating}
                      className={`w-full py-3 px-4 rounded-lg shadow-lg transition-all duration-300 flex items-center justify-center gap-2 text-white font-semibold
        ${userCredits >= selectedTemplate.credits && !isGenerating
                          ? 'bg-gradient-to-r from-[#805af5] to-[#cd99ff] hover:shadow-xl hover:opacity-90'
                          : 'bg-gray-400 cursor-not-allowed'
                        }`}
                    >
                      {isGenerating ? (
                        <>
                          <Loader2 className="animate-spin h-5 w-5" />
                          <span>Generating...</span>
                        </>
                      ) : userCredits >= selectedTemplate.credits ? (
                        <span>Generate Video</span>
                      ) : (
                        <span>Insufficient Credits</span>
                      )}
                    </button>
                  )}
                </div>
              </div>
            )}

            <div className="  rounded-xl max-w-4xl w-full overflow-y-auto px-4 pb-10 pt-4">
              {/* Error Section */}
              {generationError ? (
                <div className=" flex justify-center items-center   ">
                  <div className="bg-gradient-to-br from-[#f8f8ff] via-[#e6e6fa] to-[#dcdcdc]  mt-24 rounded-lg p-4 shadow-xl w-80">
                    <h2 className="text-xl font-bold text-red-600 mb-3">Generation Failed</h2>
                    <p className="text-gray-800 mb-3">{generationError}</p>
                  </div>
                </div>
              ) : showGeneratedVideo && generatedVideoUrl ? (
                /* Generated Video Section */
                <div className=" align-bottom bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
                  <div className="relative">
                    {/* Video player */}
                    <div className="aspect-video w-full">
                      <video className="w-full h-full object-contain" controls autoPlay>
                        <source src={generatedVideoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                  {/* Video information */}
                  <div className="bg-white px-4 py-3">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Generated Video</h3>
                    <p className="mt-1 text-sm text-gray-500">Your generated video is ready</p>
                    <button
                      className="inline-flex items-center rounded-lg gap-2 mt-2 bg-gradient-to-r from-[#805af5] to-blue-600 text-white px-4 py-2 rounded-lghover:shadow-lg transition duration-200"
                      onClick={() => handleSchedulePost(generatedVideoUrl, selectedTemplate.title)}
                    >
                      <Share2 className="w-4 h-4" />
                      Post to Socials
                    </button>
                  </div>
                </div>
              ) : showClipsModal && generatedClips.length > 0 ? (
                <div className="align-bottom  rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
                  <div className="fixed inset-0 bg-opacity-75 transition-opacity" />
                  <div className="relative  w-full max-w-md mx-4">
                    <div className="bg-black relative left-40">
                      <button
                        onClick={() => setShowClipsModal(false)}
                        className="absolute top-2 right-2 z-10 p-1.5 bg-white bg-opacity-25 rounded-full hover:bg-opacity-40"
                      >
                        <X className="h-5 w-5 text-white" />
                      </button>
                      <div className="aspect-[9/16] w-full h-[78vh]">
                        <video
                          key={generatedClips[currentClipIndex]}
                          className="w-full h-full object-contain"
                          controls
                          autoPlay
                        >
                          <source src={generatedClips[currentClipIndex]} type="video/mp4" />
                        </video>
                        <div className="absolute inset-x-0 top-1/2 flex justify-between items-center px-2 -translate-y-1/2">
                          <button
                            onClick={handlePrevClip}
                            className="p-1.5 bg-white bg-opacity-25 rounded-full hover:bg-opacity-40"
                          >
                            <ChevronLeft className="h-5 w-5 text-white" />
                          </button>
                          <button
                            onClick={handleNextClip}
                            className="p-1.5 bg-white bg-opacity-25 rounded-full hover:bg-opacity-40"
                          >
                            <ChevronRight className="h-5 w-5 text-white" />
                          </button>
                        </div>
                      </div>
                      <div className="bg-white px-3 py-2 ">
                        <div className="flex justify-between items-center">
                          <h3 className="text-sm font-medium text-gray-900">
                            Clip {currentClipIndex + 1} of {generatedClips.length}
                          </h3>
                          {/* <a
                            href={generatedClips[currentClipIndex]}
                            download={`clip-${currentClipIndex + 1}.mp4`}
                            className="text-sm text-blue-600 hover:text-blue-800 font-medium"
                          >
                            Download
                          </a> */}
                          <button
                            className="inline-flex items-center rounded-lg gap-2 mt-2 bg-gradient-to-r from-[#805af5] to-blue-600 text-white px-4 py-2 rounded-lghover:shadow-lg transition duration-200"
                            onClick={() => handleSchedulePost(generatedClips[0], topic)}
                          >
                            <Share2 className="w-4 h-4" />
                            Post to Socials
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              ) : isGenerating ? (
                /* Generating Section */
                <div className='min-h-[300px] flex justify-center items-center'>
                  <div className="bg-gradient-to-br from-[#f8f8ff] via-[#e6e6fa] to-[#dcdcdc] mt-24 rounded-3xl p-8 shadow-2xl w-96 md:w-[400px] text-center animate-slide-up flex flex-col items-center justify-center">
                    {console.log('Rendering loading spinner')}
                    {/* Loader Animation */}
                    <div className="flex flex-col items-center gap-4 animate-fade-in justify-center">
                      <Loader2 className="animate-spin h-12 w-12 text-blue-600" />
                      <h2 className="text-2xl font-semibold text-gray-900 animate-bounce">Generating Your Video</h2>
                      <p className="text-gray-700 text-base animate-fade-in">This may take a few minutes...</p>
                      <p className="text-sm text-gray-500 mt-3 max-w-xs leading-relaxed animate-fade-in">
                        You can also check the progress in <span className="font-medium text-blue-600">"My Generations"</span>
                      </p>

                      {/* Additional Content */}
                      <div className="mt-4 flex items-center gap-2 animate-fade-in justify-center">
                        <Sparkles className="h-6 w-6 text-yellow-500 animate-pulse" />
                        <p className="text-sm text-gray-600 italic">Enhancing your video with high-quality effects...</p>
                      </div>
                    </div>
                  </div>

                </div>
              ) : (
                /* Preview Video Section */
                <div className="inline-block align-bottom bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-4xl sm:w-full">
                  {/* Video player */}
                  <div className="aspect-video w-full">
                    <video className="w-full h-full object-contain" controls autoPlay>
                      <source src={selectedTemplate.previewVideo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  {/* Video information */}
                  <div className="bg-white px-4 py-4">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      {selectedTemplate.name} - Preview
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Sample video preview of the template
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">
            Generated Template by user: <span>{GenerationCount} Runs</span>
          </h2>
          {templateVideo.length === 0 ? (
            <div className="text-center py-8 bg-gray-50 rounded-lg">
              <p className="text-gray-500">No Generated Template by user</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {templateVideo.map((video, index) => {
                // Safely extract nested properties with optional chaining
                const prompt = video?.flowData?.data?.nodes?.[0]?.data?.properties?.prompt
                  || "Custom generated video";
                console.log("prompt", prompt)
                const username = video?.email || "Community Member";
                const likes = video?.likes || 0;

                return (
                  <div
                    key={video?.id || index}
                    className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-shadow"
                  >
                    {/* Video Container */}
                    <div className="aspect-video bg-gray-100">
                      {video.videoUrl ? (
                        <div className="inline-block align-bottom bg-black  text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-4xl sm:w-full">

                          <div className="aspect-video w-full">
                            <video className="w-full h-full object-contain" controls >
                              <source src={video.videoUrl} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        </div>
                      ) : (
                        <div className="w-full h-full flex items-center justify-center text-gray-400">
                          <VideoIcon className="w-12 h-12" />
                        </div>
                      )}
                    </div>
                    {/* Card Footer */}
                    <div className="p-4">
                      <h3 className="font-medium text-gray-700 mb-2">
                        Created by: {username}
                      </h3>
                      <p className="text-sm text-gray-600 ">
                        {prompt}
                      </p>
                      <div className="flex items-center  mt-4 gap-2">
                        <button
                          onClick={() => handleLike(video._id)}
                          className="flex items-center gap-1 text-gray-500 hover:text-red-500 transition-colors"
                        >
                          <HeartIcon className="w-6 h-6 text-red " />
                          <span className="text-lg font-semibold text-red-500">{likes}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div> 
    </>
  )
}
