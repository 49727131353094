import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { Loader2 } from "lucide-react";
import { serverbaseURL } from "../constant/index.jsx";
import { AuthContext } from "../provider/AuthProvider";
import { motion } from 'framer-motion';
import LoginModal from '../components/LoginModal';
import { Video, Star } from "lucide-react";

import {
  Wallet,
  VideoIcon,
  Paintbrush,
  Mic,
  X,
  ChevronLeft,
  ChevronRight
} from "lucide-react";
import MyGenerations from "./MyGenerations.jsx";
import { toast } from "react-toastify";
import DataInputForm from '../components/DataInputForm';

const Templates = () => {

  const hardcodedTemplates = [
    {
      id: 15,
      title: "Mahabharata",
      name: "Mahabharata",
      description: "Bring the Mahabharata to life with this AI-powered trailer template. Create epic visuals and dramatic storytelling in minutes!",
      type: "Motion Video",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1736020536/Template%20Card%20Images/ptkcgvhzh2n9ya3v2slv.png",
      previewVideo: "https://res.cloudinary.com/dgwhzfqvm/video/upload/v1736507364/preview_Videos/kwff4duvlawfyvch8kot.mp4",
      height: 1280,
      width: 720,
      style: 'CINEMATIC',
      credits: 5,
      styleUUID: 'a5632c7c-ddbb-4e2f-ba34-8456ab3ac436',
      music: "None",
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      musicDuration: "None",
      storyPrompt: ``,
      themeTemplate: `None`,
      narratorId: `pNInz6obpgDQGcFmaJgB`,
    },
    // {
    //   id: 16,
    //   title: "Animated Line Graph",
    //   name: "Animated Line Graph",
    //   description: "Effortlessly create animated line graphs for your YouTube Shorts or videos. Upload a CSV file, and the template turns your data into smooth, engaging visuals ideal for storytelling or showcasing trends.",
    //   type: "Motion Video",
    //   image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1737584152/Screenshot_2025-01-23_034317_okckgg.png",
    //   previewVideo: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1737663014/chart-animation_jawdlw.mp4",
    //   height: 1280,
    //   width: 720,
    //   style: 'CINEMATIC',
    //   credits: 5,
    //   styleUUID: 'a5632c7c-ddbb-4e2f-ba34-8456ab3ac436',
    //   music: "None",
    //   seller: {
    //     id: "AutoMovieCreator",
    //     name: "Auto Movie Creator",
    //     avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
    //   },
    //   musicDuration: "None",
    //   storyPrompt: ``,
    //   themeTemplate: `None`,
    //   narratorId: `pNInz6obpgDQGcFmaJgB`,
    // },
    // {
    //   id: 17,
    //   title: "Animated Bar Graph",
    //   name: "Animated Bar Graph",
    //   description: "Upload a CSV file, and the template will transform your data into dynamic visuals perfect for engaging storytelling or presenting stats.",
    //   type: "Motion Video",
    //   image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1737584137/Screenshot_2025-01-23_034217_psli0o.png",
    //   previewVideo: "https://res.cloudinary.com/dj3qabx11/video/upload/v1737662529/chart-animations/n0vqkqkzhhwrisgdf7ls.mp4",
    //   height: 1280,
    //   width: 720,
    //   style: 'CINEMATIC',
    //   credits: 5,
    //   styleUUID: 'a5632c7c-ddbb-4e2f-ba34-8456ab3ac436',
    //   music: "None",
    //   seller: {
    //     id: "AutoMovieCreator",
    //     name: "Auto Movie Creator",
    //     avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
    //   },
    //   musicDuration: "None",
    //   storyPrompt: ``,
    //   themeTemplate: `None`,
    //   narratorId: `pNInz6obpgDQGcFmaJgB`,
    // },
    // {
    //   id: 18,
    //   title: "Videos To Shorts",
    //   name: "Videos To Shorts",
    //   description: "Simply upload a video and receive five engaging 45-second logical shorts, carefully curated to highlight the most captivating moments of your content.",
    //   type: "Motion Video",
    //   image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1728106347/fh6grwe9ds9odcxzm1e1.png",
    //   previewVideo: "https://res.cloudinary.com/dj3qabx11/video/upload/v1737662529/chart-animations/n0vqkqkzhhwrisgdf7ls.mp4",
    //   height: 1280,
    //   width: 720,
    //   style: 'CINEMATIC',
    //   credits: 5,
    //   styleUUID: 'a5632c7c-ddbb-4e2f-ba34-8456ab3ac436',
    //   music: "None",
    //   seller: {
    //     id: "AutoMovieCreator",
    //     name: "Auto Movie Creator",
    //     avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
    //   },
    //   musicDuration: "None",
    //   storyPrompt: ``,
    //   themeTemplate: `None`,
    //   narratorId: `pNInz6obpgDQGcFmaJgB`,
    // },
  ];

  const [activeTab, setActiveTab] = useState('templates');
  const [templates, setTemplates] = useState([]); // This will store marketplace templates
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showCustomForm, setShowCustomForm] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showGeneratedVideo, setShowGeneratedVideo] = useState(false);
  const [generatedVideoUrl, setGeneratedVideoUrl] = useState(null);
  const [generationError, setGenerationError] = useState(null);
  // const [activeTab, setActiveTab] = useState("/templates");
  const { user, userCredits, updateCredits } = useContext(AuthContext);
  const userEmail = user?.email;
  const userUID = user?.uid;
  const navigate = useNavigate();
  const [showPreGenModal, setShowPreGenModal] = useState(false);
  const [selectedNarrator, setSelectedNarrator] = useState("pNInz6obpgDQGcFmaJgB");
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [showMyGenerations, setShowMyGenerations] = useState(false);
  const [marketplaceTemplates, setMarketplaceTemplates] = useState([]);
  const [showGeneratingModal, setShowGeneratingModal] = useState(false);
  const [templateScenes, setTemplateScenes] = useState([]);
  const [loadingScenes, setLoadingScenes] = useState(false);
  const [editableScenes, setEditableScenes] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  // Add loading state
  const [isLoading, setIsLoading] = useState(true);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [generatedClips, setGeneratedClips] = useState([]);
  const [currentClipIndex, setCurrentClipIndex] = useState(0);
  const [showClipsModal, setShowClipsModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  // Fetch marketplace templates on component mount
  useEffect(() => {
    const fetchMarketplaceTemplates = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${serverbaseURL}get-marketplace-templates`);
        console.log('Marketplace templates:', response.data);
        setTemplates(response.data);
        setMarketplaceTemplates(response.data);
      } catch (error) {
        console.error("Failed to fetch marketplace templates:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMarketplaceTemplates();
  }, []);

  useEffect(() => {
    const fetchUserCredits = async () => {
      if (user?.uid) {
        try {
          const response = await axios.post(`${serverbaseURL}get_user_details`, {
            uid: user.uid
          });
          updateCredits(response.data.credits);
        } catch (error) {
          console.error("Failed to fetch user credits:", error);
        }
      }
    };

    fetchUserCredits();
  }, [user]);


  // Handle tab switching
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handlePreview = (template) => {
    setSelectedTemplate(template);
    setShowPreview(true);
  };

  const handleGenerate = async (template) => {
    if (!user) {
      setShowLoginPopup(true);
      setSelectedTemplate(template);
      return;
    }

    setSelectedTemplate(template);

    // Special handling for video to shorts template
    if (template.id === 18) {
      setShowCustomForm(true);
      return;
    }

    // Special handling for graph templates
    if (template.id === 16 || template.id === 17) {
      setShowCustomForm(true);
      return;
    }

    setShowPreGenModal(true);

    setLoadingScenes(true);
    try {
      const response = await axios.get(`${serverbaseURL}api/template-scenes/${template.id}`);
      if (response.data.success) {
        setTemplateScenes(response.data);
        if (response.data.type === 'scenes') {
          setEditableScenes(response.data.scenes);
        } else if (response.data.type === 'text') {
          setEditableScenes([{ prompt: response.data.prompt }]);
        }
      } else {
        toast.error('Failed to load template content');
      }
    } catch (error) {
      console.error('Error fetching template content:', error);
      toast.error('Failed to load template content');
      setTemplateScenes({ type: 'none' });
      setEditableScenes([]);
    } finally {
      setLoadingScenes(false);
    }
  };

  const handleFinalGenerate = async () => {
    setShowPreGenModal(false);
    setIsGenerating(true);
    toast.success(`Video Generation added to render queue successfully!`);
    setGenerationError(null);

    try {
      console.log('Selected template:', selectedTemplate);
      console.log('Selected template flowData:', selectedTemplate.flowData);
      const originalCredits = userCredits;
      const newCredits = userCredits - selectedTemplate.credits;
      updateCredits(newCredits);
      const deductionResponse = await axios.post(`${serverbaseURL}deduct_credits`, {
        uid: user.uid,
        credits: selectedTemplate.credits
      });
      await axios.post(`${serverbaseURL}add-generation-status`, {
        uid: user.uid,
        templateId: selectedTemplate.id,
        templateName: selectedTemplate.title,
      });
      const template = selectedTemplate;
      if (template.id >= 1 && template.id <= 15) {

        const isSpecialTemplate = [4, 6, 9, 10, 12].includes(template.id);

        if (isSpecialTemplate && !template.narratorId) {
          throw new Error('Narrator ID is required for this template');
        }
        const endpoint = isSpecialTemplate
          ? `${serverbaseURL}create_template_video_cloudinary_two`
          : `https://amc-flow.onrender.com/api/generate-from-template`;

        const payload = isSpecialTemplate
          ? {
            id: template.id,
            themeTemplate: template.themeTemplate,
            topic: template.title,
            email: userEmail,
            uid: userUID,
            height: template.height,
            width: template.width,
            music: template.music || "None",
            musicDuration: template.musicDuration || 0,
            storyPrompt: template.storyPrompt,
            style: template.style,
            styleUUID: template.styleUUID,
            narratorId: template.narratorId,
            credits: template.credits
          }
          : {
            templateId: template.id.toString(),
            topic: template.title,
            email: userEmail,
            uid: userUID,
            id: template.id,
            credits: template.credits
          };
        const response = await axios.post(endpoint, payload);
        console.log(response);
        await axios.post(`${serverbaseURL}update-generation-status`, {
          uid: user.uid,
          templateId: selectedTemplate.id,
        });
        if (!response.data || !response.data.videoUrl) {
          throw new Error(response.data?.error || 'No video URL received from server');
        }
        setGeneratedVideoUrl(response.data.videoUrl);
        setShowGeneratedVideo(true);
      }

      // Use edited scenes if available
      const scenesToUse = isEditing ? editableScenes :
        (templateScenes.type === 'scenes' ? templateScenes.scenes :
          [{ prompt: templateScenes.prompt }]);
      // Create a modified template
      const modifiedTemplate = {
        ...template,
        flowData: {
          ...template.flowData,
          data: {
            ...template.flowData?.data,
            nodes: template.flowData?.data.nodes.map(node => {
              if (node.data?.type === 'prompt-input') {
                const updatedScenes = editableScenes.map((scene, index) => {
                  const originalScene = node.data.properties.scenes[index];
                  return {
                    id: originalScene?.id || index + 1,
                    prompt: scene.prompt
                  };
                });

                return {
                  ...node,
                  data: {
                    ...node.data,
                    properties: {
                      ...node.data.properties,
                      scenes: updatedScenes
                    }
                  }
                };
              } else if (node.data?.type === 'text-generation') {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    properties: {
                      ...node.data.properties,
                      prompt: editableScenes[0]?.prompt || ''
                    }
                  }
                };
              }
              return node;
            })
          }
        }
      };
      console.log("modifiedTemplate", modifiedTemplate)
      const response = await axios.post(`${serverbaseURL}api/generate-from-template-user`, {
        templateId: modifiedTemplate.id.toString(),
        topic: modifiedTemplate.title,
        email: userEmail,
        uid: userUID,
        id: modifiedTemplate.id,
        credits: modifiedTemplate.credits,
        flowData: modifiedTemplate.flowData
      });

      console.log(response);
      await axios.post(`${serverbaseURL}update-generation-status`, {
        uid: user.uid,
        templateId: selectedTemplate.id,
      });
      if (!response.data || !response.data.videoUrl) {
        throw new Error(response.data?.error || 'No video URL received from server');
      }
      setGeneratedVideoUrl(response.data.videoUrl);
      setShowGeneratedVideo(true);
    } catch (error) {
      console.error('Operation failed:', error);
      await axios.post(`${serverbaseURL}update-generation-status`, {
        uid: user.uid,
        templateId: selectedTemplate.id,
      });
      let errorMessage = 'Failed to generate video. Please try again.';
      const originalCredits = userCredits + selectedTemplate.credits;
      if (error.response?.status === 400 && error.response?.data?.message === "Not enough credits.") {
        errorMessage = "You don't have enough credits for this operation.";
        updateCredits(originalCredits);
      } else if (error.response?.status === 404) {
        errorMessage = "User account not found. Please try logging in again.";
      } else if (error.message.includes('Narrator ID')) {
        errorMessage = 'Please select a narrator voice before generating the video.';
      } else if (error.message.includes('audio')) {
        errorMessage = 'Failed to generate audio. Please try a different narrator voice.';
      }
      setGenerationError(errorMessage);
      if (error.response?.status !== 400) {
        try {
          const userDetailsResponse = await axios.post(`${serverbaseURL}get_user_details`, {
            uid: user.uid
          });
          if (userDetailsResponse.data.credits < originalCredits) {
            await axios.post(`${serverbaseURL}refund_credits`, {
              uid: user.uid,
              credits: selectedTemplate.credits
            });
            const finalCreditsResponse = await axios.post(`${serverbaseURL}get_user_details`, {
              uid: user.uid
            });
            updateCredits(finalCreditsResponse.data.credits);
          } else {
            updateCredits(originalCredits);
          }
        } catch (refundError) {
          console.error('Failed to handle credits:', refundError);
          updateCredits(originalCredits);
        }
      }
    } finally {
      setIsGenerating(false);
    }
  };

  const handleCustomFormSubmit = async (formData) => {
    setShowCustomForm(false);
    setIsGenerating(true);

    try {
      const formDataObj = new FormData();
      formDataObj.append('csvFile', formData.file);
      formDataObj.append('title', formData.title);
      formDataObj.append('description', formData.description);

      const endpoint = selectedTemplate.id === 16
        ? `${serverbaseURL}api/generate-video-line-graph`
        : `${serverbaseURL}api/generate-video-bar-graph`;

      const response = await axios.post(endpoint, formDataObj, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        setGeneratedVideoUrl(response.data.videoUrl);
        setShowGeneratedVideo(true);
        toast.success('Video generated successfully!');
      } else {
        throw new Error(response.data.error || 'Failed to generate video');
      }
    } catch (error) {
      console.error('Generation failed:', error);
      toast.error(error.message || 'Failed to generate video');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleRedirectToLogin = () => {
    setShowLoginPopup(false);
    navigate("/Login", { state: { from: { pathname: "/Templates" } } });
  };

  const handleSellerClick = async (seller) => {
    if (selectedSeller?.id === seller.id) {
      setSelectedSeller(null);
      // Reset marketplace templates to initial state
      setMarketplaceTemplates([]); // Clear first to avoid duplicates
      try {
        const response = await axios.get(`${serverbaseURL}get-marketplace-templates`);
        setMarketplaceTemplates(response.data);
      } catch (error) {
        console.error("Failed to fetch marketplace templates:", error);
      }
    } else {
      setSelectedSeller(seller);
      setMarketplaceTemplates([]); // Clear existing templates first
      try {
        const response = await axios.get(`${serverbaseURL}get-seller-templates/${seller.id}`);
        setMarketplaceTemplates(response.data);
      } catch (error) {
        console.error("Failed to fetch seller templates:", error);
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  // Modify to only use marketplace templates
  const allTemplates = [
    ...marketplaceTemplates.map(template => ({
      id: template.templateId,
      title: template.title,
      name: template.title,
      description: template.description,
      type: "Custom",
      generatedCount: template.generatedCount,
      image: template.thumbnailUrl,
      previewVideo: template.videoPreview,
      height: 1280,
      width: 720,
      style: template.flowData?.style || 'CINEMATIC',
      styleUUID: template.flowData?.styleUUID || 'a5632c7c-ddbb-4e2f-ba34-8456ab3ac436',
      credits: template.credits,
      seller: {
        id: template.sellerId,
        name: template.creatorName,
        avatar: template.brandlogo || "https://res.cloudinary.com/dyzcpcibf/image/upload/v1728106347/fh6grwe9ds9odcxzm1e1.png"
      },
      music: "None",
      musicDuration: "None",
      storyPrompt: template.flowData?.storyPrompt || "",
      themeTemplate: template.flowData?.themeTemplate || "None",
      flowData: template.flowData
    })),
    // Add hardcoded graph templates if no seller is selected
    ...(selectedSeller ? [] : hardcodedTemplates.filter(t => t.id >= 16))
  ];

  // Update filteredTemplates to use allTemplates
  const filteredTemplates = selectedSeller
    ? allTemplates.filter(template => template.seller.id === selectedSeller.id)
    : allTemplates;

  const handleShowMyGenerations = () => {
    setShowMyGenerations(true);
  };

  const handleTemplateClick = (template) => {
    navigate(`/templatedetails/${template.id}`, { state: { template } });
  };

  // Add function to handle scene updates
  const handleSceneUpdate = (index, newPrompt) => {
    const updatedScenes = [...editableScenes];
    updatedScenes[index] = { ...updatedScenes[index], prompt: newPrompt };
    setEditableScenes(updatedScenes);
  };

  // Modify handleVideoUpload to not start processing immediately
  const handleVideoUpload = async (file) => {
    setUploadedVideo(file);
    toast.success('Video uploaded successfully! Click Generate to start processing.');
  };

  // Add new function to handle video processing
  const handleVideoProcessing = async () => {
    if (!uploadedVideo) {
      toast.error('Please upload a video first');
      return;
    }
    setIsProcessing(true);
    setIsGenerating(true);
    const formData = new FormData();
    formData.append('video', uploadedVideo);

    try {
      const response = await axios.post(`${serverbaseURL}process-video-to-shorts`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (response.data.success) {
        setGeneratedClips(response.data.clips);
        setShowClipsModal(true);
        setCurrentClipIndex(0);
        toast.success('Video processing completed successfully!');
      } else {
        toast.error('Failed to process video');
      }
    } catch (error) {
      console.error('Error processing video:', error);
      toast.error(error.response?.data?.message || 'Failed to process video');
    } finally {
      setIsProcessing(false);
      setShowGeneratingModal(false);
      setIsGenerating(false);
      setShowCustomForm(false);
    }
  };

  // Add these navigation functions for clips
  const handleNextClip = () => {
    setCurrentClipIndex((prev) => (prev + 1) % generatedClips.length);
  };

  const handlePrevClip = () => {
    setCurrentClipIndex((prev) =>
      prev === 0 ? generatedClips.length - 1 : prev - 1
    );
  };

  return (
    <div className="p-8">


      {/* Add seller banner when a seller is selected */}
      {selectedSeller && (
        <div className="flex items-center justify-center">
          <div className="max-w-6xl bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white p-4 rounded-lg mb-6 flex items-center justify-center">
            <div className="flex items-center gap-3">
              <img
                src={selectedSeller.avatar}
                alt={selectedSeller.name}
                className="w-10 h-10 rounded-full"
              />
              <span className="font-semibold">Viewing templates from {selectedSeller.name}</span>
            </div>
            <button
              onClick={() => setSelectedSeller(null)}
              className="text-white hover:text-gray-200"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>
      )}

      {/* Tab Navigation */}
      <div className="flex justify-center items-center pt-5">
        <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-8 items-center justify-center w-full max-w-xs sm:max-w-none">
          {/* Templates Button */}
          <motion.button
            onClick={() => handleTabChange('templates')}
            className={`px-8 py-3 rounded-full font-semibold text-lg shadow-md transition-all duration-300 ${activeTab === 'templates'
              ? "bg-gradient-to-r from-[#805af5] to-blue-600 text-white shadow-lg"
              : "bg-gray-200 text-gray-600 hover:bg-gradient-to-r hover:from-[#6eaffb] hover:to-[#4d8bfd] hover:text-white hover:shadow-lg"
              }`}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            Templates
          </motion.button>

          {/* My Generations Button - Only show if user is logged in */}
          {user && (
            <motion.button
              onClick={() => {
                setSelectedSeller(null);
                handleTabChange('generations');
              }}
              className={`px-8 py-3 rounded-full font-semibold text-lg shadow-md transition-all duration-300 ${activeTab === 'generations'
                ? "bg-gradient-to-r from-[#805af5] to-blue-600 text-white shadow-lg"
                : "bg-[#efefff] text-gray-600 hover:bg-gradient-to-r from-[#805af5] to-blue-600 hover:text-white hover:shadow-lg"
                }`}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              My Generations
            </motion.button>
          )}
        </div>
      </div>

      {/* Conditionally render MyGenerations component */}
      {showMyGenerations && <MyGenerations />}

      {/* Generation Progress Modal */}
      {isGenerating && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 z-[100]">
          <div className="bg-white rounded-lg p-6 shadow-xl w-80 text-center relative"> {/* Added relative positioning */}
            {/* Close button */}
            <button
              onClick={() => setIsGenerating(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition-colors"
            >
              <X className="h-5 w-5" />
            </button>

            <Loader2 className="animate-spin h-8 w-8 mx-auto mb-4 text-primary" />
            <h2 className="text-xl font-semibold mb-2">Generating Your Video</h2>
            <p className="text-gray-600">This may take a few minutes...</p>
            <p className="text-sm text-gray-500 mt-2">You can close this popup and check the progress in "My Generations"</p>
          </div>
        </div>
      )}

      {/* Generated Video Modal */}
      {showGeneratedVideo && generatedVideoUrl && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            {/* Background overlay */}
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
              onClick={() => {
                setShowGeneratedVideo(false);
                setGeneratedVideoUrl(null);
              }}
            ></div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            {/* Modal panel */}
            <div className="inline-block align-bottom bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
              <div className="relative">
                {/* Close button */}
                <button
                  onClick={() => {
                    setShowGeneratedVideo(false);
                    setGeneratedVideoUrl(null);
                  }}
                  className="absolute top-4 right-4 z-10 p-2 bg-white bg-opacity-25 rounded-full hover:bg-opacity-40 transition-all duration-200"
                >
                  <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>

                {/* Video player */}
                <div className="aspect-video w-full">
                  <video
                    className="w-full h-full object-contain"
                    controls
                    autoPlay
                  >
                    <source src={generatedVideoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>

              {/* Video information */}
              <div className="bg-white px-4 py-3">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Generated Video
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Your generated video is ready
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Error Modal */}
      {generationError && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 z-[100] ">
          <div className="bg-white rounded-lg p-4 shadow-xl w-80">
            <h2 className="text-xl font-bold text-red-600 mb-3">Generation Failed</h2>
            <p className="text-gray-800 mb-3">{generationError}</p>
            <button
              onClick={() => setGenerationError(null)}
              className="w-full bg-gray-300 text-gray-800 py-2 rounded-md font-semibold hover:bg-gray-400 transition duration-200"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Content based on active tab */}
      <div className="mt-8">
        {activeTab === 'templates' ? (
          <>
            <h1 className="text-4xl font-bold text-center m-10 font-custom text-black">
              {selectedSeller ? `${selectedSeller.name}'s Templates` : 'Explore Our Templates'}
            </h1>

            {isLoading ? (
              <div className="flex flex-col items-center justify-center min-h-[400px] space-y-4">
                <div className="relative w-20 h-20">
                  <div className="absolute inset-0 rounded-full border-4 border-gray-200"></div>
                  <div className="absolute inset-0 rounded-full border-4 border-t-violet-600 animate-spin"></div>
                </div>
                <div className="bg-gradient-to-r from-[#805af5] to-[#cd99ff] bg-clip-text text-transparent text-xl font-semibold">
                  Loading Templates...
                </div>
              </div>
            ) : (

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mx-auto max-w-6xl">
                {/* Template cards here */}
                {filteredTemplates.map((template) => (
                  <div
                    key={template.id}
                    onClick={() => handleTemplateClick(template)}
                    className="bg-gradient-to-br from-[#f8f8ff] via-[#e6e6fa] to-[#dcdcdc] rounded-xl overflow-hidden shadow-lg transform transition duration-300 hover:scale-105 flex flex-col"
                  >
                    <img
                      src={template.image}
                      alt={template.name}
                      className="w-full h-56 object-cover rounded-t"
                    />

                    <div className="p-6 flex-grow flex flex-col justify-between">
                      <div className="flex-grow">
                        <h2 className="text-xl font-custom font-semibold mb-2">{template.name}</h2>
                        <p className="mb-4 text-sm text-[#55595c]">{template.description}</p>
                      </div>

                      {/* Update the seller section to be clickable */}
                      <div
                        className="flex items-center space-x-2 cursor-pointer hover:opacity-80 transition-opacity"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleSellerClick(template.seller);
                        }}
                      >
                        <img
                          src={template.seller.avatar}
                          alt={template.seller.name}
                          className="w-8 h-8 rounded-full"
                        />
                        <span className="text-sm font-medium text-gray-700">
                          {template.seller.name}
                        </span>
                      </div>
                    </div>

                    {/* Video generation count and rating */}
                    <div className="flex justify-between items-center p-4 border-t border-gray-200">
                      <div className="flex items-center text-gray-700 space-x-2">
                        <Video className="w-5 h-5 text-violet-600" />
                        <span className="text-sm font-medium">{template.generatedCount || 0} Videos Generated</span>
                      </div>
                      <div className="flex items-center text-gray-700 space-x-1">
                        <Star className="w-5 h-5 text-yellow-500" />
                        <span className="text-sm font-medium">{template.rating}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

            )}
          </>
        ) : (
          <MyGenerations />
        )}
      </div>

      {/* Preview Modal */}
      {showPreview && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            {/* Background overlay */}
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
              onClick={() => setShowPreview(false)}
            ></div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            {/* Modal panel */}
            <div className="inline-block align-bottom bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
              <div className="relative">
                {/* Close button */}
                <button
                  onClick={() => setShowPreview(false)}
                  className="absolute top-4 right-4 z-10 p-2 bg-white bg-opacity-25 rounded-full hover:bg-opacity-40 transition-all duration-200"
                >
                  <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>

                {/* Video player */}
                <div className="aspect-video w-full">
                  <video
                    className="w-full h-full object-contain"
                    controls
                    autoPlay
                  >
                    <source src={selectedTemplate.previewVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>

              {/* Video information */}
              <div className="bg-white px-4 py-3">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {selectedTemplate.name} - Preview
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Sample video preview of the template
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Custom Form Modal for Graph Templates */}
      {showCustomForm && selectedTemplate && (selectedTemplate.id === 16 || selectedTemplate.id === 17) && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[100]">
          <div className="bg-white rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">
                {selectedTemplate.id === 16 ? 'Line Graph Generator' : 'Bar Graph Generator'}
              </h2>
              <button
                onClick={() => setShowCustomForm(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <DataInputForm onSubmit={handleCustomFormSubmit} />
          </div>
        </div>
      )}

      {/* Login Modal */}
      {showLoginPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]">
          <div
            className="bg-white rounded-lg shadow-xl max-w-md w-full mx-4 relative animate-fadeIn"
            onClick={(e) => e.stopPropagation()}
          >
            <LoginModal
              isModal={true}
              onSuccess={() => {
                setShowLoginPopup(false);
                setShowPreGenModal(true);
              }}
              onClose={() => setShowLoginPopup(false)}
            />
          </div>
        </div>
      )}

      {/* Pre-Generation Modal */}
      {showPreGenModal && selectedTemplate && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
          <div className="bg-white rounded-xl w-full max-w-lg mx-auto max-h-[90vh] flex flex-col overflow-hidden">
            {/* Header */}
            <div className="p-4 border-b">
              <div className="flex justify-between items-start">
                <h2 className="text-2xl font-bold text-gray-800">Generate Video</h2>
                <button
                  onClick={() => setShowPreGenModal(false)}
                  className="text-gray-500 hover:text-gray-700 transition-colors"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
            </div>

            {/* Scrollable Content */}
            <div className="flex-1 overflow-y-auto p-4 space-y-4">
              {/* Template Image */}
              <div className="relative h-32 w-full rounded-lg overflow-hidden">
                <img
                  src={selectedTemplate.image}
                  alt={selectedTemplate.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
              </div>

              {/* Template Details */}
              <div className="space-y-3">
                {/* Title Section */}
                <div className="bg-gray-50 p-3 rounded-lg">
                  <div className="flex items-center gap-3 text-gray-700">
                    <VideoIcon className="h-5 w-5 text-violet-600 shrink-0" />
                    <div className="min-w-0">
                      <p className="text-sm text-gray-500">Title</p>
                      <p className="font-medium truncate">{selectedTemplate.title}</p>
                    </div>
                  </div>
                </div>

                {/* Content Section */}
                <div className="bg-gray-50 p-3 rounded-lg">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="font-semibold text-gray-800">Content</h3>
                    <button
                      onClick={() => setIsEditing(!isEditing)}
                      className="text-sm text-violet-600 hover:text-violet-700 font-medium"
                    >
                      {isEditing ? 'Save Changes' : 'Edit Content'}
                    </button>
                  </div>

                  {loadingScenes ? (
                    <div className="text-center py-4">
                      <p className="text-gray-600">Loading...</p>
                    </div>
                  ) : templateScenes.type === 'scenes' && editableScenes.length > 0 ? (
                    <div className="space-y-2 max-h-48 overflow-y-auto">
                      {editableScenes.map((scene, index) => (
                        <div
                          key={index}
                          className="bg-white p-3 rounded border border-gray-200"
                        >
                          <div className="flex justify-between items-start mb-2">
                            <span className="font-medium text-sm text-gray-700">
                              Scene {index + 1}
                            </span>
                          </div>
                          {isEditing ? (
                            <textarea
                              value={scene.prompt}
                              onChange={(e) => handleSceneUpdate(index, e.target.value)}
                              className="w-full p-2 text-sm text-gray-600 border rounded focus:outline-none focus:ring-1 focus:ring-violet-500"
                              rows="2"
                            />
                          ) : (
                            <p className="text-sm text-gray-600">
                              {scene.prompt}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : templateScenes.type === 'text' && templateScenes.prompt ? (
                    <div className="bg-white p-3 rounded border border-gray-200">
                      <span className="font-medium text-sm text-gray-700">
                        ChatGPT Story Prompt
                      </span>
                      {isEditing ? (
                        <textarea
                          value={editableScenes[0]?.prompt || ''}
                          onChange={(e) => handleSceneUpdate(0, e.target.value)}
                          className="w-full mt-1 p-2 text-sm text-gray-600 border rounded focus:outline-none focus:ring-1 focus:ring-violet-500"
                          rows="3"
                        />
                      ) : (
                        <p className="text-sm text-gray-600 mt-1">
                          {templateScenes.prompt}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="text-center py-4">
                      <p className="text-gray-500">No content available for this template</p>
                    </div>
                  )}
                </div>

                {/* Credits Section */}
                <div className="bg-gray-50 p-3 rounded-lg">
                  <div className="flex items-center gap-3 text-gray-700">
                    <Wallet className="h-5 w-5 text-violet-600 shrink-0" />
                    <div className="min-w-0">
                      <p className="text-sm text-gray-500">Credits Required</p>
                      <div className="flex items-center gap-2">
                        <p className="font-medium">{selectedTemplate.credits}</p>
                        <span className="text-sm text-gray-500">
                          (You have: {userCredits})
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Footer with Actions here*/}
            <div className="p-4 border-t bg-gray-50">
              <button
                onClick={handleFinalGenerate}
                disabled={userCredits < selectedTemplate.credits}
                className={`w-full py-3 px-4 rounded-lg shadow-lg transition-all duration-300 flex items-center justify-center gap-2 text-white font-semibold
                  ${userCredits >= selectedTemplate.credits
                    ? 'bg-gradient-to-r from-[#805af5] to-[#cd99ff] hover:shadow-xl hover:opacity-90'
                    : 'bg-gray-400 cursor-not-allowed'}`}
              >
                {userCredits >= selectedTemplate.credits ? (
                  <span>Generate Video</span>
                ) : (
                  <span>Insufficient Credits</span>
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Video Upload Modal for Template 18 */}
      {showCustomForm && selectedTemplate?.id === 18 && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[100]">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Upload Video</h2>
              <button
                onClick={() => {
                  setShowCustomForm(false);
                  setUploadedVideo(null);
                }}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="space-y-4">
              <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
                <input
                  type="file"
                  accept="video/*"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      handleVideoUpload(file);
                    }
                  }}
                  className="hidden"
                  id="video-upload"
                />
                <label
                  htmlFor="video-upload"
                  className="cursor-pointer text-gray-600"
                >
                  <div>
                    <span className="block mb-2">
                      {uploadedVideo ? uploadedVideo.name : 'Drop your video here or click to upload'}
                    </span>
                    <span className="text-sm text-gray-500">MP4, MOV, or AVI</span>
                  </div>
                </label>
              </div>

              {/* Add Generate button */}
              <button
                onClick={handleVideoProcessing}
                disabled={!uploadedVideo || isProcessing}
                className={`w-full py-3 px-4 rounded-lg shadow-lg transition-all duration-300 flex items-center justify-center gap-2 text-white font-semibold
                  ${!uploadedVideo || isProcessing
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-gradient-to-r from-[#805af5] to-[#cd99ff] hover:shadow-xl hover:opacity-90'
                  }`}
              >
                {isProcessing ? (
                  <>
                    <Loader2 className="animate-spin h-5 w-5" />
                    <span>Processing...</span>
                  </>
                ) : (
                  <span>Generate Clips</span>
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Add Clips Viewer Modal */}
      {showClipsModal && generatedClips.length > 0 && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="relative bg-white rounded-lg shadow-xl w-full max-w-md mx-4">
            <div className="bg-black relative">
              {/* Close button */}
              <button
                onClick={() => setShowClipsModal(false)}
                className="absolute top-2 right-2 z-10 p-1.5 bg-white bg-opacity-25 rounded-full hover:bg-opacity-40"
              >
                <X className="h-5 w-5 text-white" />
              </button>

              {/* Video player */}
              <div className="aspect-[9/16] w-full h-[90vh]">
                <video
                  key={generatedClips[currentClipIndex]}
                  className="w-full h-full object-contain"
                  controls
                  autoPlay
                >
                  <source src={generatedClips[currentClipIndex]} type="video/mp4" />
                </video>

                {/* Navigation buttons */}
                <div className="absolute inset-x-0 top-1/2 flex justify-between items-center px-2 -translate-y-1/2">
                  <button
                    onClick={handlePrevClip}
                    className="p-1.5 bg-white bg-opacity-25 rounded-full hover:bg-opacity-40"
                  >
                    <ChevronLeft className="h-5 w-5 text-white" />
                  </button>
                  <button
                    onClick={handleNextClip}
                    className="p-1.5 bg-white bg-opacity-25 rounded-full hover:bg-opacity-40"
                  >
                    <ChevronRight className="h-5 w-5 text-white" />
                  </button>
                </div>
              </div>
            </div>

            {/* Clip information */}
            <div className="bg-white px-3 py-2 rounded-b-lg">
              <div className="flex justify-between items-center">
                <h3 className="text-sm font-medium text-gray-900">
                  Clip {currentClipIndex + 1} of {generatedClips.length}
                </h3>
                <a
                  href={generatedClips[currentClipIndex]}
                  download={`clip-${currentClipIndex + 1}.mp4`}
                  className="text-sm text-blue-600 hover:text-blue-800 font-medium"
                >
                  Download
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Templates;
