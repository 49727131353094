import axios from "axios";
import GradientHeading from "../components/GradientHeading";
import { useContext, useState, useEffect, useRef } from "react";
import { serverbaseURL } from "../constant/index";
import { AuthContext } from "../provider/AuthProvider";
import { motion } from 'framer-motion';
import { FaCheck, FaTimes, FaCrown, FaRocket, FaFire, FaRegGem } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { ArrowRight, Check, ChevronDown, ChevronUp } from 'lucide-react';



let starter = ' Pay As You Go Starter';
let daily = ' Pay As You Go Daily';
let hardcore = 'Pay As You Go hardcore';

let starters = ' Subscription Starter';
let dailys = ' Subscription Daily';
let hardcores = 'Subscription Hardcore';
// Credit options and their corresponding prices
const creditOptions = [
  { credits: 100, payAsYouGo: 1742, planName: starter, plan_id: "plan_Q0MZymAblNEr5H" },
  { credits: 200, payAsYouGo: 2613, planName: daily, plan_id: "plan_Q0MbrwyVa76nJr" },
  { credits: 500, payAsYouGo: 6097, planName: hardcore, plan_id: "plan_Q0MdNI8BrwG8L6" },
];


// Credit options and their corresponding prices
const subscriptionOptions = [
  { credits: 200, subscription: 2000, planName: starters, plan_id: "plan_Q0QmkPyglAu8k6" },
  { credits: 600, subscription: 5000, planName: dailys, plan_id: "plan_Q0QlO2RPPlYY0R" },
  { credits: 1000, subscription: 8000, planName: hardcores, plan_id: "plan_Q0Qk6olOWfaolC" },
];

const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const Billing = () => {
  const { user } = useContext(AuthContext);
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate();
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [subscriptionMessage, setSubscriptionMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [selectedCredits, setSelectedCredits] = useState(creditOptions[0]);
  const [showPayAsYouGoInfo, setShowPayAsYouGoInfo] = useState(false);
  const [showSubscriptionInfo, setShowSubscriptionInfo] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(subscriptionOptions[0]);
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);


  const handleCreditChange = (e, type) => {
    const credits = parseInt(e.target.value);
    const option = creditOptions.find(opt => opt.credits === credits);
    if (option) {
      setSelectedCredits(option);
    }
  };

  const handleSubscriptionChange = (e) => {
    const credits = parseInt(e.target.value);
    const option = subscriptionOptions.find(opt => opt.credits === credits);
    if (option) setSelectedSubscription(option);
  };

  const CreditInfoDropdown = ({ isOpen, onToggle }) => (
    <div className="w-full relative">
      <button
        className="text-sm text-blue-600 hover:text-blue-800 mt-2 w-full text-left flex items-center justify-between"
        onClick={onToggle}
      >
        <span>What can I do with {selectedCredits.credits.toLocaleString()} credits?</span>
        {isOpen ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
      </button>
      {isOpen && (
        <div className="absolute top-full left-0 mt-2 p-4 bg-white rounded-md shadow-lg w-full z-10">
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <Check className="h-4 w-4 text-green-400" />
              <p className="text-[#55595c] text-sm">Schedule Post to Socials</p>
            </div>
            <div className="flex items-center gap-2">
              <Check className="h-4 w-4 text-green-400" />
              <p className="text-[#55595c] text-sm">Edit & Preview Videos</p>
            </div>
            <div className="flex items-center gap-2">
              <Check className="h-4 w-4 text-green-400" />
              <p className="text-[#55595c] text-sm">Auto Post To Channel</p>
            </div>
            <div className="flex items-center gap-2">
              <Check className="h-4 w-4 text-green-400" />
              <p className="text-[#55595c] text-sm">HD Video Resolution</p>
            </div>
            <div className="flex items-center gap-2">
              <Check className="h-4 w-4 text-green-400" />
              <p className="text-[#55595c] text-sm">Background Music</p>
            </div>
            <div className="flex items-center gap-2">
              <Check className="h-4 w-4 text-green-400" />
              <p className="text-[#55595c] text-sm">No Watermark</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );


  // New function: Cancel Subscription
  const handleCancelSubscription = async () => {
    if (!user || !userDetails?.subscriptionId) {
      alert("No active subscription to cancel.");
      return;
    }

    if (!window.confirm("Are you sure you want to cancel your subscription?")) {
      return;
    }

    setCancelLoading(true);
    try {
      const { data } = await axios.post(`${serverbaseURL}api/cancel-subscription`, {
        subscriptionId: userDetails.subscriptionId,
        email: user.email,
      });
      console.log("Cancellation Response:", data);
      setSubscriptionStatus('success');
      setSubscriptionMessage("Subscription cancelled successfully.");

      // Refresh user details after cancellation
      const userResponse = await axios.post(`${serverbaseURL}get_user_details`, {
        uid: user.uid
      });
      setUserDetails(userResponse.data);
    } catch (err) {
      console.error("Cancellation Error:", err.response ? err.response.data : err);
      setSubscriptionStatus('error');
      setSubscriptionMessage(err.response?.data?.error || 'Cancellation failed. Please try again.');
    } finally {
      setCancelLoading(false);
    }
  };


  // Dynamically load Razorpay script
  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      script.onload = () => setScriptLoaded(true);
      script.onerror = () => setError('Failed to load Razorpay SDK');
      document.body.appendChild(script);
    };
    if (!window.Razorpay) {
      loadScript();
    } else {
      setScriptLoaded(true);
    }
  }, []);


  // Updated handleSubscription function
  const handleSubscriptions = async () => {
    if (!user) {
      navigate('/login');
      return;
    }

    setLoadingSubscription(true);
    setError('');

    try {
      const { data } = await axios.post(`${serverbaseURL}api/create-subscription`, {
        plan_id: selectedSubscription.plan_id,
        total_count: 5,
        email: user.email,
      });

      const options = {
        key: "rzp_live_k9cKl94apbk7aE",
        subscription_id: data.id,
        name: 'AutoMovieCreator',
        description: `Subscription for ${selectedSubscription.credits} credits`,
        image: "https://papayacoders.com/demo.png",
        handler: async (response) => {
          console.log('re', response);
          try {
            await axios.post(`${serverbaseURL}api/verify-payment`, {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_subscription_id: response.razorpay_subscription_id,
              razorpay_signature: response.razorpay_signature,
              credits: selectedSubscription.credits, // Send selected credits
              plan_id: selectedSubscription.plan_id,  // Send selected plan ID
              planName: selectedSubscription.planName,
              money: selectedSubscription.subscription,
              email: user.email,

            });

            setSubscriptionStatus('success');
            setSubscriptionMessage('Subscription activated successfully! Your account will be updated shortly.');

            // Refresh user details
            const userResponse = await axios.post(`${serverbaseURL}get_user_details`, {
              uid: user.uid
            });
            setUserDetails(userResponse.data);
          } catch (err) {
            setSubscriptionStatus('error');
            setSubscriptionMessage(err.response?.data?.error || 'Payment verification failed. Please contact support.');
          }
        },
        prefill: {
          name: user.displayName || 'Customer',
          email: user.email || 'customer@example.com',
          contact: ''
        },
        theme: { color: '#3399cc' },
      };

      const rzp = new window.Razorpay(options);
      rzp.on('payment.failed', (response) => {
        setSubscriptionStatus('error');
        setSubscriptionMessage(response.error.description || 'Payment failed. Please try again.');
      });
      rzp.open();

    } catch (err) {
      setSubscriptionStatus('error');
      setSubscriptionMessage(err.response?.data?.error || 'Payment initialization failed. Please try again.');
    } finally {
      setLoadingSubscription(false);
    }
  };


  // Updated handleSubscription function
  const handleSubscription = async () => {
    if (!user) {
      navigate('/login');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const { data } = await axios.post(`${serverbaseURL}api/create-subscription`, {
        plan_id: selectedCredits.plan_id,
        total_count: 24
      });

      const options = {
        key: "rzp_live_k9cKl94apbk7aE",
        subscription_id: data.id,
        name: 'AutoMovieCreator',
        description: `Subscription for ${selectedCredits.credits} credits`,
        image: "https://papayacoders.com/demo.png",
        handler: async (response) => {
          try {
            await axios.post(`${serverbaseURL}api/verify-payment`, {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_subscription_id: response.razorpay_subscription_id,
              razorpay_signature: response.razorpay_signature,
              credits: selectedCredits.credits, // Send selected credits
              plan_id: selectedCredits.plan_id,  // Send selected plan ID
              planName: selectedCredits.planName,
              money: selectedCredits.payAsYouGo,
              email: user.email,
            });

            setSubscriptionStatus('success');
            setSubscriptionMessage('Subscription activated successfully! Your account will be updated shortly.');

            // Refresh user details
            const userResponse = await axios.post(`${serverbaseURL}get_user_details`, {
              uid: user.uid
            });
            setUserDetails(userResponse.data);
          } catch (err) {
            setSubscriptionStatus('error');
            setSubscriptionMessage(err.response?.data?.error || 'Payment verification failed. Please contact support.');
          }
        },
        prefill: {
          name: user.displayName,
          email: user.email,
          // contact: ''
        },
        theme: { color: '#3399cc' },
      };

      const rzp = new window.Razorpay(options);
      rzp.on('payment.failed', (response) => {
        setSubscriptionStatus('error');
        setSubscriptionMessage(response.error.description || 'Payment failed. Please try again.');
      });
      rzp.open();

    } catch (err) {
      setSubscriptionStatus('error');
      setSubscriptionMessage(err.response?.data?.error || 'Payment initialization failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  const videoLimits = {
    'free': 1,
    'starter': 3,
    'daily': 7,
    'hardcore': 14
  };


  // Fetch user details when component mounts
  // useEffect(() => {
  //   const fetchUserDetails = async () => {
  //     try {
  //       const response = await axios.post(`${serverbaseURL}get_user_details`, {
  //         uid: user.uid
  //       });
  //       setUserDetails(response.data);
  //     } catch (error) {
  //       console.error("Error fetching user details:", error);
  //     }
  //   };
  //   if (user?.uid) {
  //     fetchUserDetails();
  //   }
  // }, [user]);

  // Update the useEffect that fetches user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.post(`${serverbaseURL}get_user_details`, {
          uid: user.uid
        });
        setUserDetails(response.data);

        // Set selected plan based on user's current plan
        if (response.data.plan) {
          const payAsYouGoPlan = creditOptions.find(
            opt => opt.planName.trim() === response.data.plan.trim()
          );
          if (payAsYouGoPlan) {
            setSelectedCredits(payAsYouGoPlan);
          } else {
            const subscriptionPlan = subscriptionOptions.find(
              opt => opt.planName.trim() === response.data.plan.trim()
            );
            if (subscriptionPlan) {
              setSelectedSubscription(subscriptionPlan);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    if (user?.uid) {
      fetchUserDetails();
    }
  }, [user]);
  // Add this modal component
  const SubscriptionModal = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
        <div className="flex flex-col items-center">
          {subscriptionStatus === 'success' ? (
            <FaCheckCircle className="text-4xl text-green-500 mb-4" />
          ) : (
            <FaTimesCircle className="text-4xl text-red-500 mb-4" />
          )}
          <h2 className="text-2xl font-bold mb-4">
            {subscriptionStatus === 'success' ? 'Success!' : 'Error!'}
          </h2>
          <p className="text-gray-700 mb-6 text-center">{subscriptionMessage}</p>
          <button
            className={`px-4 py-2 rounded-lg ${subscriptionStatus === 'captured'
              ? 'bg-green-500 hover:bg-green-600'
              : 'bg-red-500 hover:bg-red-600'
              } text-white transition-colors`}
            onClick={() => setSubscriptionStatus(null)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );



  const renderBuyButton = (planName, price) => {
    const isCurrentPlan = userDetails?.plan?.toLowerCase() === planName.toLowerCase();

    return (
      <motion.button
        className={`bg-gradient-to-r ${isCurrentPlan
          ? 'from-gray-400 to-gray-500 cursor-not-allowed'
          : 'from-[#805af5] to-[#cd99ff] hover:from-[#6a3ec2] hover:to-[#a68cfc]'
          } text-white font-medium sm:text-lg py-2 sm:py-2 px-3 sm:px-4 rounded-lg w-full leading-[40px] tracking-[0.5px] mt-14 border-0 text-center inline-block`}
        whileHover={{ scale: isCurrentPlan ? 1 : 1.05 }}
        // onClick={(e) => !isCurrentPlan && handlePayment(e, price)}
        disabled={isCurrentPlan}
      >
        {isCurrentPlan ? 'CURRENT PLAN' : 'Free Plan'}
      </motion.button>
    );
  };



  return (

    <div className=" min-h-screen">
      <div className="max-w-6xl mx-auto">
        <div className="pt-20 px-5 ml-5 mb-2 pb-5">
          <GradientHeading text="CURRENT PLAN" />
        </div>
        <div className="px-5">
          <div className="px-5">
            <motion.div className="bg-[#805af5] max-w-2xl px-8 py-10 md:px-16 shadow-xl rounded-lg"
              initial={{ opacity: 0, x: -1000, scale: 0.95 }} // Starts off-screen to the left with slight scale down
              animate={{ opacity: 1, x: 0, scale: 1 }} // Animates to full opacity and position with scale up
              transition={{
                duration: 0.4, // Duration for smooth transition
                ease: 'easeInOut', // Smooth easing function for smooth and natural animation
                delay: 0.1, // A slight delay for a more fluid effect
              }}
            >
              <p className="text-lg text-white">
                <span className="font-bold">Current Plan:</span> {userDetails?.plan ? userDetails.plan : 'Loading...'}
              </p>
              <p className="text-lg text-white">
                <span className="font-bold">Credits Remaining:</span> {userDetails?.credits || 0}
              </p>
              {/* <p className="text-lg text-white">
                <span className="font-bold">Last Reset:</span> {userDetails?.lastVideoReset
                  ? new Date(userDetails.lastVideoReset).toLocaleDateString()
                  : 'N/A'}
              </p> */}
              <p className="text-lg text-white">
                <span className="font-bold">Status:</span> {userDetails?.subscriptionStatus || 'Loading...'}
              </p>
            </motion.div>
          </div>

        </div>
        <div className="pt-14 px-5 mt-2 ml-6 ">
          <GradientHeading text="CHANGE PLAN" />
        </div>

        {/* card container */}
        <div className=" p-5 rounded-lg grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 ">
          {/* card 1 */}
          <div className=" relative inline-block p-4 text-white">
            <div className="relative z-10 border-2 border-[#805af5] rounded-xl bg-[#f8f8ff] p-8 h-full shadow-lg hover:shadow-2xl hover:border-[#0d6efd]">
              <div className="flex flex-col items-center">
                <p className="text-black text-[36px] leading-tight block text-center bg-opacity-100 pb-1 font-serif">Free</p>
                <div className="flex items-baseline justify-center mt-2">
                  <span className="text-5xl font-extrabold text-black">₹{0}</span>
                  {/* <span className="ml-1  text-gray-500">/10 credits</span> */}
                </div>
                <p className="mt-1 text-gray-500 pt-1 text-center">{10}  credits one time free</p>
                {/* Features Heading */}
                <h2 className=" text-blue-600 hover:text-blue-800 text-left pb-2 opacity-100  text-[17px]  font-[500] font-serif mb-2 mt-4">Features</h2>
                {/* Features List */}
                <div className="mb-1">
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                      10 Free Credit
                    </p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                      Schedule Post to Socials
                    </p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                      Edit & Preview Videos
                    </p>
                  </div>
                  {/* Disabled Features */}
                  <div className="flex justify-start items-center gap-2">
                    <FaTimes className="h-4 w-4 text-red-400" />
                    <p className="line-through text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">Auto Post To Channel</p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaTimes className="h-4 w-4 text-red-400" />
                    <p className="line-through text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">HD Video Resolution</p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaTimes className="h-4 w-4 text-red-400" />
                    <p className="line-through text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">Background Music</p>
                  </div>
                  {/* <div className="flex justify-start items-center gap-2">
                    <FaTimes className="h-4 w-4 text-red-400" />
                    <p className="line-through text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">No Watermark</p>
                  </div> */}
                  <div className="flex justify-start items-center gap-2">
                    <FaTimes className="h-4 w-4 text-red-400" />
                    <p className="line-through text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">Download Videos</p>
                  </div>
                </div>
                {renderBuyButton('free', 0)}
              </div>
            </div>
          </div>



          {/* Pay As You Go */}
          <div className=" relative inline-block p-4 text-white">
            <div className="relative z-10 border-2 border-[#805af5] rounded-xl bg-[#f8f8ff] p-8 h-full shadow-lg hover:shadow-2xl hover:border-[#0d6efd]">
              <div className="flex flex-col">
                <p className="text-black text-[32px] leading-tight block text-center bg-opacity-100 pb-1 font-serif">PAY AS YOU GO</p>
                {/* <div className="flex items-baseline justify-center mt-3">
                  <span className="text-5xl font-extrabold text-black">${30}</span>
                  <span className="ml-1  text-gray-500">/300 credits</span>
                </div> */}
                <p className="mt-2  pt-1 text-center text-5xl font-extrabold text-black">₹{selectedCredits.payAsYouGo} </p>
                <p className="pt-1 text-center text-lg  text-gray-500 ">One time</p>

                <div className="mt-6">
                  <select
                    className="w-full p-2 border rounded bg-white text-gray-700"
                    value={selectedCredits.credits}
                    onChange={(e) => handleCreditChange(e, 'payAsYouGo')}
                  >
                    {creditOptions.map(option => (
                      <option key={option.credits} value={option.credits}>
                        {option.credits.toLocaleString()} Credits
                      </option>
                    ))}
                  </select>
                  <CreditInfoDropdown
                    isOpen={showPayAsYouGoInfo}
                    onToggle={() => setShowPayAsYouGoInfo(!showPayAsYouGoInfo)}
                  />
                </div>

                <div className="mt-10">
                  <div className="flex items-start mb-20 mt-4">
                    <Check className="h-7 w-7 text-green-600 mt-1" />
                    <p className="text-[#55595c] text-[15px] leading-[1.67] font-[500] ml-2">
                      Credits available for use anytime within one year of purchase
                    </p>
                  </div>
                </div>

                <button
                  onClick={handleSubscription}
                  disabled={
                    !scriptLoaded ||
                    loading ||
                    (userDetails?.subscriptionStatus === 'active' &&
                      selectedCredits.planName === userDetails.plan)
                  }
                  className={`bg-gradient-to-r ${loading || !scriptLoaded ||
                    (userDetails?.subscriptionStatus === 'active' &&
                      selectedCredits.planName === userDetails.plan)
                    ? 'from-gray-400 to-gray-500 cursor-not-allowed'
                    : 'from-[#805af5] to-[#cd99ff] hover:from-[#6a3ec2] hover:to-[#a68cfc] '
                    } text-white font-medium sm:text-lg py-2 sm:py-2 px-3 sm:px-4 rounded-lg w-full leading-[40px] tracking-[0.5px] mt-6 border-0 text-center inline-block hover:scale-105`}
                >
                  {userDetails?.subscriptionStatus === 'active' &&
                    selectedCredits.planName === userDetails.plan
                    ? 'Current Plan'
                    : (loading ? 'Processing...' : 'Subscribe Now')
                  }
                </button>
                {userDetails?.subscriptionStatus === 'active' &&
                  selectedCredits.planName === userDetails.plan && (
                    <button
                      onClick={handleCancelSubscription}
                      disabled={cancelLoading}
                      className={`mt-4 ${cancelLoading
                        ? 'bg-gray-400 cursor-not-allowed'
                        : 'bg-red-500 hover:bg-red-600'
                        } text-white font-medium sm:text-lg py-2 px-3 rounded-lg w-full transition-colors flex items-center justify-center gap-2`}
                    >
                      {cancelLoading ? (
                        <>
                          <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          Processing...
                        </>
                      ) : (
                        'Cancel Subscription'
                      )}
                    </button>
                  )}

              </div>
            </div>
          </div>

          {/* Subscription */}
          <div className="h-full relative inline-block p-4 text-white">
            <div className="relative z-10 border-2 border-[#805af5] rounded-xl bg-[#f8f8ff] p-8 h-full shadow-lg hover:shadow-2xl hover:border-[#0d6efd]">
              <div className="flex flex-col">
                <p className="text-black text-[32px] leading-tight block text-center bg-opacity-100 pb-1 font-serif">SUBSCRIPTION</p>
                <div className="flex items-baseline justify-center mt-2">
                  {/* <span className="text-5xl font-extrabold text-black">₹{selectedSubscription.price}</span> */}
                  {/* <span className="ml-1 text-gray-500">/200 credits</span> */}
                </div>
                <p className="  pt-1 text-center text-5xl font-extrabold text-black">₹{selectedSubscription.subscription} </p>
                <p className="pt-1 text-center text-lg  text-gray-500 ">monthly</p>


                <div className="mt-6">
                  <select
                    className="w-full p-2 border rounded bg-white text-gray-700"
                    value={selectedSubscription.credits}
                    onChange={handleSubscriptionChange}
                  >
                    {subscriptionOptions.map(option => (
                      <option key={option.credits} value={option.credits}>
                        {option.credits.toLocaleString()} Credits
                      </option>
                    ))}
                  </select>
                  <CreditInfoDropdown
                    isOpen={showSubscriptionInfo}
                    onToggle={() => setShowSubscriptionInfo(!showSubscriptionInfo)}
                  />
                </div>

                <div className="mt-3">
                  <div className="flex items-start mt-2">
                    <Check className="h-5 w-5 text-green-600 mt-1" />
                    <p className="text-[#55595c] text-[15px] leading-[1.67] font-[500] ml-2">
                      Downgrade, upgrade or cancel any time
                    </p>
                  </div>
                  <div className="flex items-start mt-2">
                    <Check className="h-6 w-6 text-green-600 mt-1" />
                    <p className="text-[#55595c] text-[15px] leading-[1.67] font-[500] ml-2">
                      Roll over up to 3x your monthly credit allowance
                    </p>
                  </div>
                  <div className="flex items-start mt-2">
                    <Check className="h-5 w-5 text-green-600 mt-1" />
                    <p className="text-[#55595c] text-[15px] leading-[1.67] font-[500] ml-2">
                      Customisable white-label video editor
                    </p>
                  </div>
                </div>
                <button
                  onClick={handleSubscriptions}
                  disabled={
                    !scriptLoaded ||
                    loadingSubscription ||
                    (userDetails?.subscriptionStatus === 'active' &&
                      selectedSubscription.planName === userDetails.plan)
                  }
                  className={`bg-gradient-to-r  ${loadingSubscription || !scriptLoaded ||
                    (userDetails?.subscriptionStatus === 'active' &&
                      selectedSubscription.planName === userDetails.plan)
                    ? 'from-gray-400 to-gray-500 cursor-not-allowed'
                    : 'from-[#805af5] to-[#cd99ff] hover:from-[#6a3ec2] hover:to-[#a68cfc] '
                    } text-white font-medium sm:text-lg py-2 sm:py-2 px-3 sm:px-4 rounded-lg w-full leading-[40px] tracking-[0.5px] mt-6 border-0 text-center inline-block hover:scale-105`}
                >
                  {userDetails?.subscriptionStatus === 'active' &&
                    selectedSubscription.planName === userDetails.plan
                    ? 'Current Plan'
                    : (loadingSubscription ? 'Processing...' : 'Subscribe Now')
                  }
                </button>
                {userDetails?.subscriptionStatus === 'pending_cancellation' && (
                  <div className="mt-4 p-3 bg-yellow-100 rounded-lg">
                    <p className="text-yellow-800">
                      Subscription ending on {new Date(userDetails.subscriptionEndDate).toLocaleDateString()}
                    </p>
                  </div>
                )}

                {userDetails?.subscriptionStatus === 'active' &&
                  selectedSubscription.planName === userDetails.plan && (
                    <button
                      onClick={handleCancelSubscription}
                      disabled={cancelLoading}
                      className={`mt-4 ${cancelLoading
                        ? 'bg-gray-400 cursor-not-allowed'
                        : 'bg-red-500 hover:bg-red-600'
                        } text-white font-medium sm:text-lg py-2 px-3 rounded-lg w-full transition-colors flex items-center justify-center gap-2`}
                    >
                      {cancelLoading ? (
                        <>
                          <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          Processing...
                        </>
                      ) : (
                        'Cancel Subscription'
                      )}
                    </button>
                  )}
              </div>
            </div>
          </div>




        </div>
        {/* Modal */}
        {subscriptionStatus && <SubscriptionModal />}      </div>

    </div>
  );
};
export default Billing;

