import React from "react";

const ShippingPolicy = () => {
    return (
        <div className="max-w-5xl mx-auto mt-10 p-6 ">
            <h1 className="text-4xl font-bold text-gray-900 mb-12 item-center">Shipping Policy</h1>

            <div className="space-y-4 text-gray-900">
                <section>
                    <h2 className="font-bold text-gray-900 text-xl my-3 ">1. Digital-Only Services</h2>
                    <p className="text-gray-900 text-lg ">AutomovieCreator is a digital service provider, and all our products and services are delivered electronically. We do not offer physical shipping for any of our services.</p>
                </section>

                <section>
                    <h2 className="font-bold text-gray-900 text-xl my-3 ">2. Delivery Method</h2>
                    <p className="text-gray-900 text-lg ">Upon successful purchase, customers will receive access to their digital content or service via email, a customer dashboard, or a downloadable link on our website.</p>
                    <p className="text-gray-900 text-lg ">Any AI-generated videos, media files, or digital assets will be processed and made available through our platform.</p>
                </section>

                <section>
                    <h2 className="font-bold text-gray-900 text-xl my-3 ">3. Delivery Timeframe</h2>
                    <p className="text-gray-900 text-lg ">Standard delivery time for our digital services varies based on the complexity of the request.</p>
                    <ul className="list-disc pl-6">
                        <li className="text-gray-900 text-lg ">Most automated video creations are delivered instantly or within a few minutes.</li>
                        <li className="text-gray-900 text-lg ">Custom or premium services may take up to <span className="font-semibold">24-48 hours</span>, depending on the  nature od the request.</li>
                    </ul>
                </section>

                <section>
                    <h2 className="font-bold text-gray-900 text-xl my-3  ">4. Order Confirmation & Access</h2>
                    <p className="text-gray-900 text-lg ">After purchase, customers will receive an email confirmation with details on how to access their digital content.</p>
                    <p className="text-gray-900 text-lg ">If you do not receive the email within the expected timeframe, please check your spam folder or contact our support team at  <a href="mailto:info@automoviecreator.com" className="text-blue-600 underline">info@automoviecreator.com</a>.</p>
                </section>

                <section>
                    <h2 className="font-bold text-gray-900 text-lg my-3  ">5. Refund & Cancellation Policy</h2>
                    <p className="text-gray-900 text-lg ">Since our services are digital and delivered instantly, we generally do not offer refunds once the order has been processed.</p>
                    <p className="text-gray-900 text-lg ">However, if you experience any issues or are not satisfied with the service, please contact our support team, and we will do our best to resolve the matter.</p>
                </section>

                <section>
                    <h2 className="font-bold text-gray-900 text-xl my-3 ">6. Contact Us</h2>
                    <p className="text-gray-900 text-lg mb-4 ">If you have any questions regarding the delivery of your digital order, please reach out to our support team at  <a href="mailto:info@automoviecreator.com" className="text-blue-600 underline">info@automoviecreator.com</a>.</p>
                </section>
            </div>
        </div>
    );
};

export default ShippingPolicy;
