export const nodeTypes = [
  {
    id: 'text-generation',
    name: 'Text Generation',
    description: 'Generate text using various language models',
    icon: '💭',
    inputs: 1,
    outputs: 1,
    demo: 'https://your-demo-url.com/text-generation',
    properties: [
      {
        name: 'model',
        type: 'select',
        options: ['ChatGPT 3.5', 'ChatGPT 4', 'Deepseek R1'],
        default: 'ChatGPT 4'
      },
      { name: 'prompt', type: 'text', required: true },
      { name: 'temperature', type: 'range', min: 0, max: 1, step: 0.1, default: 0.7 },
      // { name: 'maxTokens', type: 'number', min: 1, max: 4000, default: 2000 },
      // { name: 'topP', type: 'range', min: 0, max: 1, step: 0.1, default: 0.9 },
      // { name: 'systemMessage', type: 'text' }
    ],
  },
  {
    id: 'image-generation',
    name: 'Text to Image Generation',
    description: 'Generate images from text descriptions',
    icon: '🎨',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'model',
        type: 'select',
        options: ['Leonardo Phoenix', 'Cinematic Kino', 'Flux LORA'],
        default: 'Leonardo Phoenix'
      },
      { name: 'prompt', type: 'text', required: true },
      {
        name: 'ratio',
        type: 'select',
        options: ['1:1', '16:9', '4:3', '3:4', '9:16'],
        default: '1:1'
      },
      {
        name: 'stylePreset',
        type: 'select',
        options: ['Cinematic', 'Digital Art', 'Anime', 'Photography', 'Abstract'],
        default: 'Digital Art',
        showWhen: (props) => props.model === 'Leonardo Phoenix'
      },
      {
        name: 'loraLink',
        type: 'text',
        required: true,
        showWhen: (props) => props.model === 'Flux LORA',
        description: 'Enter the LORA training set link'
      }
    ],
  },
  {
    id: 'video-generation',
    name: 'Text/Image to Video',
    description: 'Generate videos from text or images',
    icon: '🎥',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'mode',
        type: 'select',
        options: ['Text to Video', 'Image to Video'],
        default: 'Text to Video'
      },
      {
        name: 'textPrompts',
        type: 'text',
        required: true,
        showWhen: (props) => props.mode === 'Text to Video',
        description: 'Enter text prompts for video generation'
      },
      {
        name: 'imagePrompts',
        type: 'text',
        required: true,
        showWhen: (props) => props.mode === 'Image to Video',
        description: 'Enter text prompts for each image'
      },
      {
        name: 'imageUrls',
        type: 'text',
        required: true,
        showWhen: (props) => props.mode === 'Image to Video',
        description: 'Enter image URLs (one per line)'
      },
      {
        name: 'ratio',
        type: 'select',
        options: ['1:1', '16:9', '4:3', '9:16'],
        default: '16:9'
      },
      {
        name: 'length',
        type: 'number',
        min: 1,
        max: 10,
        default: 5
      }
    ],
  },
  {
    id: 'audio-generation',
    name: 'Text to Audio',
    description: 'Generate audio from text using Eleven Labs',
    icon: '🔊',
    inputs: 1,
    outputs: 1,
    properties: [
      { name: 'text', type: 'text', required: true },
      { name: 'voice', type: 'string' },
      // { name: 'stability', type: 'range', min: 0, max: 1, step: 0.1, default: 0.5 },
      // { name: 'similarityBoost', type: 'range', min: 0, max: 1, step: 0.1, default: 0.5 },
      // { name: 'style', type: 'range', min: 0, max: 1, step: 0.1, default: 0.5 },
      // { name: 'speakerBoost', type: 'boolean', default: false },
      {
        name: 'model',
        type: 'select',
        options: ['Eleven labs', 'coming soon'],
        default: 'Eleven labs'
      },
      // {
      //   name: 'outputFormat',
      //   type: 'select',
      //   options: ['MP3', 'WAV'],
      //   default: 'MP3'
      // }
    ],
  },
  {
    id: 'video-composition',
    name: 'Video Composition',
    description: 'Compose video with image, audio and optional captions',
    icon: '🎬',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'imageSource',
        type: 'text',
        required: true,
        description: 'URL or path to the image'
      },
      {
        name: 'audioSource',
        type: 'text',
        required: true,
        description: 'URL or path to the audio file'
      },
      {
        name: 'enableCaptions',
        type: 'boolean',
        default: false,
        description: 'Enable or disable captions'
      }
    ],
  },
  {
    id: 'prompt-input',
    name: 'Prompt Input',
    description: 'Create multiple scene prompts',
    icon: '📝',
    inputs: 0,
    outputs: 1,
    properties: [
      {
        name: 'scenes',
        type: 'scene-list',
        default: [{ id: 1, prompt: '' }]
      }
    ],
  },
  {
    id: 'text-to-video',
    name: 'Text to Video Generation',
    description: 'Generate videos directly from text prompts',
    icon: '🎬',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'prompts',
        type: 'text',
        required: true,
        description: 'Enter text prompts (one per line) to generate videos'
      },
      {
        name: 'height',
        type: 'number',
        default: 720,
        min: 360,
        max: 1080
      },
      {
        name: 'width',
        type: 'number',
        default: 1280,
        min: 640,
        max: 1920
      },
      {
        name: 'topic',
        type: 'text',
        default: 'flow',
        description: 'Topic/category for the video'
      }
    ],
  },
  {
    id: 'image-prompt-video',
    name: 'Image and Video Prompts to Video',
    description: 'Enter image and video prompts and get stiched up video.',
    icon: '🎥',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'imagePrompts',
        type: 'text',
        required: true,
        description: 'Enter image prompts (one per line)'
      },
      {
        name: 'videoPrompts',
        type: 'text',
        required: true,
        description: 'Enter video prompts (one per line, must match number of image prompts)'
      },
      {
        name: 'height',
        type: 'number',
        default: 720,
        min: 360,
        max: 1080
      },
      {
        name: 'width',
        type: 'number',
        default: 1280,
        min: 640,
        max: 1920
      },
      {
        name: 'topic',
        type: 'text',
        default: 'flow',
        description: 'Topic/category for the video'
      }
    ],
  },
  {
    id: 'audio-generation',
    name: 'Text to Audio',
    description: 'Generate audio from text using Eleven Labs',
    icon: '🔊',
    inputs: 1,
    outputs: 1,
    properties: [
      { name: 'text', type: 'text', required: true },
      { name: 'voice', type: 'string' }
    ],
  },
  {
    id: 'graph-generator',
    name: 'Graph Generator',
    description: 'Generate charts and graphs from CSV data',
    icon: '📊',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'chartType',
        type: 'select',
        options: ['Line Graph', 'Bar Graph'],
        default: 'Line Graph',
        description: 'Select the type of chart to generate'
      },
      {
        name: 'title',
        type: 'text',
        required: true,
        description: 'Enter the chart title'
      },
      {
        name: 'description',
        type: 'text',
        required: true,
        description: 'Enter a brief chart description'
      },
      {
        name: 'csvFile',
        type: 'file',
        required: true,
        description: 'Upload a CSV file in the required format'
      },

    ],
  },

  {
    id: 'music-generation',
    name: 'Music Generation',
    description: 'Generate music from text prompts',
    icon: '🎵',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'prompt',
        type: 'text',
        required: true,
        description: 'Enter a text prompt for music generation'
      },



    ]
  },
  {
    id: 'lip-sync',
    name: 'Lip Sync Generator',
    description: 'Create professional lip-synced videos by seamlessly combining your video with custom audio. Ideal for dubbing, voiceovers, and content localization.',
    icon: '🎬',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'video_url',
        type: 'url',
        required: true,
        description: 'Provide the URL of the video you want to use.'
      },
      {
        name: 'audio_url',
        type: 'url',
        required: true,
        description: 'Provide the URL of the audio file to sync with the video.'
      }
    ]
  },
  
  
  {
    id: 'heygen-video',
    name: 'HeyGen Video',
    description: 'Generate AI avatar videos using text or audio input',
    icon: '🎭',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'inputType',
        type: 'select',
        options: ['text', 'audio'],
        default: 'text',
        description: 'Choose between text or audio input'
      },
      {
        name: 'text',
        type: 'text',
        required: true,
        showWhen: (props) => props.inputType === 'text',
        description: 'Enter the text for the avatar to speak'
      },
      {
        name: 'audioFile',
        type: 'audio-upload',
        showWhen: (props) => props.inputType === 'audio',
        description: 'Upload an audio file (MP3, max 10MB)'
      },
      {
        name: 'selectedAvatar',
        type: 'avatar-select',
        required: true,
        description: 'Select an avatar'
      },
      {
        name: 'selectedVoice',
        type: 'voice-select',
        required: true,
        showWhen: (props) => props.inputType === 'text',
        description: 'Select a voice for text-to-speech'
      }
    ],
  },

  {
    id: 'video-stitcher',
    name: 'Video Stitcher',
    description: 'Combine multiple videos in sequence',
    icon: '🎬',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'videos',
        type: 'video-list',
        required: true,
        description: 'Add videos to combine (Cloudinary URLs)',
      }
    ],
  },
  {
    id: 'video-overlay',
    name: 'Video Overlay',
    description: 'Add a video overlay (like webcam) to main video',
    icon: '🎬',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'mainVideo',
        type: 'video-input',
        required: true,
        description: 'Main background video URL'
      },
      {
        name: 'overlayVideo',
        type: 'video-input',
        required: true,
        description: 'Overlay video URL (e.g., webcam)'
      },
      {
        name: 'position',
        type: 'select',
        required: true,
        description: 'Position of the overlay video',
        default: 'bottom-right',
        options: [
          'bottom-right',
          'bottom-left',
          'top-right',
          'top-left',
          'center'
        ]
      },
      {
        name: 'size',
        type: 'select',
        required: true,
        description: 'Size of the overlay video',
        default: 'small',
        options: [
          'small',
          'medium',
          'large'
        ]
      },
      {
        name: 'padding',
        type: 'number',
        default: 20,
        min: 0,
        max: 100,
        description: 'Padding from the edges (in pixels)'
      }
    ]
  },
  {
    id: 'kling-image-to-video',
    name: 'Kling Image to Video',
    description: 'Generate videos from images using Kling AI',
    icon: '🎬',
    inputs: 2,
    outputs: 1,
    properties: [
      {
        name: 'imageUrls',
        type: 'text',
        required: true,
        description: 'Enter image URLs in format: []'
      },
      {
        name: 'videoPrompts',
        type: 'text',
        required: true,
        description: 'Enter prompts in format: [{"prompt": "text"}, {"prompt": "text"}]'
      },
      {
        name: 'ratio',
        type: 'select',
        options: ['1:1', '16:9', '9:16', '4:3', '3:4'],
        default: '16:9',
        description: 'Aspect ratio of the output video'
      },
      {
        name: 'length',
        type: 'number',
        default: 5,
        min: 5,
        max: 10,
        description: 'Length of each video segment in seconds'
      }
    ]
  },
  {
    id: 'Videos To Shorts',
    name: 'Videos To Shorts',
    description: 'Upload a video to generate 45-second shorts clip based on prompts',
    icon: '🎬',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'videoFile',
        type: 'video-upload',
        required: true,
        description: 'Upload your video file (MP4, MOV, AVI)'
      },
      {
        name: 'prompt',
        type: 'text',
        required: true,
        description: 'Describe which parts of the video to highlight (e.g. "action scenes, emotional moments")'
      },
     
    ]
  },
  {
    id: 'story-image-generation',
    name: 'ImageGeneration',
    description: 'Generate multiple  images from text prompts ',
    icon: '🎬',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'prompts',
        type: 'text',
        required: true,
        description: 'Enter prompts for each scene (one per line)'
      },
      {
        name: 'advancedPrompt',
        type: 'text',
        description: 'Additional instructions for image generation'
      },
     
    
    ]
  },
];