import React, { memo, useCallback, useState, useEffect } from 'react';
import { Handle, Position, useNodes, useEdges } from 'reactflow';
import { nodeTypes } from '../config/nodeTypes';
import { NodeProperties } from './NodeProperties';
import { Button } from './ui/Button';
import axios from 'axios';
import { useFlowStore } from '../store/flowStore';
import { serverbaseURL } from "../constant/index";
import { Info, X, ExternalLink, HelpCircle, PlayCircle } from 'lucide-react';

export const CustomNode = memo(function CustomNode({ 
  data, 
  selected, 
  isEditable,
  onPropertiesChange
}) {
  const nodeType = nodeTypes.find((t) => t.id === data.type);
  const nodes = useNodes();
  const edges = useEdges();
  const [output, setOutput] = useState(null);
  const setNodeOutput = useFlowStore(state => state.setNodeOutput);
  const getNodeOutput = useFlowStore(state => state.getNodeOutput);
  const updateNodeProperties = useFlowStore(state => state.updateNodeProperties);
  const handleNodeRun = useFlowStore(state => state.handleNodeRun);
  const [showDescription, setShowDescription] = useState(false);
  
  // Initialize frozen state from data.properties.frozen
  const [isFrozen, setIsFrozen] = useState(data.properties?.frozen || false);

  // Sync the UI state with the actual node data
  useEffect(() => {
    setIsFrozen(!!data.properties?.frozen);
  }, [data.properties?.frozen]);

  // Toggle frozen state and update node properties
  const toggleFrozen = useCallback(() => {
    const newFrozenState = !isFrozen;
    setIsFrozen(newFrozenState);
    
    // Update the node properties in the store
    updateNodeProperties(data.id, { frozen: newFrozenState });
    
    // Also update the properties directly through onPropertiesChange
    if (onPropertiesChange) {
      onPropertiesChange({
        ...data.properties,
        frozen: newFrozenState
      });
    }
    
    console.log(`Node ${data.name} (${data.id}) frozen state set to: ${newFrozenState}`);
  }, [isFrozen, data.id, data.name, data.properties, updateNodeProperties, onPropertiesChange]);

  const getInputFromConnectedNode = () => {
    const incomingEdges = edges.filter(edge => edge.target === data.id);
    console.log('Incoming edges:', incomingEdges);

    if (!incomingEdges.length) return null;

    const sourceNodeId = incomingEdges[0].source;
    const sourceNode = nodes.find(node => node.id === sourceNodeId);
    console.log('Source node:', sourceNode);

    if (!sourceNode?.data?.output) return null;
    console.log('Source node output:', sourceNode.data.output);
    return sourceNode.data.output;
  };

  const processPromptWithInput = (properties) => {
    const newProperties = { ...properties };

    // Find all placeholders like {nodeName.output}
    const placeholderRegex = /{([^}]+)\.output}/g;
    let matches;

    const processText = (text) => {
      if (!text) return text;

      let processedText = text;
      while ((matches = placeholderRegex.exec(text)) !== null) {
        const [fullMatch, nodeName] = matches;
        const nodeOutput = getNodeOutput(nodeName);

        if (nodeOutput) {
          const outputText = typeof nodeOutput === 'object'
            ? (nodeOutput.text || nodeOutput.url || JSON.stringify(nodeOutput))
            : nodeOutput.toString();

          processedText = processedText.replace(fullMatch, outputText);
        }
      }
      return processedText;
    };

    if (newProperties.prompt) {
      newProperties.prompt = processText(newProperties.prompt);
    }
    if (newProperties.text) {
      newProperties.text = processText(newProperties.text);
    }

    return newProperties;
  };

  const handleTextGeneration = async () => {
    try {
      const processedProperties = processPromptWithInput(data.properties);
      
      // Add model-specific routing
      const endpoint = processedProperties.model === 'Deepseek R1'
        ? `${serverbaseURL}chat-completion-deepseek`
        : `${serverbaseURL}chat-completion`;

      const response = await axios.post(endpoint, {
        ...processedProperties,
        nodeId: data.id,
        nodeType: data.type
      });

      let result = response.data;
      if (typeof result === 'string') {
        try {
          result = JSON.parse(result);
        } catch (parseError) {
          result = { text: result };
        }
      }

      setOutput(result);
      setNodeOutput(data.id, result);
      data.onChange?.({ ...data.properties, output: result });
      return result;
    } catch (error) {
      const errorMessage = `Error: ${error.response?.data || error.message}`;
      setOutput(errorMessage);
      setNodeOutput(data.id, errorMessage);
      data.onChange?.({ ...data.properties, output: errorMessage });
      throw error;
    }
  };

  const handleImageGeneration = async () => {
    try {
      const processedProperties = processPromptWithInput(data.properties);
      const response = await axios.post(`${serverbaseURL}image-generation`, {
        ...processedProperties,
        nodeId: data.id,
        nodeType: data.type
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      let result;
      if (typeof response.data === 'string') {
        try {
          result = JSON.parse(response.data);
        } catch (parseError) {
          result = { url: response.data };
        }
      } else {
        result = response.data;
      }

      setOutput(result);
      setNodeOutput(data.id, result);
      data.onChange?.({ ...data.properties, output: result });
      console.log('Image Generation Response:', result);
      return result;
    } catch (error) {
      const errorMessage = `Error: ${error.response?.data || error.message}`;
      setOutput(errorMessage);
      setNodeOutput(data.id, errorMessage);
      data.onChange?.({ ...data.properties, output: errorMessage });
      console.error('Image Generation Error:', error);
      throw error;
    }
  };

  const handleVideoGeneration = async () => {
    try {
      const processedProperties = processPromptWithInput(data.properties);
      const response = await axios.post(`${serverbaseURL}video-generation`, {
        ...processedProperties,
        nodeId: data.id,
        nodeType: data.type
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      let result;
      if (typeof response.data === 'string') {
        try {
          result = JSON.parse(response.data);
        } catch (parseError) {
          result = { url: response.data };
        }
      } else {
        result = response.data;
      }

      setOutput(result);
      setNodeOutput(data.id, result);
      data.onChange?.({ ...data.properties, output: result });
      console.log('Video Generation Response:', result);
      return result;
    } catch (error) {
      const errorMessage = `Error: ${error.response?.data || error.message}`;
      setOutput(errorMessage);
      setNodeOutput(data.id, errorMessage);
      data.onChange?.({ ...data.properties, output: errorMessage });
      console.error('Video Generation Error:', error);
      throw error;
    }
  };

  const handleAudioGeneration = async () => {
    try {
      const processedProperties = processPromptWithInput(data.properties);
      // For audio generation, we need to process the 'text' property instead of 'prompt'
      if (processedProperties.text && typeof processedProperties.text === 'string') {
        const input = getInputFromConnectedNode();
        processedProperties.text = processedProperties.text.replace('{input}', input || '');
      }

      const response = await axios.post(`${serverbaseURL}audio-generation`, {
        ...processedProperties,
        nodeId: data.id,
        nodeType: data.type
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      let result;
      if (typeof response.data === 'string') {
        try {
          result = JSON.parse(response.data);
        } catch (parseError) {
          result = { url: response.data };
        }
      } else {
        result = response.data;
      }

      setOutput(result);
      setNodeOutput(data.id, result);
      data.onChange?.({ ...data.properties, output: result });
      console.log('Audio Generation Response:', result);
      return result;
    } catch (error) {
      const errorMessage = `Error: ${error.response?.data || error.message}`;
      setOutput(errorMessage);
      setNodeOutput(data.id, errorMessage);
      data.onChange?.({ ...data.properties, output: errorMessage });
      console.error('Audio Generation Error:', error);
      throw error;
    }
  };

  const handleVideoComposition = async () => {
    try {
      const processedProperties = processPromptWithInput(data.properties);
      const response = await axios.post(`${serverbaseURL}video-composition`, {
        ...processedProperties,
        nodeId: data.id,
        nodeType: data.type
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      let result;
      if (typeof response.data === 'string') {
        try {
          result = JSON.parse(response.data);
        } catch (parseError) {
          result = { url: response.data };
        }
      } else {
        result = response.data;
      }

      setOutput(result);
      setNodeOutput(data.id, result);
      data.onChange?.({ ...data.properties, output: result });
      console.log('Video Composition Response:', result);
      return result;
    } catch (error) {
      const errorMessage = `Error: ${error.response?.data || error.message}`;
      setOutput(errorMessage);
      setNodeOutput(data.id, errorMessage);
      data.onChange?.({ ...data.properties, output: errorMessage });
      console.error('Video Composition Error:', error);
      throw error;
    }
  };

  const handleRun = async () => {
    try {
      const handlers = {
        'text-generation': handleTextGeneration,
        'image-generation': handleImageGeneration,
        'video-generation': handleVideoGeneration,
        'audio-generation': handleAudioGeneration,
        'video-composition': handleVideoComposition,
        'prompt-input': async () => {
          const scenes = data.properties.scenes || [];
          const output = scenes.map((scene, index) => ({
            prompt: scene.prompt,
            sceneNumber: index + 1
          }));
          setOutput(output);
          setNodeOutput(data.id, output);
          return output;
        },
      };

      const handler = handlers[data.type];
      if (!handler) {
        console.error('Unknown node type:', data.type);
        return;
      }

      const result = await handler();
      console.log(`${nodeType.name} Result:`, result);
    } catch (error) {
      console.error(`Error in ${nodeType.name}:`, error);
    }
  };

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      event.stopPropagation();
    }
  }, []);

  const onRunClick = useCallback(async () => {
    if (handleNodeRun) {
      await handleNodeRun(data.id);
    }
  }, [handleNodeRun, data.id]);

  const getNodeDescription = (type) => {
    switch (type) {
      case 'text-generation':
        return {
          description: "Generate text content using advanced language models. Perfect for creating scripts, stories, or any text-based content.",
          usage: "1. Select a model (GPT-3.5, GPT-4, etc.)\n2. Enter your prompt\n3. Adjust temperature for creativity\n4. Run to generate text",
          demo: "https://demo.textgeneration.ai"
        };
      case 'image-generation':
        return {
          description: "Create images from text descriptions using AI models like Leonardo and Flux LORA.",
          usage: "1. Choose your preferred model\n2. Write a detailed image description\n3. Select aspect ratio and style\n4. Generate your image",
          demo: "https://demo.imagegen.ai"
        };
      case 'video-generation':
        return {
          description: "Transform text or images into dynamic videos with AI-powered animation.",
          usage: "1. Select text-to-video or image-to-video mode\n2. Input your prompts or images\n3. Choose video settings\n4. Generate your video",
          demo: "https://demo.videogen.ai"
        };
      case 'audio-generation':
        return {
          description: "Convert text into natural-sounding speech using advanced text-to-speech models.",
          usage: "1. Enter the text you want to convert\n2. Select a voice\n3. Adjust speech parameters\n4. Generate audio",
          demo: "https://demo.audiogen.ai"
        };
      case 'video-composition':
        return {
          description: "Combine videos, images, and audio into a cohesive composition.",
          usage: "1. Upload your media files\n2. Arrange them in sequence\n3. Add transitions and effects\n4. Export final video",
          demo: "https://demo.videocomposition.ai"
        };
      case 'prompt-input':
        return {
          description: "Create and manage multiple scene prompts for complex generations.",
          usage: "1. Add scene descriptions\n2. Order your scenes\n3. Connect to other nodes\n4. Use in generation workflow",
          demo: "https://demo.promptinput.ai"
        };
      default:
        return {
          description: "Configure this node's settings to process your content.",
          usage: "Check the node's properties panel for specific instructions.",
          demo: null
        };
    }
  };

  const nodeInfo = getNodeDescription(data.type);

  return (
    <div
      className={`bg-white rounded-lg shadow-lg border-2 ${
        selected ? 'border-blue-500' : 'border-gray-200'
      }`}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <div className="p-4 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="text-xl">{nodeType.icon}</span>
            <h3 className="font-medium">{nodeType.name}</h3>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={toggleFrozen}
              className={`p-1 hover:bg-gray-100 rounded-full ${isFrozen ? 'text-blue-500' : 'text-gray-500'}`}
              title={isFrozen ? "Node output is frozen (click to unfreeze)" : "Freeze node output"}
            >
              {isFrozen ? '🧊' : '❄️'}
            </button>
            <button
              onClick={() => setShowDescription(true)}
              className="p-1 hover:bg-gray-100 rounded-full"
              title="View node description"
            >
              <Info className="w-4 h-4 text-gray-500" />
            </button>
          </div>
        </div>
      </div>

      <NodeProperties
        nodeType={nodeType}
        properties={data.properties || {}}
        onChange={(newProperties) => {
          if (isEditable) {
            onPropertiesChange?.(newProperties);
            data.onChange?.(newProperties);
          }
        }}
        output={output}
        nodeName={data.name}
        onNameChange={(newName) => {
          if (isEditable) {
            data.onChange?.({ ...data.properties, name: newName });
          }
        }}
        isEditable={isEditable}
      />

      {/* <Button onClick={onRunClick} className="m-4">
        Run
      </Button> */}

      {Array.from({ length: nodeType.inputs }).map((_, i) => (
        <Handle
          key={`input-${i}`}
          type="target"
          position={Position.Left}
          style={{ top: `${((i + 1) * 100) / (nodeType.inputs + 1)}%` }}
        />
      ))}

      {Array.from({ length: nodeType.outputs }).map((_, i) => (
        <Handle
          key={`output-${i}`}
          type="source"
          position={Position.Right}
          style={{ top: `${((i + 1) * 100) / (nodeType.outputs + 1)}%` }}
        />
      ))}

      {/* Description Modal */}
      {showDescription && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg max-w-md w-full mx-4 relative overflow-hidden">
            {/* Header */}
            <div className="p-6 border-b border-gray-200">
              <button
                onClick={() => setShowDescription(false)}
                className="absolute right-4 top-4 p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <X className="w-4 h-4 text-gray-500" />
              </button>
              
              <div className="flex items-center gap-3">
                <span className="text-2xl">{nodeType.icon}</span>
                <h3 className="text-xl font-semibold text-gray-900">{nodeType.name}</h3>
              </div>
            </div>

            {/* Content */}
            <div className="p-6 space-y-6">
              <div className="space-y-2">
                <p className="text-gray-600">
                  {nodeInfo.description}
                </p>
              </div>

              <div className="space-y-2">
                <h4 className="font-medium text-gray-900">How to Use</h4>
                <div className="bg-gray-50 rounded-lg p-4">
                  <pre className="text-sm text-gray-600 whitespace-pre-wrap">
                    {nodeInfo.usage}
                  </pre>
                </div>
              </div>

              {nodeInfo.demo && (
                <div>
                  <a 
                    href={nodeInfo.demo}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-2 text-blue-500 hover:text-blue-600"
                  >
                    View Demo
                    <ExternalLink className="w-4 h-4" />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
});