import React, { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../provider/AuthProvider";

const Register = () => {
  const navigate = useNavigate();
  // const location = useLocation()
  const { googleSignIn } = useContext(AuthContext);

  const handleGoogleSignIn = () => {
    googleSignIn()
      .then((result) => {
        if (result.user.email) {
          // Save user data to local storage
          localStorage.setItem("user", JSON.stringify(result.user));

          navigate("/");
        }
      })
      .catch((error) => {
        if (error) {
          console.error(`Error in google login ${error}`);
        }
      });
  };

  return (
    <div className="min-h-screen flex flex-col justify-center bg-gray-100">
    {/* <Navbar /> */}
    <div className="w-full mx-auto max-w-md bg-white rounded-lg px-10 py-4  shadow-md ">
      <h1 className="text-gray-800 text-3xl text-center font-semibold">
        Register
      </h1>
      <p className="text-gray-500 text-center mt-2">Create your account</p>
      <div className="flex justify-center items-center mt-8">
        <button
          className="w-full bg-blue-600 text-white rounded-lg px-6 py-3 font-medium shadow-sm hover:bg-blue-700 transition duration-150"
          onClick={handleGoogleSignIn}
        >
          Sign up with Google
        </button>
      </div>
      <div className="flex justify-center items-center gap-1 mt-8 text-gray-600">
        <p className="text-sm">Already have an account?</p>
        <Link to="/login" className="text-blue-600 hover:underline">
          Login
        </Link>
      </div>
    </div>
  </div>
  

  );
};

export default Register;
