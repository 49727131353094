import React from 'react'
import ReturnPolicyInd from "../components/ReturnPolicyInd";
import InternationalRefundPolicy from "../components/InternationalRefundPolicy";

export const RefundAndCancellation = () => {
  return (
    <>
    <ReturnPolicyInd/>
    <InternationalRefundPolicy/>
    </>
  )
}
