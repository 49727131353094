import React from 'react';
import { Plus, Trash2 } from 'lucide-react';
import { Button } from './ui/Button';

export function VideoListInput({ value = [], onChange }) {
  const handleAddVideo = () => {
    onChange([...value, { url: '', id: Date.now().toString() }]);
  };

  const handleRemoveVideo = (index) => {
    const newVideos = [...value];
    newVideos.splice(index, 1);
    onChange(newVideos);
  };

  const handleUrlChange = (index, newUrl) => {
    const newVideos = [...value];
    newVideos[index] = { ...newVideos[index], url: newUrl };
    onChange(newVideos);
  };

  return (
    <div className="space-y-3">
      {value.map((video, index) => (
        <div key={video.id} className="flex gap-2">
          <input
            type="text"
            value={video.url}
            onChange={(e) => handleUrlChange(index, e.target.value)}
            placeholder="Paste Cloudinary video URL"
            className="flex-1 px-3 py-2 border rounded text-sm"
          />
          <Button
            variant="ghost"
            size="sm"
            onClick={() => handleRemoveVideo(index)}
            className="text-red-500 hover:text-red-700"
          >
            <Trash2 className="w-4 h-4" />
          </Button>
        </div>
      ))}
      
      <Button 
        onClick={handleAddVideo}
        variant="outline"
        size="sm"
        className="w-full flex items-center justify-center gap-2"
      >
        <Plus className="w-4 h-4" />
        Add Video URL
      </Button>
    </div>
  );
} 