import React from 'react';
import { Button } from './ui/Button';
import { Plus, Save, Upload, Play } from 'lucide-react';
import axios from 'axios';
import { useFlowStore } from '../store/flowStore';
import { serverbaseURL } from '../constant/index';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import { FlowManagementDialog } from './FlowManagementDialog';
import { useContext } from 'react';
import { AuthContext } from "../provider/AuthProvider";
import { Spinner } from './ui/Spinner';
import { ToastContainer } from 'react-toastify';

export function Header({ onAddNode }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState('save');
  const { nodes, edges, setNodes, setEdges, setNodeOutput, updateNodeProperties, getNodeOutput } = useFlowStore();
  const location = useLocation();
  const isEditMode = location.state?.mode === 'edit';
  const { user } = useContext(AuthContext);
  const [isRunning, setIsRunning] = useState(false);


  // Add this function in your component or a separate utility file
function validateOutputUsage(sourceNodeName, allNodes) {
  const sourceNode = allNodes.find(n => n.data.name === sourceNodeName);
  
  if (!sourceNode) {
    throw new Error(`Node "${sourceNodeName}" not found`);
  }
  
  if (!sourceNode.data.properties.enableOutput) {
    throw new Error(
      `Output of node "${sourceNodeName}" is not enabled. ` +
      `Please enable the "Enable Output" option on that node.`
    );
  }
  
  return true;
}

  const handleUpdate = async () => {
    try {
      // Get the template data from localStorage
      const editTemplate = JSON.parse(localStorage.getItem('editTemplate'));

      if (!editTemplate?.templateId) {
        toast.error('Template ID not found', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }

      const flowData = {
        nodes,
        edges,
      };

      // Update user flow first
      const response = await axios.put(`${serverbaseURL}api/update-user-flow`, {
        templateId: editTemplate.templateId,
        name: editTemplate.name,
        data: {
          data: flowData
        }
      });

      // Try to update published flow, but don't throw error if it fails
      try {
        await axios.put(`${serverbaseURL}api/update-published-flow`, {
          templateId: editTemplate.templateId,
          name: editTemplate.name,
          data: {
            data: flowData
          }
        });
      } catch (error) {
        // Silently handle the error if template is not published
        console.log('Template not yet published in marketplace');
      }

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        // Optionally, update localStorage with the new data
        localStorage.setItem('editTemplate', JSON.stringify({
          ...editTemplate,
          data: {
            data: flowData
          }
        }));
      } else {
        toast.error(response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error('Error updating flow:', error);
      toast.error('Failed to update flow: ' + (error.response?.data?.error || error.message), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleSaveFlow = async (flowName) => {
    if (!flowName) return;

    const flowData = {
      name: flowName,
      data: {
        nodes,
        edges
      }
    };

    try {
      await axios.post(`${serverbaseURL}api/users-flows`, {
        flowData,
        uid: user.uid,
        name: flowName
      });
      setDialogOpen(false);
      toast.success(`Flow "${flowName}" saved successfully!`);
    } catch (error) {
      console.error('Error saving flow:', error);
      toast.error(`Failed to save flow: ${error.message}`);
    }
  };

  const handleImportFlow = (flow) => {
    if (!flow.data || !flow.data.data.nodes || !flow.data.data.edges) {
      toast.error('Invalid flow data structure');
      return;
    }

    setNodes(flow.data.data.nodes);
    setEdges(flow.data.data.edges);
    setDialogOpen(false);
    toast.success(`Flow "${flow.name}" imported successfully!`);
  };

  const debugNodeState = (nodes) => {
    console.log("Current Node States:");
    nodes.forEach(node => {
      console.log({
        id: node.id,
        name: node.data.name,
        type: node.data.type,
        frozen: node.data.properties.frozen,
        hasOutput: getNodeOutput(node.data.name) !== undefined
      });
    });
  };

  const processNode = async (node) => {
    try {
      // Log full node data
      console.log('ProcessNode called with:', JSON.stringify({
        id: node.id,
        name: node.data.name,
        type: node.data.type,
        frozen: node.data.properties.frozen,
        properties: node.data.properties
      }, null, 2));

      // Skip execution if node is frozen and has output
      if (node.data.properties.frozen === true) {
        const existingOutput = getNodeOutput(node.data.name);
        if (existingOutput !== undefined) {
          console.log(`Node "${node.data.name}" is FROZEN and HAS output - SKIPPING PROCESSING`);
          return existingOutput;
        }
        console.log(`Node "${node.data.name}" is FROZEN but has NO cached output - WILL PROCESS`);
      }

      const inputEdges = edges.filter(edge => edge.target === node.id);
      const inputNodes = inputEdges.map(edge =>
        nodes.find(n => n.id === edge.source)
      );
      const inputResults = await Promise.all(
        inputNodes.map(async (inputNode) => {
          if (!getNodeOutput(inputNode.data.name)) {
            await processNode(inputNode);
          }
          return getNodeOutput(inputNode.data.name);
        })
      );

      let response;
      switch (node.data.type) {
        case 'prompt-input':
          const scenes = node.data.properties.scenes || [];
          const output = scenes.map((scene, index) => ({
            prompt: scene.prompt,
          }));
          setNodeOutput(node.id, output);
          response = { data: output };
          break;

        case 'video-composition':
          // Process imageSource placeholder
          let processedImageSource = node.data.properties.imageSource;
          if (processedImageSource) {
            const placeholderRegex = /{([^}]+)\.output}/g;
            processedImageSource = processedImageSource.replace(placeholderRegex, (match, nodeName) => {
              validateOutputUsage(nodeName, nodes); // Add this line
              const sourceNode = nodes.find(n => n.data.name === nodeName);
              if (!sourceNode) {
                console.warn(`Node "${nodeName}" not found`);
                return match;
              }
              const output = getNodeOutput(nodeName);
              return JSON.stringify(output) || match;
            });
          }

          // Process audioSource placeholder
          let processedAudioSource = node.data.properties.audioSource;
          if (processedAudioSource) {
            const placeholderRegex = /{([^}]+)\.output}/g;
            processedAudioSource = processedAudioSource.replace(placeholderRegex, (match, nodeName) => {
              const sourceNode = nodes.find(n => n.data.name === nodeName);
              if (!sourceNode) {
                console.warn(`Node "${nodeName}" not found`);
                return match;
              }
              const output = getNodeOutput(nodeName);
              return JSON.stringify(output) || match;
            });
          }

          const imageOutput = processedImageSource
            ? JSON.parse(processedImageSource)
            : getNodeOutput(inputNodes.find(n => n.data.type === 'image-generation')?.data.name);

          const audioOutput = processedAudioSource
            ? JSON.parse(processedAudioSource)
            : getNodeOutput(inputNodes.find(n => n.data.type === 'audio-generation')?.data.name);

          if (!imageOutput || !audioOutput) {
            throw new Error('Video composition requires both image and audio inputs');
          }

          const imageUrls = Array.isArray(imageOutput) && imageOutput[0]?.imageUrls
            ? imageOutput[0].imageUrls
            : (Array.isArray(imageOutput) ? imageOutput : [imageOutput]);

          const videoPayload = {
            imageSources: imageUrls,
            audioSources: {
              audioUrl: audioOutput.audioUrl,
              audioDuration: audioOutput.duration || 30.00,
              type: audioOutput.type,
            },
            height: node.data.properties.height || 720,
            width: node.data.properties.width || 1280,
            topic: node.data.properties.topic || 'flow'
          };

          console.log('Sending video composition request:', videoPayload);

          response = await axios.post(`${serverbaseURL}create-video-with-subtitles`, videoPayload);
          if (response.data?.videoUrl) {
            response.data = {
              url: response.data.videoUrl,
              type: 'video'
            };
          }
          break;

        case 'text-generation':
          let processedPrompt = node.data.properties.prompt;
          if (processedPrompt) {
            const placeholderRegex = /{([^}]+)\.output}/g;
            processedPrompt = processedPrompt.replace(placeholderRegex, (match, nodeName) => {
              validateOutputUsage(nodeName, nodes);
              const sourceNode = nodes.find(n => n.data.name === nodeName);
              if (!sourceNode) {
                console.warn(`Node "${nodeName}" not found`);
                return match;
              }

              const output = getNodeOutput(nodeName);
              console.log('Using output from node:', {
                nodeName,
                output
              });
              return output || match;
            });
          }

          // Add model-specific routing
          const endpoint = node.data.properties.model === 'Deepseek R1'
            ? `${serverbaseURL}chat-completion-deepseek`
            : `${serverbaseURL}chat-completion`;

          response = await axios.post(endpoint, {
            ...node.data.properties,
            prompt: processedPrompt,
            nodeId: node.id,
            nodeType: node.data.type
          });
          break;

        case 'image-generation':
          try {
            let processedPrompt = node.data.properties.prompt;
            if (processedPrompt) {
              const placeholderRegex = /{([^}]+)\.output}/g;
              processedPrompt = processedPrompt.replace(placeholderRegex, (match, nodeName) => {
                validateOutputUsage(nodeName, nodes); // Add this line
                const sourceNode = nodes.find(n => n.data.name === nodeName);
                if (!sourceNode) {
                  console.warn(`Node "${nodeName}" not found`);
                  return match;
                }

                const output = getNodeOutput(nodeName);
                // Handle the case where output is already an object
                if (typeof output === 'object') {
                  return JSON.stringify(output);
                }
                return output || match;
              });
            }

            let imagePrompts;
            const promptText = processedPrompt.trim();

            // Handle different input formats
            if (typeof promptText === 'string') {
              if (promptText.startsWith('[')) {
                try {
                  imagePrompts = JSON.parse(promptText);
                } catch (parseError) {
                  console.error('JSON Parse Error:', parseError);
                  console.log('Invalid JSON string:', promptText);
                  throw new Error('Failed to parse prompt JSON: ' + parseError.message);
                }
              } else {
                // Include the model in the prompt object
                imagePrompts = [{
                  prompt: promptText,
                  model: node.data.properties.model,
                  loraLink: node.data.properties.loraLink // Add the selected model here
                }];
              }
            } else if (Array.isArray(promptText)) {
              imagePrompts = promptText.map(item => ({
                ...item,
                model: node.data.properties.model,
                loraLink: node.data.properties.loraLink // Add the selected model to each prompt
              }));
            } else if (typeof promptText === 'object') {
              imagePrompts = [{
                ...promptText,
                model: node.data.properties.model,
                loraLink: node.data.properties.loraLink // Add the selected model
              }];
            } else {
              throw new Error('Invalid prompt format');
            }

            // Ensure all prompts have the correct structure
            imagePrompts = imagePrompts.map(item => {
              if (typeof item === 'string') {
                return {
                  prompt: item,
                  model: node.data.properties.model,
                  loraLink: node.data.properties.loraLink
                };
              }
              return {
                ...item,
                model: node.data.properties.model,
                loraLink: node.data.properties.loraLink
              };
            });

            console.log('Sending to Leonardo API:', imagePrompts);
            response = await axios.post(`${serverbaseURL}generate-images-leonardo`, imagePrompts);
          } catch (error) {
            console.error('Error processing image prompts:', error);
            throw new Error(`Failed to process image prompts: ${error.message}`);
          }
          break;

        case 'audio-generation':
          let processedProperties = { ...node.data.properties };
          if (processedProperties.text) {
            const placeholderRegex = /{([^}]+)\.output}/g;
            processedProperties.text = processedProperties.text.replace(placeholderRegex, (match, nodeName) => {
              validateOutputUsage(nodeName, nodes); // Add this line
              const sourceNode = nodes.find(n => n.data.name === nodeName);
              if (!sourceNode) {
                console.warn(`Node "${nodeName}" not found`);
                return match;
              }

              const output = getNodeOutput(nodeName);
              return output || match;
            });
          }

          response = await axios.post(`${serverbaseURL}audio-generation`, {
            ...processedProperties,
            nodeId: node.id,
            nodeType: node.data.type
          });
          break;

        case 'music-generation':
          try {
            console.log('Starting music generation process...');
            let processedPrompt = node.data.properties.prompt;
            console.log('Original prompt:', processedPrompt);

            if (processedPrompt) {
              const placeholderRegex = /{([^}]+)\.output}/g;
              processedPrompt = processedPrompt.replace(placeholderRegex, (match, nodeName) => {
                validateOutputUsage(nodeName, nodes); // Add this line
                console.log(`Replacing placeholder for node: ${nodeName}`);
                const sourceNode = nodes.find(n => n.data.name === nodeName);
                if (!sourceNode) {
                  console.warn(`Node "${nodeName}" not found`);
                  return match;
                }
                const output = getNodeOutput(nodeName);
                console.log(`Retrieved output for node ${nodeName}:`, output);
                return output || match;
              });
            }

            console.log('Processed prompt:', processedPrompt);

            const requestBody = {
              text: processedPrompt.trim()
            };
            console.log('Sending request with body:', requestBody);

            response = await axios.post(`${serverbaseURL}generate-music`, requestBody);
            console.log('Response :', response);

            console.log('Response received:', response.data);

            // Convert response to array format for consistency
            response.data = [response.data];
            console.log('Formatted response data:', response.data);

            updateNodeProperties(node.id, {
              ...node.data.properties,
              output: response.data
            });
            console.log('Updated node properties:', node.data.properties);

            setNodeOutput(node.id, response.data);
            console.log('Set node output successfully.');
          } catch (error) {
            console.error('Music Generation Error:', error);
            throw error;
          }
          break;

        case 'video-generation':
          try {
            const mode = node.data.properties.mode;
            let payload;

            if (mode === 'Text to Video') {
              const textPrompts = node.data.properties.textPrompts.split('\n')
                .filter(prompt => prompt.trim())
                .map(prompt => ({ prompt: prompt.trim() }));

              payload = {
                mode: 'text',
                prompts: textPrompts,
                ratio: node.data.properties.ratio,
                length: node.data.properties.length
              };
            } else {
              const imagePrompts = node.data.properties.imagePrompts.split('\n')
                .filter(prompt => prompt.trim())
                .map(prompt => ({ prompt: prompt.trim() }));

              const imageUrls = node.data.properties.imageUrls.split('\n')
                .filter(url => url.trim());

              if (imagePrompts.length !== imageUrls.length) {
                throw new Error('Number of prompts must match number of images');
              }

              payload = {
                mode: 'image',
                prompts: imagePrompts,
                images: imageUrls,
                ratio: node.data.properties.ratio,
                length: node.data.properties.length
              };
            }

            response = await axios.post(`${serverbaseURL}api/generate-kling-video`, payload);
            break;
          } catch (error) {
            console.error('Video Generation Error:', error);
            throw error;
          }

        case 'text-to-video':
          let processedPrompts = node.data.properties.prompts;
          if (processedPrompts) {
            const placeholderRegex = /{([^}]+)\.output}/g;
            processedPrompts = processedPrompts.replace(placeholderRegex, (match, nodeName) => {
              validateOutputUsage(nodeName, nodes); // Add this line
              const sourceNode = nodes.find(n => n.data.name === nodeName);
              if (!sourceNode) {
                console.warn(`Node "${nodeName}" not found`);
                return match;
              }
              const output = getNodeOutput(nodeName);
              if (Array.isArray(output)) {
                return output.map(item => item.prompt).join('\n');
              }
              return output || match;
            });
          }

          const textPrompts = processedPrompts.split('\n')
            .filter(prompt => prompt.trim())
            .map(prompt => ({ prompt: prompt.trim() }));

          response = await axios.post(`${serverbaseURL}api/generate-prompt-videos`, {
            prompts: textPrompts
          });

          // Convert single URL to array format for video composition node
          response.data = [response.data];

          updateNodeProperties(node.id, {
            ...node.data.properties,
            output: response.data
          });
          setNodeOutput(node.id, response.data);
          break;

        case 'image-prompt-video':
          let processedImagePrompts = node.data.properties.imagePrompts;
          let processedVideoPrompts = node.data.properties.videoPrompts;

          if (processedImagePrompts) {
            const placeholderRegex = /{([^}]+)\.output}/g;
            processedImagePrompts = processedImagePrompts.replace(placeholderRegex, (match, nodeName) => {
              validateOutputUsage(nodeName, nodes); // Add this line
              const sourceNode = nodes.find(n => n.data.name === nodeName);
              if (!sourceNode) return match;
              const output = getNodeOutput(nodeName);
              if (Array.isArray(output)) {
                return JSON.stringify(output, null, 2);
              }
              return output || match;
            });
          }

          if (processedVideoPrompts) {
            const placeholderRegex = /{([^}]+)\.output}/g;
            processedVideoPrompts = processedVideoPrompts.replace(placeholderRegex, (match, nodeName) => {
              validateOutputUsage(nodeName, nodes); // Add this line
              const sourceNode = nodes.find(n => n.data.name === nodeName);
              if (!sourceNode) return match;
              const output = getNodeOutput(nodeName);
              if (Array.isArray(output)) {
                return output.map(item => item.prompt).join('\n');
              }
              return output || match;
            });
          }

          const imgPrompts = JSON.parse(processedImagePrompts);
          const videoPrompts = processedVideoPrompts.split('\n')
            .filter(prompt => prompt.trim())
            .map(prompt => ({ prompt: prompt.trim() }));

          if (imgPrompts.length !== videoPrompts.length) {
            throw new Error('Number of image prompts must match number of video prompts');
          }

          response = await axios.post(`${serverbaseURL}api/generate-kling-video`, {
            imgPrompts,
            videoPrompts,
            topic: node.data.properties.topic || 'flow',
            height: node.data.properties.height || 720,
            width: node.data.properties.width || 1280
          });

          // Convert single URL to array format for video composition node
          response.data = [response.data];

          updateNodeProperties(node.id, {
            ...node.data.properties,
            output: response.data
          });
          setNodeOutput(node.id, response.data);
          break;

        case 'heygen-video':
          try {
            let audioAssetId = null;

            if (node.data.properties.inputType === 'audio' && node.data.properties.audioFile) {
              const formData = new FormData();
              formData.append('audio', node.data.properties.audioFile);

              const uploadResponse = await axios.post(
                `${serverbaseURL}api/upload-audio`,
                formData,
                {
                  headers: { 'Content-Type': 'multipart/form-data' }
                }
              );

              if (uploadResponse.data.success) {
                audioAssetId = uploadResponse.data.audio_asset_id;
              } else {
                throw new Error('Audio upload failed');
              }
            }

            const videoPayload = {
              resolution: '360p',
              dimension: {
                width: 360,
                height: 640
              },
              video_inputs: [
                {
                  character: {
                    type: 'avatar',
                    avatar_id: node.data.properties.selectedAvatar,
                    avatar_style: 'normal'
                  },
                  voice: node.data.properties.inputType === 'text'
                    ? {
                      type: 'text',
                      input_text: node.data.properties.text,
                      voice_id: node.data.properties.selectedVoice,
                    }
                    : {
                      type: 'audio',
                      audio_asset_id: audioAssetId
                    },
                  background: {
                    type: 'color',
                    value: '#ffffff'
                  }
                }
              ]
            };

            const generateResponse = await axios.post(
              `${serverbaseURL}api/generate-video`,
              videoPayload
            );

            const videoId = generateResponse.data.data.video_id;

            const statusResponse = await axios.get(
              `${serverbaseURL}api/video/status`,
              { params: { video_id: videoId } }
            );

            const result = statusResponse.data.data.video_url;
            updateNodeProperties(node.id, {
              ...node.data.properties,
              output: result
            });
            setNodeOutput(node.id, result);
            return result;
          } catch (error) {
            console.error('HeyGen Video Generation Error:', error);
            throw error;
          }

        case 'graph-generator':
          try {
            const { chartType, title, description, csvFile } = node.data.properties;

            console.log('Graph Generator Properties:', {
              chartType,
              title,
              description,
              csvFile,
            });

            // Validate inputs
            if (!csvFile) {
              throw new Error('CSV file is required to generate the chart');
            }
            if (!title) {
              throw new Error('Title is required');
            }

            // Prepare form data
            const formData = new FormData();
            formData.append('title', title);
            if (description) formData.append('description', description);
            formData.append('csvFile', csvFile);

            // Send request to the backend
            const response = await axios.post(
              `${serverbaseURL}api/generate-video-bar-graph`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );

            // Update node properties and set the output
            updateNodeProperties(node.id, {
              ...node.data.properties,
              output: response.data,
            });

            setNodeOutput(node.id, response.data);
          } catch (error) {
            console.error('Graph Generation Error:', error);
            throw error;
          }
          break;

        case 'video-stitcher':
          try {
            const videos = node.data.properties.videos || [];

            if (videos.length === 0) {
              throw new Error('No videos provided for stitching');
            }

            // Validate all URLs are from Cloudinary
            videos.forEach((video, index) => {
              if (!video.url || !video.url.includes('cloudinary')) {
                throw new Error(`Invalid Cloudinary URL at position ${index + 1}`);
              }
            });

            const response = await axios.post(
              `${serverbaseURL}api/stitch-videos`,
              { videos },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            );

            if (!response.data.success) {
              throw new Error('Failed to stitch videos');
            }

            const result = response.data.video_url;
            updateNodeProperties(node.id, {
              ...node.data.properties,
              output: result
            });
            setNodeOutput(node.id, result);
            return result;

          } catch (error) {
            console.error('Video Stitching Error:', error);
            throw error;
          }
        case 'lip-sync':
          try {
            const { video_url, audio_url } = node.data.properties;

            if (!video_url || !audio_url) {
              throw new Error('Video and Audio URLs are required');
            }

            const response = await axios.post(
              `${serverbaseURL}api/generate-lip-sync`,
              {
                video_url,
                audio_url,

              }
            );

            if (!response.data.requestId) {
              throw new Error('Lip Sync Generation Failed');
            }

            const result = response.data.videoData;
            updateNodeProperties(node.id, {
              ...node.data.properties,
              output: result,
            });
            setNodeOutput(node.id, result);
            return result;
          } catch (error) {
            console.error('Lip Sync Generation Error:', error);
            throw error;
          }

        case 'video-overlay':
          try {
            const { mainVideo, overlayVideo, position, size, padding } = node.data.properties;

            if (!mainVideo || !overlayVideo) {
              throw new Error('Both main video and overlay video URLs are required');
            }

            const response = await axios.post(
              `${serverbaseURL}api/overlay-video`,
              {
                mainVideo,
                overlayVideo,
                position,
                size,
                padding: parseInt(padding)
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            );

            const result = response.data.video_url;
            updateNodeProperties(node.id, {
              ...node.data.properties,
              output: result
            });
            setNodeOutput(node.id, result);
            return result;

          } catch (error) {
            console.error('Video Overlay Error:', error);
            throw error;
          }
        case 'Videos To Shorts':
          try {
            const videoFile = node.data.properties.videoFile;
            const prompt = node.data.properties.prompt;

            if (!videoFile) {
              throw new Error('Please upload a video file');
            }

            const formData = new FormData();
            formData.append('video', videoFile);
            formData.append('prompt', prompt);
            console.log(formData);

            const response = await axios.post(
              `${serverbaseURL}process-video-to-shorts`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );

            const result = response.data;
            console.log("result", result)
            console.log(node.id);
            updateNodeProperties(node.id, {
              ...node.data.properties,
              output: result
            });
            setNodeOutput(node.id, result);
            return result;

          } catch (error) {
            console.error('Video to Shorts Error:', error);
            throw error;
          }

        case 'kling-image-to-video':
          try {
            // Get the inputs and clean them up more thoroughly
            let imageUrls;
            try {
              const imageUrlsString = node.data.properties.imageUrls
                .trim()
                .replace(/\n/g, '') // Remove newlines
                .replace(/,\s*]/g, ']') // Remove trailing commas before closing bracket
                .replace(/^\[/, '[') // Ensure proper opening bracket
                .replace(/]$/, ']') // Ensure proper closing bracket
                .replace(/'/g, '"'); // Replace single quotes with double quotes

              try {
                imageUrls = JSON.parse(imageUrlsString);
              } catch (jsonError) {
                // If JSON parsing fails, try evaluating as array string
                // This is safer than using eval()
                imageUrls = imageUrlsString
                  .slice(1, -1) // Remove brackets
                  .split(',')
                  .map(url => url.trim())
                  .filter(url => url.length > 0);
              }

              // Ensure we have an array
              if (!Array.isArray(imageUrls)) {
                throw new Error('Image URLs must be an array');
              }

              // Clean up each URL
              imageUrls = imageUrls.map(url =>
                url.trim()
                  .replace(/^['"]/, '') // Remove leading quotes
                  .replace(/['"]$/, '') // Remove trailing quotes
              ).filter(url => url.length > 0);

            } catch (e) {
              console.error('Image URL parsing error:', e);
              throw new Error('Invalid image URLs format. Expected array of URLs');
            }

            let videoPrompts;
            try {
              videoPrompts = JSON.parse(node.data.properties.videoPrompts);
              // Validate the structure of video prompts
              if (!Array.isArray(videoPrompts) || !videoPrompts.every(p => p.prompt)) {
                throw new Error('Video prompts must be an array of objects with "prompt" property');
              }
            } catch (e) {
              throw new Error('Invalid video prompts format. Expected JSON array of objects with "prompt" property');
            }

            // Validate inputs
            if (!imageUrls.length) {
              throw new Error('At least one image URL is required');
            }

            if (imageUrls.length !== videoPrompts.length) {
              console.log('Image URLs:', imageUrls);  // For debugging
              console.log('Video Prompts:', videoPrompts);  // For debugging
              throw new Error(`Number of image URLs (${imageUrls.length}) must match number of video prompts (${videoPrompts.length})`);
            }

            // Prepare payload for API
            const payload = {
              pairs: imageUrls.map((url, index) => ({
                imageUrl: url,
                prompt: videoPrompts[index].prompt
              })),
              ratio: node.data.properties.ratio || '16:9',
              length: node.data.properties.length || 3
            };

            console.log("Payload:", payload);

            // Send to backend
            response = await axios.post(`${serverbaseURL}api/generate-direct-kling-video`, payload);
            break;
          } catch (error) {
            console.error('Kling Video Generation Error:', error);
            throw error;
          }
          break;

        default:
          throw new Error(`Unsupported node type: ${node.data.type}`);
      }

      console.log('API Response:', response.data);

      const result = response.data;
      updateNodeProperties(node.id, {
        ...node.data.properties,
        output: result
      });
      setNodeOutput(node.id, result);
      return result;

    } catch (error) {
      console.error(`Error processing node ${node.id}:`, error);
      setNodeOutput(node.id, { error: error.message });
      throw error;
    }
  };

  const handleRunFlow = async () => {
    setIsRunning(true);
    try {
      // Add validation before processing nodes
      edges.forEach(edge => {
        const sourceNode = nodes.find(n => n.id === edge.source);
        if (sourceNode && !sourceNode.data.properties.enableOutput) {
          throw new Error(
            `Output usage error: Node "${sourceNode.data.name}" is connected ` +
            `but doesn't have output enabled. Please enable its output.`
          );
        }
      });
      // Debug current node states
      debugNodeState(nodes);

      // Create a topological sort of nodes for execution
      const nodeMap = new Map(nodes.map(node => [node.id, node]));
      const inDegree = new Map(nodes.map(node => [node.id, 0]));
      const graph = new Map(nodes.map(node => [node.id, []]));

      // Build the graph
      edges.forEach(edge => {
        const source = edge.source;
        const target = edge.target;

        if (graph.has(source)) {
          graph.get(source).push(target);
        }

        if (inDegree.has(target)) {
          inDegree.set(target, inDegree.get(target) + 1);
        }
      });

      // Find nodes with no dependencies (in-degree = 0)
      const queue = [];
      for (const [nodeId, degree] of inDegree) {
        if (degree === 0) {
          queue.push(nodeId);
        }
      }

      // Process nodes in topological order
      while (queue.length > 0) {
        const currentId = queue.shift();
        const currentNode = nodeMap.get(currentId);

        // Check if node is frozen and has cached output
        const isFrozen = currentNode.data.properties?.frozen === true;
        const nodeName = currentNode.data.name;
        const cachedOutput = getNodeOutput(nodeName);

        console.log("FULL NODE DATA:", JSON.stringify(currentNode, null, 2));
        console.log(`Processing node ${nodeName} (id: ${currentId}):`, {
          frozen: isFrozen,
          frozenValue: currentNode.data.properties?.frozen,
          frozenType: typeof currentNode.data.properties?.frozen,
          properties: currentNode.data.properties,
          hasCachedOutput: cachedOutput !== undefined
        });

        if (isFrozen && cachedOutput !== undefined) {
          console.log(`Node "${nodeName}" is frozen with cached output - SKIPPING EXECUTION`);
        } else {
          // If not frozen or no cached output, process it
          if (isFrozen) {
            console.log(`Node "${nodeName}" is frozen but NO cached output found - EXECUTING`);
          }
          await processNode(currentNode);
        }

        // Process child nodes
        const children = graph.get(currentId) || [];
        for (const child of children) {
          inDegree.set(child, inDegree.get(child) - 1);
          if (inDegree.get(child) === 0) {
            queue.push(child);
          }
        }
      }

      toast.success('Flow executed successfully!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error('Error running flow:', error);
      toast.error(`Error running flow: ${error.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setIsRunning(false);
    }
  };

  // Add this function to directly freeze all nodes for testing
  const freezeAllNodes = () => {
    nodes.forEach(node => {
      updateNodeProperties(node.id, { frozen: true });
    });

    // Verify nodes are frozen
    console.log("After freezing all nodes:");
    debugNodeState(nodes);
  };

  return (
    <>
      <div className="p-4 bg-white border-b border-gray-200 flex justify-between items-center">
        <div className="flex items-center gap-4">
          <h1 className="text-xl font-semibold">GLIF Builder</h1>
          <Button
            onClick={handleRunFlow}
            className="bg-black hover:bg-gray-800 min-w-[120px]"
            disabled={isRunning}
          >
            {isRunning ? (
              <>
                <Spinner />
                Processing...
              </>
            ) : (
              <>
                <Play className="w-4 h-4 mr-2" />
                Run Flow
              </>
            )}
          </Button>
        </div>
        <div className="flex items-center gap-2">
          {isEditMode ? (
            <Button
              onClick={handleUpdate}
              className="flex items-center gap-2"
            >
              <Save className="w-4 h-4" />
              Update
            </Button>
          ) : (
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  setDialogMode('save');
                  setDialogOpen(true);
                }}
              >
                <Save className="w-4 h-4 mr-2" />
                Save Flow
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setDialogMode('import');
                  setDialogOpen(true);
                }}
              >
                <Upload className="w-4 h-4 mr-2" />
                Import Flow
              </Button>
            </>
          )}
          <Button onClick={onAddNode}>
            <Plus className="w-4 h-4 mr-2" />
            Add Node
          </Button>
          <Button
            onClick={freezeAllNodes}
            variant="outline"
            className="ml-2"
          >
            Freeze All Nodes
          </Button>
        </div>
      </div>

      <FlowManagementDialog
        isOpen={dialogOpen}
        mode={dialogMode}
        onClose={(flowName) => {
          if (flowName) {
            handleSaveFlow(flowName);
          }
          setDialogOpen(false);
        }}
        onImport={handleImportFlow}
      />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}