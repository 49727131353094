import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from './ui/Button';
import { serverbaseURL } from '../constant/index';
import { ChevronDown, Play, Pause, Upload } from 'lucide-react';

const ITEMS_PER_PAGE = 8;

export function AvatarSelector({ value, onChange }) {
  const [avatars, setAvatars] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [previewingAvatar, setPreviewingAvatar] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    fetchAvatars();
  }, []);

  const fetchAvatars = async () => {
    try {
      const response = await axios.get(`${serverbaseURL}api/avatars`);
      console.log('Avatars response:', response.data); // Debug log
      const allAvatars = response.data?.data?.avatars || [];
      setAvatars(allAvatars.slice(0, ITEMS_PER_PAGE));
      setHasMore(allAvatars.length > ITEMS_PER_PAGE);
    } catch (error) {
      console.error('Error fetching avatars:', error);
    }
  };

  const loadMore = async () => {
    try {
      const response = await axios.get(`${serverbaseURL}api/avatars`);
      const allAvatars = response.data?.data?.avatars || [];
      const nextBatch = allAvatars.slice(
        page * ITEMS_PER_PAGE,
        (page + 1) * ITEMS_PER_PAGE
      );
      setAvatars(prev => [...prev, ...nextBatch]);
      setPage(prev => prev + 1);
      setHasMore(allAvatars.length > (page + 1) * ITEMS_PER_PAGE);
    } catch (error) {
      console.error('Error loading more avatars:', error);
    }
  };

  const selectedAvatar = avatars.find(a => a.avatar_id === value);

  return (
    <div className="relative">
      <div
        className="w-full p-2 border rounded flex justify-between items-center cursor-pointer hover:border-gray-400"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-sm">
          {selectedAvatar ? selectedAvatar.avatar_name : 'Select an avatar'}
        </span>
        <ChevronDown className={`w-4 h-4 transform transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </div>

      {isOpen && (
        <div className="absolute z-50 w-full mt-1 bg-white border rounded-md shadow-lg max-h-64 overflow-y-auto">
          {avatars.map((avatar) => (
            <div 
              key={avatar.avatar_id}
              className={`p-3 hover:bg-gray-50 flex justify-between items-center cursor-pointer ${
                value === avatar.avatar_id ? 'bg-blue-50' : ''
              }`}
              onClick={() => {
                onChange(avatar.avatar_id);
                setIsOpen(false);
              }}
            >
              <div className="flex items-center gap-2">
                {avatar.preview_image_url && (
                  <img 
                    src={avatar.preview_image_url} 
                    alt={avatar.avatar_name}
                    className="w-8 h-8 rounded-full object-cover"
                  />
                )}
                <span className="text-sm font-medium">{avatar.avatar_name}</span>
              </div>
              {avatar.preview_video_url && (
                <Button
                  size="sm"
                  variant="ghost"
                  className="ml-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPreviewingAvatar(previewingAvatar === avatar.avatar_id ? null : avatar.avatar_id);
                  }}
                >
                  {previewingAvatar === avatar.avatar_id ? <Pause className="w-4 h-4" /> : <Play className="w-4 h-4" />}
                </Button>
              )}
            </div>
          ))}
          {previewingAvatar && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-4 rounded-lg max-w-lg w-full">
                <video
                  className="w-full rounded"
                  src={avatars.find(a => a.avatar_id === previewingAvatar)?.preview_video_url}
                  controls
                  autoPlay
                />
                <Button
                  className="mt-4 w-full"
                  onClick={() => setPreviewingAvatar(null)}
                >
                  Close Preview
                </Button>
              </div>
            </div>
          )}
          {hasMore && (
            <Button 
              variant="ghost" 
              className="w-full text-sm text-gray-600"
              onClick={(e) => {
                e.stopPropagation();
                loadMore();
              }}
            >
              Load More
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export function VoiceSelector({ value, onChange }) {
  const [voices, setVoices] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [previewingVoice, setPreviewingVoice] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    fetchVoices();
  }, []);

  const fetchVoices = async () => {
    try {
      const response = await axios.get(`${serverbaseURL}api/voices`);
      console.log('Voices response:', response.data); // Debug log
      const allVoices = response.data?.data?.voices || [];
      setVoices(allVoices.slice(0, ITEMS_PER_PAGE));
      setHasMore(allVoices.length > ITEMS_PER_PAGE);
    } catch (error) {
      console.error('Error fetching voices:', error);
    }
  };

  const loadMore = async () => {
    try {
      const response = await axios.get(`${serverbaseURL}api/voices`);
      const allVoices = response.data?.data?.voices || [];
      const nextBatch = allVoices.slice(
        page * ITEMS_PER_PAGE,
        (page + 1) * ITEMS_PER_PAGE
      );
      setVoices(prev => [...prev, ...nextBatch]);
      setPage(prev => prev + 1);
      setHasMore(allVoices.length > (page + 1) * ITEMS_PER_PAGE);
    } catch (error) {
      console.error('Error loading more voices:', error);
    }
  };

  const selectedVoice = voices.find(v => v.voice_id === value);

  return (
    <div className="relative">
      <div
        className="w-full p-2 border rounded flex justify-between items-center cursor-pointer hover:border-gray-400"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-sm">
          {selectedVoice ? selectedVoice.name : 'Select a voice'}
        </span>
        <ChevronDown className={`w-4 h-4 transform transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </div>

      {isOpen && (
        <div className="absolute z-50 w-full mt-1 bg-white border rounded-md shadow-lg max-h-64 overflow-y-auto">
          {voices.map((voice) => (
            <div 
              key={voice.voice_id}
              className={`p-3 hover:bg-gray-50 flex justify-between items-center cursor-pointer ${
                value === voice.voice_id ? 'bg-blue-50' : ''
              }`}
              onClick={() => {
                onChange(voice.voice_id);
                setIsOpen(false);
              }}
            >
              <div className="flex flex-col">
                <span className="text-sm font-medium">{voice.name}</span>
                <span className="text-xs text-gray-500">{voice.language} • {voice.gender}</span>
              </div>
              {voice.preview_audio && (
                <Button
                  size="sm"
                  variant="ghost"
                  className="ml-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPreviewingVoice(previewingVoice === voice.voice_id ? null : voice.voice_id);
                  }}
                >
                  {previewingVoice === voice.voice_id ? <Pause className="w-4 h-4" /> : <Play className="w-4 h-4" />}
                </Button>
              )}
            </div>
          ))}
          {previewingVoice && (
            <div className="mt-2 p-2 border rounded">
              <audio
                className="w-full"
                src={voices.find(v => v.voice_id === previewingVoice)?.preview_audio}
                controls
                autoPlay
              />
            </div>
          )}
          {hasMore && (
            <Button 
              variant="ghost" 
              className="w-full text-sm text-gray-600"
              onClick={(e) => {
                e.stopPropagation();
                loadMore();
              }}
            >
              Load More
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export function AudioUploader({ onChange, value }) {
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useState('');

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.size > 10 * 1024 * 1024) { // 10MB limit
      alert('File is too large. Please choose a file smaller than 10MB.');
      return;
    }

    if (!file.type.startsWith('audio/')) {
      alert('Please select an audio file (MP3, WAV, etc.)');
      return;
    }

    setFileName(file.name);
    setIsUploading(true);

    try {
      const formData = new FormData();
      formData.append('audio', file);
      
      onChange(file);
    } catch (error) {
      console.error('Error handling audio file:', error);
      alert('Error handling audio file. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-center w-full">
        <label className="w-full flex flex-col items-center px-4 py-6 bg-white text-gray-700 rounded-lg border-2 border-dashed border-gray-300 cursor-pointer hover:bg-gray-50">
          <Upload className="w-8 h-8 text-gray-400" />
          <span className="mt-2 text-sm">
            {isUploading ? 'Uploading...' : 'Click to upload audio file'}
          </span>
          <span className="mt-1 text-xs text-gray-500">MP3, WAV (max. 10MB)</span>
          <input
            type="file"
            className="hidden"
            accept="audio/*"
            onChange={handleFileChange}
          />
        </label>
      </div>
      {fileName && (
        <div className="text-sm text-gray-600 flex items-center gap-2">
          <span>Selected file:</span>
          <span className="font-medium">{fileName}</span>
        </div>
      )}
    </div>
  );
} 